import React, { useEffect, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { CATALOG_NO_OPTION_MESSAGE } from '../../../../constants/product';
import SearchBar from '../../../ui/molecules/SearchBar';
import { DraggableDataTable } from '../../../ui/organisms/DraggableDataTable';
import { Console } from 'console';

interface Props {
    categoryGroups: any;
    selectedCatalogs?;
    handleCatalogsChange?: (value) => void;
    removeDeletedGroups: (value) => void;
    orderGroups: (value) => void;
    groupsCatalogsList?;
}

export default function SelectedGroupsView({
    categoryGroups,
    selectedCatalogs,
    handleCatalogsChange,
    removeDeletedGroups,
    orderGroups,
    groupsCatalogsList,
}: Props) {
    const animatedComponents = makeAnimated();
    const [categoryGroupsData, setCategoryGroupsData] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        if (categoryGroups) {
            const groups = [];
            categoryGroups?.forEach((group) => {
                const exists = groups?.some(
                    (catGroup) => catGroup?.productGroupId === group?.productGroupId,
                );
                if (!exists) groups.push(group);
            });
            setCategoryGroupsData(groups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryGroups, setCategoryGroupsData]);

    useEffect(() => {
        const groups = [...categoryGroups];
        if (searchClicked && searchTerm && searchTerm?.length > 2) {
            const filteredList = groups?.filter((group) => {
                return (
                    group?.groupName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    group?.productGroupId.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setCategoryGroupsData(filteredList);
        } else {
            setCategoryGroupsData(groups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchClicked, searchTerm, setCategoryGroupsData]);

    const productNames = categoryGroups
        ?.map((product) => product?.groupName)
        .concat(categoryGroups?.map((product) => product?.productGroupId));

    const colourStyles = {
        menuList: (styles) => ({
            ...styles,
            background: '#f3e8ff',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused ? '#f3e8ff' : isSelected ? '#f3e8ff' : undefined,
            zIndex: 1,
        }),
        multiValue: (styles) => {
            return {
                ...styles,
                backgroundColor: '#c084fc',
            };
        },
        multiValueRemove: (styles) => ({
            ...styles,

            ':hover': {
                backgroundColor: '#c084fc',
                color: 'white',
            },
        }),
        menu: (base) => ({
            ...base,
            zIndex: 100,
            background: '#c084fc',
        }),
    };

    const catagoryGroupColumns = React.useMemo(
        () => [
            {
                id: 'delete',
                accessor: '',

                Cell: function deleteProd({ row }) {
                    return (
                        <span
                            id={`${row?.original?.id}-delete`}
                            style={{
                                cursor: 'pointer',
                                color: '#a855f7',
                                position: 'absolute',
                                padding: '5px',
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyDown={() => {
                                removeDeletedGroups(row?.original?.id);
                            }}
                            onClick={() => {
                                removeDeletedGroups(row?.original?.id);
                            }}
                        >
                            <BsIcons.BsTrash />
                        </span>
                    );
                },
            },
            {
                Header: 'Group ID',
                accessor: 'productGroupId',
            },
            {
                Header: 'Group Title',
                accessor: 'name',
            },
            {
                Header: 'Display Name',
                accessor: 'displayName',
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Product Count',
                accessor: 'productsCount',
                Cell: function showProductsCount({ row }) {
                    return (
                        <div>
                            {row?.original?.productsCount ??
                                row?.original?.itemOrder?.split(',').length}
                        </div>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: function showBadges({ row }) {
                    const isActive = row?.original?.isActive;
                    const isFeatured = row?.original?.featured;
                    return (
                        <div className="flex gap-2">
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                    row?.original?.isActive === true
                                        ? 'bg-green-100 text-green-600'
                                        : row?.original?.isActive === false
                                        ? 'bg-red-100 text-red-600'
                                        : ''
                                }`}
                            >
                                {row?.original?.isActive === true ? 'Active' : 'Inactive'}
                            </span>
                            {row?.original?.featured ? (
                                <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-yellow-200 text-yellow-600 ml-1">
                                    Featured
                                </span>
                            ) : null}
                        </div>
                    );
                },
            },
            {
                Header: 'Catalogs',
                accessor: '',
                Cell: function showCatalog({ row }) {
                    let catalogOptions = [];
                    const foundCatalogs = groupsCatalogsList?.find(
                        (groupCatalog) => groupCatalog?.id === row?.original?.id,
                    );
                    if (foundCatalogs) {
                        catalogOptions = foundCatalogs?.catalogs?.map((catalog) => ({
                            label: catalog?.label || catalog?.catalogName,
                            value: catalog?.value || catalog?.catalogId,
                        }));
                    }
                    return (
                        <span className="d-inline-block z-999 h-44 w-full py-3">
                            <Select
                                id={`${row?.original?.id}-select-catalog`}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                styles={colourStyles}
                                options={selectedCatalogs}
                                defaultValue={catalogOptions}
                                noOptionsMessage={() => CATALOG_NO_OPTION_MESSAGE}
                                onChange={(list) => {
                                    const groupCatalogs = {
                                        id: row?.original?.id,
                                        catalogs: list,
                                    };

                                    handleCatalogsChange(groupCatalogs);
                                }}
                            />
                        </span>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCatalogs, handleCatalogsChange],
    );

    return (
        <>
            <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500 px-4 pb-4">
                <SearchBar
                    id="add-new-category-search-field"
                    options={productNames}
                    handleChange={(e) => {
                        setSearchTerm(e.target.value);
                        setSearchClicked(true);
                    }}
                    placeHolder={'Search Product by Name or ID'}
                />
            </div>
            <div className="w-full">
                {categoryGroupsData?.length ? (
                    <DraggableDataTable
                        columns={catagoryGroupColumns}
                        data={categoryGroupsData}
                        setData={orderGroups}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                            {catagoryGroupColumns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
