/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState } from 'react';
import S3 from 'react-aws-s3';
import { s3Config } from '../../../constants/config/constants';

import ImageCropModal from '../../../utils/ImageCropper';
import { Images } from '../../ui/atoms/Images';
import Loader from '../../../utils/loader';
import NormalLoader from '../../../utils/normalLoader';

interface Props {
    isEdit: boolean;
    width?: string;
    height: string;
    placeholder?: string;
    isGallery?: boolean;
    inputId?: string;
    setGalleryImage?: any;
    imageProp?: any;
    disabled?: boolean;
    aspectRatio?: number;
    transparencyEnabled?: boolean;
}

export default function UploadProductPic({
    isEdit,
    width,
    height,
    placeholder,
    isGallery,
    inputId,
    setGalleryImage,
    imageProp,
    disabled,
    aspectRatio,
    transparencyEnabled,
}: Props) {
    const [imageError, setImageError] = useState<string>('');
    const [cropModalVisible, setCropModalVisible] = useState(false);
    const [zoom, setZoom] = useState(1);
    const newFileName = Date.now().toString();

    const [productImage, setProductImage] = useState<File>(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [imageSrch, setImageSrc] = useState<string>('');
    const [isUploading, setIsUploading] = useState(false);
    const [hover, setHover] = useState(false);
    const [productLocation, setProductImageLocation] = useState('');

    const uploadImageToS3 = async (resultedImage: any) => {
        setIsUploading(true);
        s3Config.dirName = `product/${newFileName}`;
        const ReactS3Client = new S3(s3Config);
        if (resultedImage?.length) {
            ReactS3Client.uploadFile(resultedImage[0], newFileName)
                .then((data) => {
                    setProductImageLocation(data?.location);
                    setGalleryImage(data?.location);

                    setIsUploading(false);
                })
                .catch((err) => {
                    console.error(err);
                    setIsUploading(false);
                });
        }
    };

    const handleImage = (e) => {
        const _URL = window.URL || window.webkitURL;
        let file, img;
        if ((file = e.target.files[0])) {
            img = new Image();
            img.onload = function () {
                const width = this.naturalWidth;
                const height = this.naturalHeight;
                console.log(
                    'setting image dimensions to natural width: ' + width + ' height: ' + height,
                );
                if (width <= 2048 && height <= 2048) {
                    setProductImage(file);
                    setCropModalVisible(true);
                    setZoom(1);

                    setImageError(null);
                } else {
                    setImageError('Image resolution is too bigger');
                }
            };
            img.onerror = function () {
                setImageError('not a valid file: ' + file.type);
            };
            img.src = _URL.createObjectURL(file);
        }
    };

    const handleImageInput = (image) => {
        return (
            <>
                <input
                    className={`invisible focus:outline-none w-full pb-3 border-b-2 pl-2 focus:border-blue-900`}
                    id={inputId}
                    type="file"
                    accept="image/png, image/jpeg"
                    disabled={disabled ? true : false}
                    onChange={(e) => {
                        handleImage(e);
                        e.target.value = null;
                    }}
                />
                <label
                    className=" justify-center cursor-pointer underline  flex"
                    htmlFor={isEdit ? inputId : null}
                >
                    {image && hover ? (
                        <>
                            <div className=" z-50 -mt-16 font-light text-gray-600 opacity-100 ">
                                {disabled ? null : (
                                    <div
                                        id={inputId ? `change-image-button-${inputId}` : ''}
                                        className={`underline font-poppins text-purple-500   ${
                                            isGallery ? 'text-sm' : 'text-lg'
                                        }`}
                                    >
                                        Change Image
                                    </div>
                                )}
                            </div>
                        </>
                    ) : null}
                </label>
            </>
        );
    };

    const handleProfilePic = () => {
        if (productLocation) {
            return (
                <>
                    <div className={`${width} ${' '} ${height} flex`}>
                        <div
                            className="text-center flex flex-col  w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="flex flex-col m-auto  w-full">
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div className={` ${isGallery ? '' : '-mt-21'}`}>
                                        <img
                                            className={`${height} object-contain  inset-0 rounded  bg-cover bg-center  ${
                                                hover ? ' opacity-50 ' : ''
                                            } z-0`}
                                            src={productLocation}
                                            alt={'product'}
                                        />
                                        <div className={` ${isGallery ? '-mt-12' : '-mt-30'} `}>
                                            {handleImageInput(productLocation)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (!productImage && imageProp) {
            return (
                <>
                    <div className={`${width} ${' '}  ${height} order-1`}>
                        <div
                            className="text-center flex flex-col w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="flex flex-col m-auto  w-full">
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <Loader />
                                    </div>
                                ) : (
                                    <div>
                                        <img
                                            className={`${height} object-contain  inset-0 rounded bg-cover bg-center  ${
                                                hover ? ' opacity-50 ' : ''
                                            } z-0`}
                                            src={imageProp}
                                            alt={'product'}
                                        />

                                        <div className={` ${isGallery ? '-mt-12' : '-mt-30'} `}>
                                            {handleImageInput(imageProp)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (imageProp) {
            return (
                <>
                    <div className={`${width} ${' '}  ${height}   flex`}>
                        <div
                            className="text-center flex flex-col w-full"
                            onMouseOver={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            <div className="flex flex-col m-auto  w-full">
                                {isUploading ? (
                                    <div className="m-auto mx-auto flex flex-col w-full">
                                        <NormalLoader />
                                    </div>
                                ) : (
                                    <div className="-mt-10">
                                        <img
                                            className={`${height} object-contain  inset-0 rounded bg-cover bg-center  ${
                                                hover ? ' opacity-50 ' : ''
                                            } z-0`}
                                            src={imageProp}
                                            alt={'product'}
                                        />
                                        <div className={` ${isGallery ? '-mt-12' : '-mt-30'} `}>
                                            {handleImageInput(productLocation)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div
                        className={`${width} ${
                            disabled && !imageProp ? 'bg-gray-100' : 'bg-gray-100'
                        } ${height}  rounded flex`}
                    >
                        <div className="text-center flex flex-col w-full m-auto">
                            {isUploading ? (
                                <div className="m-auto mx-auto flex flex-col w-full">
                                    <NormalLoader />
                                </div>
                            ) : (
                                <>
                                    {!disabled ? (
                                        <>
                                            <div
                                                className={` ${
                                                    isGallery ? 'mt-8 m-auto' : 'm-auto'
                                                }`}
                                            >
                                                <Images
                                                    width={`${isGallery ? 'w-10' : 'w-20'}`}
                                                    height={`${isGallery ? 'h-10' : 'h-20'}`}
                                                    src="/images/icons/upload.png"
                                                    alt="upload"
                                                />
                                            </div>
                                            {placeholder ? (
                                                <div className=" font-light text-gray-600  text-lg">
                                                    Drag and drop product images here or
                                                </div>
                                            ) : null}
                                            <label
                                                className=" justify-center cursor-pointer underline flex"
                                                htmlFor={isEdit ? inputId : null}
                                            >
                                                <div className="flex flex-col">
                                                    <div
                                                        id={
                                                            inputId
                                                                ? `upload-image-button-${inputId}`
                                                                : ''
                                                        }
                                                        className={`${
                                                            isGallery ? 'text-xs' : 'text-lg'
                                                        } font-light text-purple-600  text-lg`}
                                                    >
                                                        Upload Image
                                                    </div>
                                                </div>
                                            </label>
                                        </>
                                    ) : (
                                        <div>
                                            <div className="ml-12 mt-5 relative">
                                                {/* <Images
                                                    width={`${isGallery ? 'w-10' : 'w-20'}`}
                                                    height={`${isGallery ? 'h-10' : 'h-20'}`}
                                                    src="/images/icons/noImage.png"
                                                    alt="upload"
                                                /> */}
                                                {/* needed in future */}
                                            </div>
                                            {'No image added'}
                                        </div>
                                    )}

                                    <input
                                        className={`invisible focus:outline-none w-full pb-3 border-b-2 pl-2 focus:border-blue-900`}
                                        id={inputId}
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        disabled={disabled ? true : false}
                                        onChange={(e) => {
                                            handleImage(e);
                                            e.target.value = null;
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <div className="">
            <div className="flex flex-row">{handleProfilePic()}</div>

            {cropModalVisible ? (
                <>
                    {isGallery ? (
                        <ImageCropModal
                            modalVisible={cropModalVisible}
                            setModalVisible={setCropModalVisible}
                            uploadImageToS3={uploadImageToS3}
                            setImageUrl={setImageSrc}
                            uploadedImageUrl={productImage && URL.createObjectURL(productImage)}
                            uploadedImage={productImage}
                            title="Crop image and upload"
                            btnText="Set a Product image"
                            zoom={zoom}
                            cropRect={true}
                            setZoom={setZoom}
                            aspectRatio={200 / 200}
                            enableTransparency={transparencyEnabled}
                        />
                    ) : (
                        <ImageCropModal
                            modalVisible={cropModalVisible}
                            setModalVisible={setCropModalVisible}
                            uploadImageToS3={uploadImageToS3}
                            setImageUrl={setImageSrc}
                            uploadedImageUrl={productImage && URL.createObjectURL(productImage)}
                            uploadedImage={productImage}
                            title="Crop image and upload"
                            btnText="Set a Product image"
                            zoom={zoom}
                            cropRect={true}
                            setZoom={setZoom}
                            aspectRatio={aspectRatio || 16 / 9}
                            enableTransparency={transparencyEnabled}
                        />
                    )}
                </>
            ) : null}
            <div className="text-red-400 flex justify-center -ml-4">{imageError}</div>
        </div>
    );
}
