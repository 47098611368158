import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { SORT_ASC } from '../../../../constants/common';
import { pagination } from '../../../../constants/config/constants';
import { ClientsEnum } from '../../../../enums/apoloClient/client-enum';
import { FETCH_PRODUCT_GROUPS } from '../../../../queries/ProductGroupQueries';
import DataTable from '../../../ui/organisms/DataTable';
import SearchBar from '../../../ui/molecules/SearchBar';

interface Props {
    selectedTab: number;
    selectedGroups: any;
    setAvaialbleGroupsCount: (value) => void;
    addCheckedGroups: (value) => void;
    setCheckedRowIds?: (value) => void;
}

export default function AvailableGroupsListView({
    selectedTab,
    selectedGroups,
    setAvaialbleGroupsCount,
    addCheckedGroups,
    setCheckedRowIds,
}: Props) {
    const [groupData, setGroupData] = useState([]);
    const [groupInitialData, setGroupInitialData] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalGroups, setTotalGroups] = useState(0);
    const [initialCheckedGroups, setInitialCheckedGroups] = useState({});
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const [fetchGroupData, { loading }] = useLazyQuery(FETCH_PRODUCT_GROUPS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
            setGroupData(data?.productGroups?.productGroups);
            setGroupInitialData(data?.productGroups?.productGroups);
            setTotalGroups(data?.productGroups?.totalProductGroups);
            setAvaialbleGroupsCount(data?.productGroups?.totalProductGroups);
        },
    });

    const availableGroupColumns = useMemo(
        () => [
            {
                Header: 'Group ID',
                accessor: 'productGroupId',
            },
            {
                Header: 'Group Title',
                accessor: 'name',
            },
            {
                Header: 'Display Name',
                accessor: 'displayName',
            },
            {
                Header: 'Product Type',
                accessor: 'productType',
            },
            {
                Header: 'Product Count',
                accessor: 'productsCount',
            },
            {
                Header: 'Status',
                accessor: '',
                Cell: function showBadges({ row }) {
                    const isActive = row?.original?.isActive;
                    const isFeatured = row?.original?.featured;
                    return (
                        <div className="flex gap-2">
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                    row?.original?.isActive === true
                                        ? 'bg-green-100 text-green-600'
                                        : row?.original?.isActive === false
                                        ? 'bg-red-100 text-red-600'
                                        : ''
                                }`}
                            >
                                {row?.original?.isActive === true ? 'Active' : 'Inactive'}
                            </span>
                            {row?.original?.featured ? (
                                <span className="inline-flex rounded-full h-6 px-3 justify-center items-center text-sm bg-yellow-200 text-yellow-600 ml-1">
                                    Featured
                                </span>
                            ) : null}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const groupNames = groupInitialData
        ?.map((group) => group?.name)
        .concat(groupData?.map((group) => group?.productGroupId));

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedTab, pageSize]);

    useEffect(() => {
        if (searchClicked && searchTerm) {
            fetchGroupData({
                variables: {
                    filter: {
                        productType: null,
                        active: null,
                        deleted: null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: 'id',
                    order: SORT_ASC,
                    searchText: searchClicked && searchTerm?.length > 1 ? searchTerm : null,
                },
            });
            if (searchClicked && searchTerm?.length > 1) {
                setCurrentPage(1);
            }
        } else {
            fetchGroupData({
                variables: {
                    filter: {
                        productType: null,
                        deleted: null,
                    },
                    offset: currentPage - 1 || 0,
                    limit: Math.ceil(pageSize) || 10000,
                    sort: 'id',
                    order: SORT_ASC,
                },
            });
        }
    }, [currentPage, pageSize, searchClicked, searchTerm]);

    useEffect(() => {
        const checkedList = initialCheckedGroups;
        if (selectedGroups) {
            selectedGroups?.map((rowGroup) => {
                checkedList[rowGroup?.id] = true;
            });
        }
        setInitialCheckedGroups(checkedList);
    }, [selectedGroups, setInitialCheckedGroups]);

    return (
        <>
            <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500 px-4 pb-4">
                <SearchBar
                    id="add-new-category-search-field"
                    options={groupNames}
                    handleChange={(e) => {
                        setSearchTerm(e.target.value);
                        setSearchClicked(true);
                    }}
                    placeHolder={'Search Group by Name or ID'}
                />
            </div>
            <div className="w-full">
                {groupData?.length || loading ? (
                    <DataTable
                        columns={availableGroupColumns}
                        data={groupData}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex} //future need
                        setDefaultPageSize={setPageSize}
                        radioBtn={false}
                        serverSidePagination={true}
                        currentPage={currentPage}
                        loading={loading}
                        pageSizes={pageSize}
                        setCurrentPage={setCurrentPage}
                        totalItems={totalGroups}
                        isCategoryProductsView={true}
                        checkbox={true}
                        checkboxHeader={true}
                        initialSelectedGroups={initialCheckedGroups}
                        setCheckedGroups={addCheckedGroups}
                        setCheckedRowIds={setCheckedRowIds}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                            {availableGroupColumns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </>
    );
}
