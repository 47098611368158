import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCookie } from '../../utils/cookiesService';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/rootActions';

export default function LoginValidity() {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const token = getCookie('access_token');

    useEffect(() => {
        if (token) {
            const decoded_token: any = jwtDecode(token);
            if (decoded_token?.exp && decoded_token.exp * 1000 < new Date().getTime()) {
                dispatch(logout());
                history.push('/');
            }
        }
    }, [dispatch, history, location, token]);
    return <></>;
}
