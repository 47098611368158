import { useEffect, useState } from 'react';
import TableTabView from '../productScreens/TableTabView';
import GroupSelectedItemsView from './GroupSelectedItemsView';
import GroupAvailableItemsListView from './GroupAvailableItemsListView';
import { ProductType } from '../../../enums/product';

interface Props {
    productType: string;
    groupItems: any;
    currentPageName: string;
    setGroupItems: (value) => void;
    previousSelectedItems?;
    setSelectedTabId?: (value) => void;
    setPreviousSelectedItems?: (value) => void;
    groupId?: number;
}

export default function GroupItemSelectionView({
    productType,
    groupItems,
    currentPageName,
    setGroupItems,
    previousSelectedItems,
    setSelectedTabId,
    setPreviousSelectedItems,
    groupId,
}: Props) {
    const [selectedTab, setSelectedTab] = useState(1);
    const [categoryProductsCounts, setCategoryProductsCounts] = useState({
        all: 0,
        selected: 0,
    });
    const [availableItemsCount, setAvailableItemsCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkedRowIds, setCheckedRowIds] = useState(null);
    let deletedIds = [];

    useEffect(() => {
        if (availableItemsCount) {
            setCategoryProductsCounts({
                all: availableItemsCount,
                selected: groupItems?.length,
            });
        }
    }, [availableItemsCount, groupItems]);

    useEffect(() => {
        if (previousSelectedItems) {
            const allItemsList = [...selectedItems];

            previousSelectedItems?.forEach((previousItem) => {
                const exists = allItemsList?.some((item) => item?.id === previousItem?.id);
                if (!exists) allItemsList?.push(previousItem);
            });

            setSelectedItems(allItemsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousSelectedItems, setSelectedItems]);

    useEffect(() => {
        if (selectedItems) {
            const items = [];
            selectedItems?.map((selectedItem) => {
                items.push(selectedItem);
            });
            setGroupItems(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems, productType]);

    const orderProducts = (orderedList) => {
        setGroupItems(orderedList);
    };

    const removeDeletedItems = (deletedId) => {
        if (!deletedIds.includes(deletedId)) {
            const updateDeletedItemIds = [...deletedIds, deletedId];
            deletedIds = updateDeletedItemIds;
        }

        const itemsList = [...selectedItems];
        const filteredList = itemsList?.filter((item) => !deletedIds.includes(item?.id));

        setSelectedItems(filteredList);
        if (setPreviousSelectedItems) {
            setPreviousSelectedItems(filteredList);
        }
    };

    const addCheckedItems = (checkedList) => {
        const itemList = [...selectedItems];
        checkedList?.forEach((checkedItem) => {
            const exists = itemList?.some((item) => item?.id === checkedItem?.id);
            if (!exists) {
                itemList?.push(checkedItem?.original);
            }
        });

        setSelectedItems(itemList);
    };

    useEffect(() => {
        if (checkedRowIds) {
            const itemList = [...selectedItems];
            const filteredList = itemList?.filter((item) => {
                const isItemRemoved = checkedRowIds[item?.id] !== undefined;
                return isItemRemoved;
            });
            setGroupItems(filteredList);
            setSelectedItems(filteredList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedRowIds, setSelectedItems]);

    useEffect(() => {
        if (selectedTab && setSelectedTabId) {
            setSelectedTabId(selectedTab);
        }
    }, [selectedTab, setSelectedTabId]);

    return (
        <>
            {(productType === ProductType.VOUCHER || productType === ProductType.TOPUP) && (
                <div className="w-full">
                    <div className="w-full p-5 bg-yellow-100 text-yellow-600">
                        Note : The unavailable Items are already allocated to a Product Group
                    </div>
                    <div className={`w-full ml-4 py-4 `}>
                        <TableTabView
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            categoryProductsCounts={categoryProductsCounts}
                        />
                    </div>
                    <div className="w-full">
                        {selectedTab === 1 ? (
                            <GroupAvailableItemsListView
                                selectedTab={selectedTab}
                                selectedItems={selectedItems}
                                addCheckedItems={addCheckedItems}
                                setAvaialbleItemsCount={setAvailableItemsCount}
                                setCheckedRowIds={setCheckedRowIds}
                                productType={productType}
                                currentPageName={currentPageName}
                                groupId={groupId}
                            />
                        ) : (
                            <GroupSelectedItemsView
                                groupItems={groupItems}
                                removeDeletedItems={removeDeletedItems}
                                orderProducts={orderProducts}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
