/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Loader from '../../../utils/loader';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';
import DataTable from '../../ui/organisms/DataTable';

import TableTabView from '../productScreens/TableTabView';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';

import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { FETCH_ADMIN_PROFILE } from '../../../queries/AdminProfileQueries';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserDetails, getRoles, getUsersWithRole } from '../../../redux/rootActions';
import { RootState } from '../../../redux/rootReducer';
import SearchBar from '../../ui/molecules/SearchBar';
import {
    INSTANT_EDIT_OPTION_EDIT_USER,
    INSTANT_EDIT_OPTION_VIEW_USER,
    USER_TYPE_EXISTING,
    USER_TYPE_INVITED,
} from '../../../constants/users';
import { useHistory } from 'react-router-dom';
import { Buttons } from '../../ui/atoms/Button';
import AddUserOptionModal from '../../templates/modals/AddNewUserModal';
import InviteUserFormModal from '../../templates/modals/InviteUserformModal';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
    REGISTERED,
} from '../../../constants/profile';
import { ClientRoles, ClientRolesText, ClientRolesTextBlock } from '../../../enums/keycloak';
import DataTableAlter from '../../ui/organisms/DataTableAlter';

export default function UsersScreen() {
    const [completeQuery, setCompleteQuery] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const history = useHistory();
    const [clickedUser, setClickedUser] = useState(null);

    const [usersData, setUsersData] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [inviteUserFormModal, setInviteUserFormModal] = useState(false);
    const [complete, setComplete] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [userDataInitial, setUserDataInitial] = useState([]);
    const [keyClockSortedListed, setKeyClockSortedList] = useState([]);
    const [userDataUnMutated, setUserDataMutated] = useState([]);
    const [isFilterClicked, setIsFilterClicked] = useState(false);
    const [message, setMessage] = useState('');
    const [confirmationModalYesClicked, setConfirmationModalYesClicked] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showAddNewUser, setShowAddNewUser] = useState(false);
    const [queryError, setQueryError] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [allUsers, setAllUSers] = useState([]);
    const [partnerUsers, setPartnerUsers] = useState([]);
    const [storeAdminUsers, setStoreAdminUsers] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [isSelectedRoleRemoved, setIsSelectedRoleRemoved] = useState(true);
    const [searchClicked, setSearchClicked] = useState(false);
    const [rolesValid, setRolesValid] = useState([]);
    const dispatch = useDispatch();
    const [invitedUserCount, setInvitedUserCount] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(false);

    const {
        data: userData,
        loading: userLoading,
        called,
    } = useQuery(FETCH_ADMIN_PROFILE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            const userDataUpdated = userData?.users;
            setUsersData(userDataUpdated);
            setUserDataMutated(userData?.users);
            setUserDataInitial(userData?.users);
            setCompleteQuery(true);
        },
    });
    const keyCloakUserDetails = useSelector((state: RootState) => state.auth.usersList);

    useEffect(() => {
        const sortedList = keyCloakUserDetails?.sort(
            (a, b) => a?.createdTimestamp - b?.createdTimestamp,
        );

        setKeyClockSortedList(sortedList);
    }, [keyCloakUserDetails]);

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const roles = useSelector((state: RootState) => state.auth.roles);

    useEffect(() => {
        if (roles?.length) {
            const rolesDropList = roles?.map((role) => ({
                text: role.name,
                value: role.name,
            }));
            const roleList = rolesDropList?.filter((role) => role?.text !== ClientRoles?.USER);

            const rolesNames = roleList?.map((role) => ({
                value: role?.value,
                text:
                    role?.text === ClientRoles?.STORE_ADMIN
                        ? ClientRolesTextBlock.STORE_ADMIN
                        : ClientRoles?.PARTNER
                        ? ClientRolesTextBlock.PARTNER
                        : null,
            }));
            setRolesValid(rolesNames);
        }
    }, [roles, roles?.length]);

    useEffect(() => {
        const rolesArray = roles?.map((role) => role?.name);

        rolesArray?.map((role) => {
            if (role === ClientRoles.STORE_ADMIN) {
                dispatch(getUsersWithRole(ClientRoles.STORE_ADMIN));
            }
            if (role === ClientRoles.PARTNER) {
                dispatch(getUsersWithRole(ClientRoles.PARTNER));
            }
        });
    }, [dispatch, roles]);

    const storeAdminUsersByRoles = useSelector(
        (state: RootState) => state.auth.storeAdminUsersByRoles,
    );

    const partnerUsersByRoles = useSelector((state: RootState) => state.auth.partnerUsersByRoles);

    const keylogUsers = keyClockSortedListed?.map((user) => ({
        userId: user?.userId,
        userName: user?.username,
        firstName: user?.firstName,
        lastName: user?.lastName,
        contact: {
            primaryEmail: user?.email,
        },
    }));

    const keyClockUsersUserNames = keylogUsers?.map((user) => user?.userName);
    const registeredUsersUserNames = userDataInitial?.map((user) => user?.userName);

    const filteredNames = keyClockUsersUserNames?.filter((user) => {
        return !registeredUsersUserNames?.includes(user);
    });
    const filteredUserList = [];
    filteredNames?.map((userNames) => {
        const users = keylogUsers?.filter((user) => {
            if (userNames === user?.userName) {
                filteredUserList?.push(user);
                return user;
            }
        });

        return users;
    });

    const invitedUserList = filteredUserList?.map((user) => ({
        userId: user?.userId,
        userName: user?.userName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        contact: {
            primaryEmail: user?.contact?.primaryEmail,
        },
        role: user?.role,
    }));

    useEffect(() => {
        let usersArray = [];

        if (!isFilterClicked) {
            setSelectedRole(null);
            setIsSelectedRoleRemoved(true);
        }

        const storeAdminUsers = storeAdminUsersByRoles?.map((user) => user?.username);
        const partnerUsers = partnerUsersByRoles?.map((user) => user?.username);

        if (selectedTab === 1) {
            const activeUsers = userDataInitial?.filter((user) => {
                return user?.isActive === true;
            });

            const activeStoreAdmins = activeUsers?.filter((user) => {
                return storeAdminUsers?.some((storeAdminsUserName) => {
                    return storeAdminsUserName === user?.userName;
                });
            });
            const activePartners = activeUsers?.filter((user) => {
                return partnerUsers?.some((partnerUserName) => {
                    return partnerUserName === user?.userName;
                });
            });

            setStoreAdminUsers(activeStoreAdmins?.map((user) => user?.userName));
            setPartnerUsers(activePartners?.map((user) => user?.userName));

            usersArray = activeUsers?.map((user) => ({
                userId: user?.userId,
                userName: user?.userName,
                firstName: user?.firstName,
                lastName: user?.lastName,
                contact: {
                    primaryEmail: user?.contact?.primaryEmail,
                },
            }));

            if (selectedRole > '0' && isFilterClicked) {
                if (selectedRole === ClientRoles.STORE_ADMIN) {
                    usersArray = usersArray.filter((user) => {
                        return storeAdminUsers?.includes(user?.userName);
                    });
                } else if (selectedRole === ClientRoles.PARTNER) {
                    usersArray = usersArray.filter((user) => {
                        return partnerUsers?.includes(user?.userName);
                    });
                }
            }
            const noOfPages = Math.ceil(usersArray?.length / pageSize);
            const pageIndexFilteredData = usersArray?.slice(
                pageIndex * pageSize,
                pageIndex * pageSize + pageSize,
            );
            setPageCount(noOfPages);
            setUsersData(usersArray);
            setFilteredData(usersArray); //future need
        } else if (selectedTab === 3) {
            const inActiveUsers = userDataInitial?.filter((user) => {
                return user?.isActive === false;
            });

            const activeStoreAdmins = inActiveUsers?.filter((user) => {
                return storeAdminUsers?.some((storeAdminsUserName) => {
                    return storeAdminsUserName === user?.userName;
                });
            });
            const activePartners = inActiveUsers?.filter((user) => {
                return partnerUsers?.some((partnerUserName) => {
                    return partnerUserName === user?.userName;
                });
            });

            setStoreAdminUsers(activeStoreAdmins?.map((user) => user?.userName));
            setPartnerUsers(activePartners?.map((user) => user?.userName));

            usersArray = inActiveUsers?.map((user) => ({
                userId: user?.userId,
                userName: user?.username,
                firstName: user?.firstName,
                lastName: user?.lastName,
                contact: {
                    primaryEmail: user?.contact?.primaryEmail,
                },
            }));

            if (selectedRole > '0' && isFilterClicked) {
                if (selectedRole === ClientRoles.STORE_ADMIN) {
                    usersArray = usersArray.filter((user) => {
                        return storeAdminUsers?.includes(user?.userName);
                    });
                } else if (selectedRole === ClientRoles.PARTNER) {
                    usersArray = usersArray.filter((user) => {
                        return partnerUsers?.includes(user?.userName);
                    });
                }
            }

            const noOfPages = Math.ceil(usersArray?.length / pageSize);
            const pageIndexFilteredData = usersArray.slice(
                pageIndex * pageSize,
                pageIndex * pageSize + pageSize,
            );
            setPageCount(noOfPages);
            setUsersData(usersArray);
            setFilteredData(usersArray); //future need
        } else if (selectedTab == 2) {
            const keylogUsers = keyClockSortedListed?.map((user) => ({
                userId: user?.userId,
                userName: user?.username,
                firstName: user?.firstName,
                lastName: user?.lastName,
                contact: {
                    primaryEmail: user?.email,
                },
            }));

            const activeStoreAdmins = keylogUsers?.filter((user) => {
                return storeAdminUsers?.some((storeAdminsUserName) => {
                    return storeAdminsUserName === user?.userName;
                });
            });
            const activePartners = keylogUsers?.filter((user) => {
                return partnerUsers?.some((partnerUserName) => {
                    return partnerUserName === user?.userName;
                });
            });

            setStoreAdminUsers(activeStoreAdmins?.map((user) => user?.userName));
            setPartnerUsers(activePartners?.map((user) => user?.userName));

            const keyClockUsersUserNames = keylogUsers?.map((user) => user?.userName);
            const registeredUsersUserNames = userDataInitial?.map((user) => user?.userName);

            const filteredNames = keyClockUsersUserNames?.filter((user) => {
                return !registeredUsersUserNames?.includes(user);
            });
            const filteredUserList = [];
            filteredNames?.map((userNames) => {
                const users = keylogUsers?.filter((user) => {
                    if (userNames === user?.userName) {
                        filteredUserList?.push(user);
                        return user;
                    }
                });

                return users;
            });

            let listOfInvitedUsers = filteredUserList?.map((user) => ({
                userId: user?.userId,
                userName: user?.userName,
                firstName: user?.firstName,
                lastName: user?.lastName,
                contact: {
                    primaryEmail: user?.contact?.primaryEmail,
                },
                role: user?.role,
            }));

            if (selectedRole > '0' && isFilterClicked) {
                if (selectedRole === ClientRoles.STORE_ADMIN) {
                    listOfInvitedUsers = listOfInvitedUsers.filter((user) => {
                        return storeAdminUsers?.includes(user?.userName);
                    });
                } else if (selectedRole === ClientRoles.PARTNER) {
                    listOfInvitedUsers = listOfInvitedUsers.filter((user) => {
                        return partnerUsers?.includes(user?.userName);
                    });
                }
            }
            setInvitedUsers(listOfInvitedUsers);
            setInvitedUserCount(listOfInvitedUsers?.length);

            const noOfPages = Math.ceil(listOfInvitedUsers?.length / pageSize);
            const pageIndexFilteredData = listOfInvitedUsers.slice(
                pageIndex * pageSize,
                pageIndex * pageSize + pageSize,
            );

            setPageCount(noOfPages);
            setUsersData(listOfInvitedUsers);
            setFilteredData(listOfInvitedUsers); //future need
        }
    }, [
        pageIndex,
        pageSize,
        invitedUsers.length,
        selectedTab,
        userDataInitial,
        keyClockSortedListed,
        storeAdminUsersByRoles,
        partnerUsersByRoles,
        selectedRole,
        isFilterClicked,
    ]);

    useEffect(() => {
        if (searchTerm?.length > 3 && selectedTab === 1) {
            const searchList = userDataUnMutated;
            setSearchClicked(true);
            const searchResult = searchList?.filter((user) =>
                user?.contact?.primaryEmail.toUpperCase().includes(searchTerm.toUpperCase()),
            );

            if (searchResult?.length) {
                setUsersData(searchResult);
            } else {
                setUsersData([]);
            }
        } else if (searchTerm?.length > 3 && selectedTab === 2) {
            const searchLists = invitedUserList;
            setSearchClicked(true);
            const searchResult = searchLists?.filter((user) =>
                user?.contact?.primaryEmail.toUpperCase().includes(searchTerm.toUpperCase()),
            );

            if (searchResult?.length) {
                setUsersData(searchResult);
            } else {
                setUsersData([]);
            }
        } else {
            setUsersData(filteredData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, searchTerm, invitedUsers, userDataUnMutated, selectedTab]);

    const [userStatus, setUserStatus] = useState({
        active: 0,
        invitedUsers: 0,
        inactive: 0,
    });

    useEffect(() => {
        dispatch(getAllUserDetails());
    }, [dispatch]);

    useEffect(() => {
        const userCounts = {
            active: 0,
            invitedUsers: 0,
            inactive: 0,
        };

        userDataInitial?.map((user) => {
            if (user?.isActive) {
                userCounts.active++;
            } else if (!user?.isActive) {
                userCounts.inactive++;
            }
        });

        if (keyClockSortedListed?.length) {
            const keyClockUsersUserNames = keyClockSortedListed?.map((user) => user?.username);
            const registeredUsersUserNames = userDataInitial?.map((user) => user?.userName);

            const filteredNames = keyClockUsersUserNames?.filter((user) => {
                return !registeredUsersUserNames?.includes(user);
            });

            userCounts.invitedUsers = filteredNames?.length;
        }

        setUserStatus(userCounts);
    }, [invitedUserCount, keyClockSortedListed, pageSize, selectedTab, userDataInitial]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    const isRegistered = localStorage.getItem('isRegistered');

    useEffect(() => {
        if (warningModalOkayClicked) {
            history.push('/adminProfile');
        }
    }, [history, warningModalOkayClicked]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    }, [isRegistered]);

    useEffect(() => {
        const instantMenuList = [];

        selectedTab === 1
            ? instantMenuList.push({
                  text: 'View',
                  value: INSTANT_EDIT_OPTION_VIEW_USER,
              })
            : null;

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const columnsTab1 = React.useMemo(
        () => [
            { id: 'userId', Header: 'User ID', accessor: 'userId' },

            {
                id: 'email',
                Header: 'Email',
                accessor: 'contact.primaryEmail',
            },
            {
                id: 'firstName',
                Header: 'First name',
                accessor: 'firstName',
            },
            {
                id: 'lastName',
                Header: 'Last name',
                accessor: 'lastName',
            },
            {
                Header: 'Role',
                accessor: 'userName',
                Cell: function points({ value }) {
                    if (storeAdminUsers?.includes(value)) {
                        return <span>{ClientRolesTextBlock.STORE_ADMIN}</span>;
                    } else if (partnerUsers?.includes(value)) {
                        return <span>{ClientRolesTextBlock.PARTNER}</span>;
                    } else {
                        return <span>{''}</span>;
                    }
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: function showActionColumn({ row }) {
                    return (
                        <div
                            className="cursor__pointer"
                            id={`${row?.original?.userId}-action-button`}
                        >
                            <button
                                value="menu cursor-pointer"
                                className="text-gray -500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                            >
                                <BsIcons.BsThreeDots className="mx-auto" />
                            </button>
                        </div>
                    );
                },
            },
        ],
        [partnerUsers, storeAdminUsers],
    );
    const columnsTab2 = React.useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'contact.primaryEmail',
            },
            {
                Header: 'First name',
                accessor: 'firstName',
            },
            {
                Header: 'Last name',
                accessor: 'lastName',
            },
            {
                Header: 'Role',
                accessor: 'userName',
                Cell: function points({ value }) {
                    if (storeAdminUsers?.includes(value)) {
                        return <span>{ClientRolesTextBlock.STORE_ADMIN}</span>;
                    } else if (partnerUsers?.includes(value)) {
                        return <span>{ClientRolesTextBlock.PARTNER}</span>;
                    } else {
                        return <span>{''}</span>;
                    }
                },
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: (
                    <div className="cursor__pointer">
                        {selectedTab === 1 || selectedTab === 3 ? (
                            <>
                                <button
                                    id="action-button"
                                    value="menu cursor-pointer"
                                    className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                                >
                                    <BsIcons.BsThreeDots className="mx-auto" />
                                </button>
                            </>
                        ) : null}
                    </div>
                ),
            },
        ],
        [partnerUsers, selectedTab, storeAdminUsers],
    );
    const userSearchList = keylogUsers?.map((user) => user?.contact?.primaryEmail);

    useEffect(() => {
        if (
            selectedMenuItem === INSTANT_EDIT_OPTION_VIEW_USER &&
            clickedUser &&
            selectedTab === 1
        ) {
            localStorage.setItem('view-user', clickedUser?.userName);
            localStorage.setItem('user_type', USER_TYPE_EXISTING);
            localStorage.getItem('current_user_username');
            history.push('/users/view');
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_VIEW_USER &&
            clickedUser &&
            selectedTab === 2
        ) {
            localStorage.setItem('view-user', clickedUser?.contact?.primaryEmail);
            localStorage.setItem('user_type', USER_TYPE_INVITED);
            history.push('/users/view');
        }
    }, [clickedUser, history, selectedMenuItem, selectedTab]);

    if (isRegistered === REGISTERED && userLoading) return <Loader />;
    if (isRegistered === REGISTERED && !keyClockSortedListed?.length) {
        return (
            <div>
                <Loader />
            </div>
        );
    }
    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <div className="w-full ml-4 pt-4">
                {showToast && (
                    <div
                        className={`z-50 -mt-2 mr-5 h-16 flex flex-row  fixed w-9/12 mix-blend-normal mb-2 px-4 py-2 rounded-md items-center transition-transform ${
                            queryError ? 'bg-red-100' : 'bg-green-100'
                        }`}
                    >
                        <div
                            className={`flex-1 px-5 font-poppins font-bold ${
                                queryError ? 'text-red-500' : 'text-green-500'
                            }`}
                        >
                            {message}
                        </div>
                        <div className="flex w-2/10 px-5">
                            <button onClick={() => setShowToast(false)}>
                                <BsIcons.BsX className="mx-auto text-lg" />
                            </button>
                        </div>
                    </div>
                )}
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        usersCounts={userStatus}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        setYesBtnClick={setConfirmationModalYesClicked}
                        setNoBtnClick={setConfirmationModalNoClicked}
                    />
                    <WarningMessageModal
                        showWarningModal={showWarningModal}
                        setShowWarningModal={setShowWarningModal}
                        warningModalBody={warningModalBody}
                        warningModalTitle={warningModalTitle}
                        setProcessFinishedClicked={setWarningModalOkayClicked}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div className="flex realative lg:mr-4">
                        <button
                            id="filter-button"
                            onKeyDown={() => {
                                setIsFilterClicked(!isFilterClicked);
                            }}
                            onClick={() => setIsFilterClicked(!isFilterClicked)}
                            className={`bg-transparent hover:bg-purple-500 hover:text-white py-2 px-4 border border-gray-300 hover:border-transparent rounded-md ${
                                isFilterClicked ? 'bg-purple-500 text-white' : 'text-gray-500'
                            }`}
                        >
                            <span className="flex">
                                <BsIcons.BsFillFunnelFill /> <span className="ml-2">Filter</span>
                            </span>
                        </button>
                    </div>
                    <div className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3 text-gray-500">
                        <SearchBar
                            id="user-list-search-field"
                            options={userSearchList}
                            handleChange={(e) => handleChange(e)}
                            placeHolder={'Search user by email'}
                        />
                    </div>
                </div>
                <Buttons
                    name="Invite new user"
                    type="submit"
                    buttonType="primary"
                    id="invite"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        setShowAddNewUser(true);
                    }}
                />
                <AddUserOptionModal
                    setShowAddNewUser={setShowAddNewUser}
                    showAddNewUser={showAddNewUser}
                    setInviteUserFormModal={setInviteUserFormModal}
                />
                {!showAddNewUser ? (
                    <>
                        <InviteUserFormModal
                            setShowInviteNewUser={setInviteUserFormModal}
                            showInviteNewUser={inviteUserFormModal}
                        />
                    </>
                ) : null}
            </div>
            {isFilterClicked ? (
                <>
                    <div className="flex flex-row w-full h-24 md-24 p-2">
                        <div className="relative w-1/3 px-2">
                            <div className="flex flex-wrap flex-col relative">
                                <div className="text-gray-600 w-25 text-center font-poppins text-sm lg:text-sm h-max bg-white z-10 -mb-3 ml-4 border-opacity-20">
                                    Filter by role
                                </div>

                                <select
                                    className="px-4 py-3 z-0 rounded-lg outline-none border  bg-white border-gray-400 hover:border-purple-500"
                                    id="filter-by-role"
                                    placeholder="actions"
                                    onChange={(e) => {
                                        setSelectedRole(e.target.value);
                                        setIsSelectedRoleRemoved(false);
                                    }}
                                    onBlur={(e) => {
                                        setSelectedRole(e.target.value);
                                        setIsSelectedRoleRemoved(false);
                                    }}
                                    disabled={rolesValid?.length <= 0}
                                >
                                    {isSelectedRoleRemoved ? (
                                        <option value="" disabled selected>
                                            Select roles filters
                                        </option>
                                    ) : null}

                                    {rolesValid?.map((option: any, index) => {
                                        return (
                                            <option value={option.value} key={index}>
                                                {option.text}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            {isFilterClicked && !isSelectedRoleRemoved && selectedRole?.length ? (
                <div className="bg-purple-100 w-full h-20 flex  -mt-3 py-2 px-4 my-5 ">
                    <div className="w-full -mt-2">
                        <div className="flex flex-col">
                            <div className="text-purple-500 font-poppins  flex justify-between p-5 -mt-1">
                                <div className="flex">
                                    {selectedRole ? (
                                        <div className="border-2 border-purple-300 w-auto h-auto flex py-2 px-3 rounded-md mr-5">
                                            <div className="font-poppins mt-1 mr-3">
                                                {selectedRole === ClientRoles.STORE_ADMIN
                                                    ? ClientRolesTextBlock.STORE_ADMIN
                                                    : selectedRole === ClientRoles.PARTNER
                                                    ? ClientRolesTextBlock.PARTNER
                                                    : null}
                                            </div>
                                            <button
                                                className="text-purple-700 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedRole('0');

                                                    setIsSelectedRoleRemoved(true);
                                                }}
                                            >
                                                x
                                            </button>
                                        </div>
                                    ) : null}
                                </div>
                                {!isSelectedRoleRemoved ? (
                                    <div className="w-auto h-auto p-3 border-l-2 border-gray-300">
                                        <div className="right-0 my-auto  mx-10 border-l-2 flex underline text-lg">
                                            <button
                                                className="text-purple-400 font-bold  text-lg"
                                                onClick={() => {
                                                    setSelectedRole('0');

                                                    setIsSelectedRoleRemoved(true);
                                                }}
                                            >
                                                Clear all
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className="w-full flex-col">
                {usersData?.length ? (
                    <DataTableAlter //without selection of row
                        columns={selectedTab === 1 || selectedTab === 3 ? columnsTab1 : columnsTab2}
                        data={usersData}
                        // pageCount={pageCount}
                        setSelectedItems={setSelectedUsers}
                        // setPageIndex={setPageIndex}
                        // setDefaultPageSize={setPageSize} //future need
                        menuItems={instantEditMenuItems}
                        setExposeClickedItem={setClickedUser}
                        setSelectedMenuItem={setSelectedMenuItem}
                        selectedTab={selectedTab}
                        isInitialLoad={isInitialLoad}
                        setIsInitialLoad={setIsInitialLoad}
                    />
                ) : isFilterClicked && !userData?.length ? (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columnsTab1?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found for selected filter.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                ) : searchClicked && !usersData?.length ? (
                    <div className="flex p-10 justify-center">
                        <div className=" mx-1 ">Your search</div>
                        <div className="text-purple-500">{`"${searchTerm}"`}</div>
                        <div className=" mx-1 ">did not match any results</div>
                    </div>
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-3 px-5 bg-purple-100 justify-between flex">
                            {columnsTab1?.map((column) => {
                                return (
                                    <div className="text-black font-poppins font-semibold flex ml-12">
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">No data found.</div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
