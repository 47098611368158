import { useEffect, useState } from 'react';
import TableTabView from '../../productScreens/TableTabView';
import AvailableGroupsListView from './AvailableGroupsListView';
import SelectedGroupsView from './SelectedGroupsView';

interface Props {
    selectedCatalogs?;
    setSelectedGroupsCatalogs?: (value) => void;
    previousSelectedGroups?;
    setSelectedTabId?: (value) => void;
    setPreviousSelectedGroups?: (value) => void;
    previousSelectedGroupCatalogs?;
    setGroupCatalogs?: (value) => void;
    groupCatalogs?;
    initialGroupCatalogs?;
    setInitialGroupCatalogs?: (value) => void;
    activeCatalogs?;
    categoryGroups: any;
    setCategoryGroups: (value) => void;
}

export default function CategoryGroupSelectionView({
    selectedCatalogs,
    setSelectedGroupsCatalogs,
    previousSelectedGroups,
    setSelectedTabId,
    setPreviousSelectedGroups,
    previousSelectedGroupCatalogs,
    setGroupCatalogs,
    groupCatalogs,
    initialGroupCatalogs,
    setInitialGroupCatalogs,
    activeCatalogs,
    categoryGroups,
    setCategoryGroups,
}: Props) {
    const [selectedTab, setSelectedTab] = useState(1);
    const [categoryProductGroupsCounts, setCategoryProductGroupsCounts] = useState({
        all: 0,
        selected: 0,
    });
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [availableGroupsCount, setAvailableGroupsCount] = useState<number>(0);
    const [isSelectedCatalogsUpdated, setIsSelectedCatalogsUpdated] = useState(false);
    const [checkedRowIds, setCheckedRowIds] = useState(null);
    let deletedIds = [];
    let changedCatalogGroupIds = [];
    const changedGroupCatalogs = [];

    useEffect(() => {
        if (availableGroupsCount) {
            setCategoryProductGroupsCounts({
                all: availableGroupsCount,
                selected: categoryGroups?.length,
            });
        }
    }, [availableGroupsCount, categoryGroups]);

    useEffect(() => {
        if (previousSelectedGroups) {
            const allGroupsList = [...selectedGroups];

            previousSelectedGroups?.forEach((previousGroup) => {
                const exists = allGroupsList?.some((group) => group?.id === previousGroup?.id);
                if (!exists) allGroupsList?.push(previousGroup);
            });

            setSelectedGroups(allGroupsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousSelectedGroups, setSelectedGroups]);

    useEffect(() => {
        if (selectedGroups) {
            const groups = [];
            selectedGroups?.map((selectedGroup) => {
                groups.push(selectedGroup);
            });
            setCategoryGroups(groups);
        }
    }, [selectedGroups]);

    const orderGroups = (orderedList) => {
        const tempGroupCatelogs = [...groupCatalogs];
        let sortedCatalogs = [];

        orderedList.forEach((group) => {
            sortedCatalogs = [...sortedCatalogs, tempGroupCatelogs.find((x) => x.id === group.id)];
        });

        setGroupCatalogs(sortedCatalogs);
        setCategoryGroups(orderedList);
        pushChangesToParentView(sortedCatalogs);
    };

    const removeDeletedGroups = (deletedId) => {
        if (setInitialGroupCatalogs) {
            setInitialGroupCatalogs([
                ...initialGroupCatalogs.filter((groupCatalog) => groupCatalog.id != deletedId),
            ]);
        }

        const groupCatalogsTemp = [
            ...groupCatalogs.filter((groupCatalog) => groupCatalog.id != deletedId),
        ];
        setGroupCatalogs(groupCatalogsTemp);

        if (!deletedIds.includes(deletedId)) {
            const updateDeletedGroupIds = [...deletedIds, deletedId];
            deletedIds = updateDeletedGroupIds;
        }

        const groupsList = [...selectedGroups];
        const filteredList = groupsList?.filter((group) => !deletedIds.includes(group?.id));

        setSelectedGroups(filteredList);
        setPreviousSelectedGroups(filteredList);
        pushChangesToParentView(groupCatalogsTemp);
    };

    const addCheckedGroups = (checkedList) => {
        const groupList = [...selectedGroups];
        let GroupCatalogsTemp = [];
        checkedList?.forEach((checkedGroup) => {
            const exists = groupList?.some((group) => group?.id === checkedGroup?.id);
            if (!exists) {
                groupList?.push(checkedGroup?.original);
                GroupCatalogsTemp = [
                    ...groupCatalogs.filter((groupCatalog) => groupCatalog.id != checkedGroup?.id),
                    { id: checkedGroup?.id, catalogs: selectedCatalogs },
                ];
                setGroupCatalogs(GroupCatalogsTemp);
            }
        });

        setSelectedGroups(groupList);
        pushChangesToParentView(GroupCatalogsTemp);
    };

    useEffect(() => {
        if (checkedRowIds) {
            const groupList = [...selectedGroups];
            let removedGroupId = null;
            const filteredList = groupList?.filter((group) => {
                const isGroupNotRemoved = checkedRowIds[group?.id] !== undefined;
                if (!isGroupNotRemoved) {
                    removedGroupId = group?.id;
                }
                return isGroupNotRemoved;
            });

            if (removedGroupId && setInitialGroupCatalogs) {
                setInitialGroupCatalogs([
                    ...initialGroupCatalogs.filter(
                        (initialGroupCatalog) => initialGroupCatalog.id != removedGroupId,
                    ),
                ]);
            }

            const groupCatalogsTemp = [
                ...groupCatalogs.filter((groupCatalog) => groupCatalog.id != removedGroupId),
            ];
            setGroupCatalogs(groupCatalogsTemp);
            setCategoryGroups(filteredList);
            setSelectedGroups(filteredList);
            pushChangesToParentView(groupCatalogsTemp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedRowIds, setSelectedGroups]);

    const handleCatalogsChange = (changedGroupCatalog) => {
        if (!changedCatalogGroupIds.includes(changedGroupCatalog?.id)) {
            const updatedGroupIds = [...changedCatalogGroupIds, changedGroupCatalog?.id];
            changedCatalogGroupIds = updatedGroupIds;
            changedGroupCatalogs?.push(changedGroupCatalog);
        }

        const groupCatalogList = [...groupCatalogs];
        groupCatalogList?.forEach((groupCatalog) => {
            const exists = changedCatalogGroupIds?.includes(groupCatalog?.id);
            if (exists) {
                const foundMappedCatalogs = changedGroupCatalogs?.find(
                    (changedGroup) => changedGroup?.id === groupCatalog?.id,
                );
                groupCatalog.catalogs = foundMappedCatalogs?.catalogs;
            } else {
                const modifiedList = groupCatalog.catalogs.map((catalog) => ({
                    label: catalog?.label || catalog?.catalogName,
                    value: catalog?.value || catalog?.catalogId,
                }));
                groupCatalog.catalogs = modifiedList;
            }
        });
        pushChangesToParentView(groupCatalogList);
    };

    const pushChangesToParentView = (groupCatalogsTemp?) => {
        const groupsCatalogsFinalList = [];
        if (groupCatalogsTemp) {
            groupCatalogsTemp?.forEach((groupCatalog) => {
                groupCatalog?.catalogs?.forEach((catalog) => {
                    groupsCatalogsFinalList?.push({
                        productGroupId: groupCatalog?.id,
                        catalogId: catalog?.value || catalog?.catalogId,
                    });
                });
            });
        } else {
            groupCatalogs?.forEach((groupCatalog) => {
                groupCatalog?.catalogs?.forEach((catalog) => {
                    groupsCatalogsFinalList?.push({
                        productGroupId: groupCatalog?.id,
                        catalogId: catalog?.value || catalog?.catalogId,
                    });
                });
            });
        }

        setSelectedGroupsCatalogs(groupsCatalogsFinalList);
    };

    useEffect(() => {
        if (!isSelectedCatalogsUpdated && groupCatalogs?.length == 0) {
            const groupCatalogList = [...groupCatalogs];
            if (selectedGroups) {
                selectedGroups?.forEach((selectedGroup) => {
                    let catalogs = [];

                    if (selectedCatalogs) {
                        const catalogList = [];

                        if (catalogs?.length > selectedCatalogs?.length) {
                            const filteredCatalogs = catalogs?.filter((catalog) =>
                                selectedCatalogs?.some(
                                    (selected) => catalog?.value === selected?.value,
                                ),
                            );
                            catalogList.push(filteredCatalogs);
                        } else {
                            selectedCatalogs?.forEach((catalog) => {
                                if (!catalogs?.includes(catalog)) {
                                    catalogList?.push(catalog);
                                }
                            });
                        }

                        catalogs = catalogList;
                    }

                    const previousCatalogs = previousSelectedGroupCatalogs?.find(
                        (catalog) => catalog?.productGroupId === selectedGroup?.id,
                    );

                    if (previousCatalogs) {
                        catalogs = previousCatalogs?.catalogs?.map((catalog) => {
                            return { value: catalog?.catalogId, label: catalog?.catalogName };
                        });
                    }

                    const exists = groupCatalogList?.some(
                        (groupCatalog) => groupCatalog?.productGroupId === selectedGroup?.id,
                    );
                    if (!exists) {
                        const catalogsObject = {
                            productGroupId: selectedGroup?.id,
                            catalogs: activeCatalogs
                                ? catalogs.filter((catalog) =>
                                      activeCatalogs.includes(catalog.value),
                                  )
                                : catalogs,
                        };
                        groupCatalogList?.push(catalogsObject);
                    } else {
                        groupCatalogList?.forEach((groupCatalog) => {
                            if (groupCatalog?.productGroupId === selectedGroup?.id) {
                                groupCatalog.catalogs = catalogs;
                            }
                        });
                    }
                });

                const filteredCatalogs = groupCatalogList?.filter((groupCatalog) =>
                    selectedGroups?.some(
                        (selectedGroup) => groupCatalog?.productGroupId === selectedGroup?.id,
                    ),
                );
                if (filteredCatalogs?.length > 0 && setInitialGroupCatalogs) {
                    setIsSelectedCatalogsUpdated(true);
                    setInitialGroupCatalogs(filteredCatalogs);
                }
                setGroupCatalogs(filteredCatalogs);
                pushChangesToParentView();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        previousSelectedGroupCatalogs,
        selectedCatalogs,
        selectedGroups,
        setGroupCatalogs,
        selectedTab,
    ]);

    useEffect(() => {
        if (selectedTab && setSelectedTabId) {
            setSelectedTabId(selectedTab);
        }
    }, [selectedTab, setSelectedTabId]);

    return (
        <>
            <div className="w-full">
                <div className={`w-full ml-4 py-4 `}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        categoryGroupsCounts={categoryProductGroupsCounts}
                    />
                </div>
                <div className="w-full">
                    {selectedTab === 1 ? (
                        <AvailableGroupsListView
                            selectedTab={selectedTab}
                            selectedGroups={selectedGroups}
                            addCheckedGroups={addCheckedGroups}
                            setAvaialbleGroupsCount={setAvailableGroupsCount}
                            setCheckedRowIds={setCheckedRowIds}
                        />
                    ) : (
                        <SelectedGroupsView
                            categoryGroups={categoryGroups}
                            removeDeletedGroups={removeDeletedGroups}
                            orderGroups={orderGroups}
                            handleCatalogsChange={handleCatalogsChange}
                            selectedCatalogs={selectedCatalogs}
                            groupsCatalogsList={groupCatalogs}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
