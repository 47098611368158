import Modal from 'react-responsive-modal';
import { Buttons } from '../../ui/atoms/Button';
import EditableCell from '../../ui/molecules/EditableCell';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { PaymentMethod } from '../../../enums/payment/';
import {
    FETCH_PARTNER_ITEM_CONVERSION_RATES,
    RESET_OVERRIDE_CONVERSION_RATE,
    SAVE_ITEM_PARTNER_CURRENCY_CONVERSIONS,
    SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE,
    RESET_OVERRIDE_CURRENCY_MARKED_UP_PERCENTAGE,
    RESET_OVERRIDE_POINTS_MARKED_UP_PERCENTAGE,
    RESET_OVERRIDE_PSP_MARKED_UP_PERCENTAGE,
    RESET_OVERRIDE_FINAL_PRICE,
} from '../../../queries/CurrencyConversionQueries';
import Loader from '../../../utils/loader';
import {
    getUpdatedOverridedConversions,
    preparePartnerCurrencyConversions,
    updatePartnerItemCurrencyConverions,
    updatePartnerItemFinalPrice,
} from '../../../helpers/CurrencyPriceHelper.helpers';
import NormalLoader from '../../../utils/normalLoader';
import {
    CURRENCY_CONVERSION_RESET_FAILED,
    CURRENCY_CONVERSION_RESET_SUCCESS,
    FAILED_RESPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE,
    GLOBAL_CURRENCY_NOT_SET_ITEM_PARTNER_LEVEL_ERROR,
    SUCCESS_REPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE,
    CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    CURRENCY_MARKED_UP_PERCENTAGE_RESET_FAILED,
    MARKEDUP_PERCENTAGE_FOR_CURRENCY_NOT_SET_ERROR,
    MARKEDUP_PERCENTAGE_FOR_POINTS_NOT_SET_ERROR,
    POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    POINTS_MARKED_UP_PERCENTAGE_RESET_FAILED,
    PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS,
    PSP_MARKED_UP_PERCENTAGE_RESET_FAILED,
    MARKEDUP_PERCENTAGE_FOR_PSP_NOT_SET_ERROR,
    SUCCESS_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE,
    FAILED_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE,
    FINAL_MARKED_UP_PRICE_TITLE,
    FINAL_MARKED_UP_PRICE_MESSAGE,
} from '../../../constants/currency';
import { useDispatch } from 'react-redux';
import {
    setCurrencyConversionOverrideSuccess,
    setCurrencyConversionOverrideSuccessMessage,
    setCurrencyConversionResetMessage,
    setCurrencyConversionResetSuccess,
    setCurrencyMarkedUpPercentageResetMessage,
    setCurrencyMarkedUpPercentageResetSuccess,
    setPointsMarkedUpPercentageResetMessage,
    setPointsMarkedUpPercentageResetSuccess,
    setPspMarkedUpPercentageResetMessage,
    setPspMarkedUpPercentageResetSuccess,
    setPspMarkedUpPercentageOverrideSuccessMessage,
    setPspMarkedUpPercentageOverrideSuccess,
} from '../../../redux/actions/storeActions';
import { currencyAndCountryCodes } from '../../../utils/currencies';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import ResetCurrencyRateConfirmationModal from '../../templates/modals/ResetCurrencyRateConfirmationModal';
import ResetMarkedUpPercentageForCurrencyConfirmationModal from '../../templates/modals/ResetMarkedUpPercentageForCurrencyConfirmationModal';
import ResetMarkedUpPercentageForPointsConfirmationModal from '../../templates/modals/ResetMarkedUpPercentageForPointsConfirmationModal';
import ResetMarkedUpPercentageForPspConfirmationModal from '../../templates/modals/ResetMarkedUpPercentageForPspConfirmationModal';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { ERROR } from '../../../constants/supplier';
import { Tab, Tabs } from '../../ui/molecules/Tabs';
import { roundToNearest } from '../../../helpers/valueRoundHelper.helpers';
import { SAVE_PARTNER_POINTS } from '../../../queries/PointsQueries';
import { FETCH_PRODUCTS_BY_ID } from '../../../queries/ItemQueries';
import { setItemPoints } from '../../../redux/rootActions';
import { FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE } from '../../../queries/PartnerQueries';
import {
    FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID,
    RESET_LOCAL_CURRENCY_RATE,
    RESET_MARKED_UP_PRICE,
} from '../../../queries/ProductQueries';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import {
    FINAL_REATIL_PRICE_MESSAGE,
    FINAL_REATIL_PRICE_TITLE,
    LOCAL_CURRENCY_RESET_MESSAGE,
    LOCAL_CURRENCY_RESET_TITLE,
    MARKED_UP_PRICE_MESSAGE,
    MARKED_UP_PRICE_TITLE,
} from '../../../constants/product';
import GlobalCurrencyUpdateModal from '../../templates/modals/GlobalCurrencyUpdateModal';
import AvailableChannels from './AvailableChannels';
import Toast from '../../ui/atoms/Toast';
import { useParams } from 'react-router-dom';

interface Props {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    partner?: any;
    costPrice: any;
    currency: string;
}

export default function ViewEditCurrencyPrices({
    openModal,
    setOpenModal,
    partner,
    costPrice,
    currency,
}: Props) {
    const dispatch = useDispatch();
    const [conversionRateChange, setConversionRateChange] = useState(false);
    const [currencyConversions, setCurrencyConversions] = useState([]);
    const [calculatedCurrencyConversions, setCalculatedCurrencyConversions] = useState([]);
    const [overrideConversions, setOverrideConversions] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [resetIndex, setResetIndex] = useState<number>(null);
    const [resetPaymentMethod, setResetPaymentMethod] = useState(null);
    const [warningModalBody, setWarningModalBody] = useState<string>(null);
    const [warningModalTitle, setWarningModalTitle] = useState<string>(null);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [
        showConfirmationModalForLocalCurrencyRateReset,
        setShowConfirmationModalForLocalCurrencyRateReset,
    ] = useState<boolean>(false);
    const [
        showConfirmationModalForMarkedUpPriceReset,
        setShowConfirmationModalForMarkedUpPriceReset,
    ] = useState<boolean>(false);

    // Whether the values in the tabs have unsaved changes
    const [isDirtyDCBTab, setIsDirtyDCBTab] = useState(false);
    const [isDirtyPointsTab, setIsDirtyPointsTab] = useState(false);
    const [isDirtyPSPTab, setIsDirtyPSPTab] = useState(false);

    // Partner level local currency conversion rate - fetched on modal load
    const [partnerLocalCurrencyConversionRate, setPartnerLocalCurrencyConversionRate] =
        useState(null);
    // Partner local currency - fetched on modal load
    const [partnerLocalCurrency, setPartnerLocalCurrency] = useState<string>(null);
    // Product level local currency conversion rate
    const [productLocalCurrencyConversionRate, setProductLocalCurrencyConversionRate] = useState(
        partner?.localCurrencyConversionRate,
    );
    // Value in item points
    const [calculatedItemPoints, setCalculatedItemPoints] = useState(null);
    // Price in local currency after applying the mark-up percentage
    const [localCurrencyMarkedUpPrice, setLocalCurrencyMarkedUpPrice] = useState(null);
    // Retail prince in points rounded up to the nearest 10th
    const [retailPriceInPoints, setRetailPriceInPoints] = useState(null);
    // Conversion rate from product's source currency to partner's local currency - read from currency conversions data
    const [sourceToLocalConversionRate, setSourceToLocalConversionRate] = useState<number>(null);

    const [itemLevelMarkedUpPercentageForCurrency, setItemLevelMarkedUpPercentageForCurrency] =
        useState(null);
    const [itemLevelMarkedUpPercentageForPoints, setItemLevelMarkedUpPercentageForPoints] =
        useState(null);
    const [itemLevelMarkedUpPercentageForPsp, setItemLevelMarkedUpPercentageForPsp] =
        useState(null);

    const [
        showConfirmationModalForFinalMarkedUpPriceReset,
        setShowConfirmationModalForFinalMarkedUpPriceReset,
    ] = useState<boolean>(false);
    const [
        showConfirmationModalForCurrencyMarkedUpPercentage,
        setShowConfirmationModalForCurrencyMarkedUpPercentage,
    ] = useState<boolean>(false);
    const [
        showConfirmationModalForPointsMarkedUpPercentage,
        setShowConfirmationModalForPointsMarkedUpPercentage,
    ] = useState<boolean>(false);
    const [
        showConfirmationModalForPspMarkedUpPercentage,
        setShowConfirmationModalForPspMarkedUpPercentage,
    ] = useState<boolean>(false);
    const [selectedGlobalCurrency, setSelectedGlobalCurrency] = useState(null);
    const [globalCurrencyUpdateModal, setGlobalCurrencyUpdateModal] = useState(null);
    const [setItNowButtonEnabled, setSetItNowButtonEnabled] = useState(true);
    const [isAvailableChannelsModalVisible, setIsAvailableChannelsModalVisible] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [toastError, setToastError] = useState(false);
    const parameters = useParams();
    const itemID = parameters['id'];
    const [overridenFinalRetailPrice, setOverridenFinalRetailPrice] = useState(null);

    const [
        showConfirmationModalForFinalRetailPriceReset,
        setShowConfirmationModalForFinalRetailPriceReset,
    ] = useState<boolean>(false);

    const [
        saveOverrideConversions,
        { loading: saveOverrideConversionsLoading, error: saveOverrideConversionError },
    ] = useMutation(SAVE_ITEM_PARTNER_CURRENCY_CONVERSIONS, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [
            FETCH_PARTNER_ITEM_CONVERSION_RATES,
            FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID,
        ],
        onCompleted: () => {
            dispatch(
                setCurrencyConversionOverrideSuccessMessage(
                    SUCCESS_REPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE + partner?.pid,
                ),
            );
            dispatch(setCurrencyConversionOverrideSuccess(true));
        },
        onError: () => {
            dispatch(
                setCurrencyConversionOverrideSuccessMessage(
                    FAILED_RESPONSE_SAVE_ITEM_PARTNER_CURRENCY_RATE + saveOverrideConversionError,
                ),
            );
            dispatch(setCurrencyConversionOverrideSuccess(false));
            handleModalClose();
        },
    });

    const [
        savePspMarkedUpPercentage,
        { loading: savePspMarkedUpPercentageLoading, error: savePspMarkedUpPercentageError },
    ] = useMutation(SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        onCompleted: () => {
            dispatch(
                setPspMarkedUpPercentageOverrideSuccessMessage(
                    SUCCESS_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE + partner?.pid,
                ),
            );
            dispatch(setPspMarkedUpPercentageOverrideSuccess(true));
        },
        onError: () => {
            dispatch(
                setPspMarkedUpPercentageOverrideSuccessMessage(
                    FAILED_REPONSE_SAVE_ITEM_PARTNER_PSP_MARKED_UP_PERCENTAGE +
                        savePspMarkedUpPercentageError,
                ),
            );
            dispatch(setPspMarkedUpPercentageOverrideSuccess(false));
            handleModalClose();
        },
    });

    const [resetOverride, { loading: resetOverrideLoading, error: resetOverrideError }] =
        useMutation(RESET_OVERRIDE_CONVERSION_RATE, {
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            refetchQueries: [FETCH_PARTNER_ITEM_CONVERSION_RATES],
            onCompleted: () => {
                dispatch(
                    setCurrencyConversionResetMessage(
                        CURRENCY_CONVERSION_RESET_SUCCESS + 'Item ID : ' + itemID,
                    ),
                );
                dispatch(setCurrencyConversionResetSuccess(true));
                handleModalClose();
            },
            onError: () => {
                dispatch(
                    setCurrencyConversionResetMessage(
                        CURRENCY_CONVERSION_RESET_FAILED + resetOverrideError,
                    ),
                );
                dispatch(setCurrencyConversionResetSuccess(false));
                handleModalClose();
            },
        });

    const [
        resetOverrideForCurrencyMarkedUpPercentage,
        {
            loading: resetOverrideForCurrencyMarkedUpPercentageLoading,
            error: resetOverrideForCurrencyMarkedUpPercentageError,
        },
    ] = useMutation(RESET_OVERRIDE_CURRENCY_MARKED_UP_PERCENTAGE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        onCompleted: () => {
            dispatch(
                setCurrencyMarkedUpPercentageResetMessage(
                    CURRENCY_MARKED_UP_PERCENTAGE_RESET_SUCCESS + 'Item ID : ' + itemID,
                ),
            );
            dispatch(setCurrencyMarkedUpPercentageResetSuccess(true));
            handleModalClose();
        },
        onError: () => {
            dispatch(
                setCurrencyMarkedUpPercentageResetMessage(
                    CURRENCY_MARKED_UP_PERCENTAGE_RESET_FAILED +
                        resetOverrideForCurrencyMarkedUpPercentageError,
                ),
            );
            dispatch(setCurrencyMarkedUpPercentageResetSuccess(false));
            handleModalClose();
        },
    });

    const [
        resetOverrideForPointsMarkedUpPercentage,
        {
            loading: resetOverrideForPointsMarkedUpPercentageLoading,
            error: resetOverrideForPointsMarkedUpPercentageError,
        },
    ] = useMutation(RESET_OVERRIDE_POINTS_MARKED_UP_PERCENTAGE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        onCompleted: () => {
            dispatch(
                setPointsMarkedUpPercentageResetMessage(
                    POINTS_MARKED_UP_PERCENTAGE_RESET_SUCCESS + 'Item ID : ' + itemID,
                ),
            );
            dispatch(setPointsMarkedUpPercentageResetSuccess(true));
            handleModalClose();
        },
        onError: () => {
            dispatch(
                setPointsMarkedUpPercentageResetMessage(
                    POINTS_MARKED_UP_PERCENTAGE_RESET_FAILED +
                        resetOverrideForPointsMarkedUpPercentageError,
                ),
            );
            dispatch(setPointsMarkedUpPercentageResetSuccess(false));
            handleModalClose();
        },
    });

    const [
        resetOverrideForPspMarkedUpPercentage,
        {
            loading: resetOverrideForPspMarkedUpPercentageLoading,
            error: resetOverrideForPspMarkedUpPercentageError,
        },
    ] = useMutation(RESET_OVERRIDE_PSP_MARKED_UP_PERCENTAGE, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        refetchQueries: [FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        onCompleted: () => {
            dispatch(
                setPspMarkedUpPercentageResetMessage(
                    PSP_MARKED_UP_PERCENTAGE_RESET_SUCCESS + 'Item ID : ' + itemID,
                ),
            );
            dispatch(setPspMarkedUpPercentageResetSuccess(true));
            handleModalClose();
        },
        onError: () => {
            dispatch(
                setPspMarkedUpPercentageResetMessage(
                    PSP_MARKED_UP_PERCENTAGE_RESET_FAILED +
                        resetOverrideForPspMarkedUpPercentageError,
                ),
            );
            dispatch(setPspMarkedUpPercentageResetSuccess(false));
            handleModalClose();
        },
    });

    const [
        loadCurrencyConversions,
        { data: partnerCurrencyConversions, loading: partnerCurrencyConversionsLoading },
    ] = useLazyQuery(FETCH_PARTNER_ITEM_CONVERSION_RATES, {
        variables: { partnerId: partner?.pid, itemId: itemID },
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            setCurrencyConversions(partnerCurrencyConversions?.currencyConversions);
        },
    });

    const [saveProductPartnerPoints] = useMutation(SAVE_PARTNER_POINTS, {
        refetchQueries: [FETCH_PRODUCTS_BY_ID, FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            dispatch(
                setItemPoints({
                    saveItemPoints: {
                        ...data.saveItemPoints,
                        pointsValue: data?.saveItemPoints?.pointsValue,
                        markedUpPrice: data?.saveItemPoints?.markedUpPrice,
                    },
                }),
            );
            setIsDirtyPointsTab(false);
        },
        onError() {
            console.error('Failed to save partner points');
            handleModalClose();
        },
    });

    const [resetLocalCurrencyConversion] = useMutation(RESET_LOCAL_CURRENCY_RATE, {
        refetchQueries: [FETCH_PRODUCTS_BY_ID, FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setIsDirtyPointsTab(false);
            handleModalClose();
            setShowConfirmationModalForLocalCurrencyRateReset(false);
        },
        onError() {
            console.error('Failed to reset local currency rate');
            handleModalClose();
        },
    });

    const [resetMarkedUpPrice] = useMutation(RESET_MARKED_UP_PRICE, {
        refetchQueries: [FETCH_PRODUCTS_BY_ID, FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setIsDirtyPointsTab(false);
            handleModalClose();
            setShowConfirmationModalForMarkedUpPriceReset(false);
        },
        onError() {
            console.error('Failed to reset marked up price');
            handleModalClose();
        },
    });

    const [resetOverriddenFinalMarkedUpPrice] = useMutation(RESET_OVERRIDE_FINAL_PRICE, {
        refetchQueries: [FETCH_PRODUCTS_BY_ID, FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            handleModalClose();
            setShowConfirmationModalForMarkedUpPriceReset(false);
        },
        onError() {
            console.error('Failed to reset marked up price');
            handleModalClose();
        },
    });

    const [
        fetchPartnerLocalCurrencyConversionRate,
        { loading: localCurrencyConversionRateLoading },
    ] = useLazyQuery(FETCH_PARTNER_LOCAL_CURRENCY_CONVERSION_RATE, {
        variables: { partnerId: partner?.id },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        context: { clientName: ClientsEnum.STORE },
        onCompleted(data) {
            if (
                data.partnerLocalCurrencyConversionRate &&
                data.partnerLocalCurrencyConversionRate.length > 0
            ) {
                setPartnerLocalCurrencyConversionRate(
                    data.partnerLocalCurrencyConversionRate[0].conversionRate,
                );
                setPartnerLocalCurrency(data.partnerLocalCurrencyConversionRate[0].localCurrency);
            }
        },
        onError() {
            console.error('Failed to fetch local currency conversion rate');
        },
    });

    const [resetOveriddenFinalPricePoints] = useMutation(RESET_OVERRIDE_FINAL_PRICE, {
        refetchQueries: [FETCH_PRODUCTS_BY_ID, FETCH_PARTNER_CONVERSIONS_DETAILS_BY_ITEM_ID],
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setIsDirtyPointsTab(false);
            handleModalClose();
            setShowConfirmationModalForFinalRetailPriceReset(false);
        },
        onError() {
            console.error('Failed to reset marked up price');
            handleModalClose();
        },
    });

    const validateGlobalCurrency = useCallback(
        (index): boolean => {
            if (currencyConversions?.length > 0) {
                const existingPreference = currencyConversions[index];

                if (
                    !existingPreference?.conversionRate ||
                    existingPreference?.conversionRate === undefined ||
                    existingPreference?.conversionRate === null
                ) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        },
        [currencyConversions],
    );

    const updateConversionRate = useCallback(
        (index, value, tab) => {
            if (validateGlobalCurrency(index)) {
                setConversionRateChange(true);

                const conversions = [...calculatedCurrencyConversions];

                // display updated values in table
                const updatedConversions = updatePartnerItemCurrencyConverions(
                    conversions,
                    index,
                    value,
                );

                const result = preparePartnerCurrencyConversions(
                    costPrice,
                    updatedConversions,
                    partner?.markedUpPercentageForCurrency,
                    partner?.markedUpPercentage,
                    partner?.markedUpPercentageForPsp,
                );
                setCalculatedCurrencyConversions(result);

                // record override values for api call
                const overrideRecord = calculatedCurrencyConversions[index];

                const updatedOverrideConversions = getUpdatedOverridedConversions(
                    overrideConversions,
                    overrideRecord,
                );

                setOverrideConversions(updatedOverrideConversions);
                if (tab === 'DCB') {
                    setIsDirtyDCBTab(true);
                } else if (tab === 'PSP') {
                    setIsDirtyPSPTab(true);
                }
            } else {
                setWarningModalBody(GLOBAL_CURRENCY_NOT_SET_ITEM_PARTNER_LEVEL_ERROR);
                setWarningModalTitle(ERROR);
                setShowWarningModal(true);
                setSetItNowButtonEnabled(true);
            }
        },
        [
            calculatedCurrencyConversions,
            costPrice,
            overrideConversions,
            partner?.markedUpPercentageForCurrency,
            partner?.markedUpPercentage,
            partner?.markedUpPercentageForPsp,
            validateGlobalCurrency,
        ],
    );

    const updateFinalPrice = useCallback(
        (index, value, data, tab) => {
            if (validateGlobalCurrency(index)) {
                setConversionRateChange(true);

                const conversions = [...calculatedCurrencyConversions];

                let finalPriceOverriddenDetails = null;

                if (data?.paymentMethod === PaymentMethod.DCB) {
                    finalPriceOverriddenDetails = {
                        ...data,
                        finalPriceOverriddenDCB: value,
                        markedUpPercentageDCB:
                            itemLevelMarkedUpPercentageForCurrency ||
                            partner?.markedUpPercentageForCurrency,
                    };
                } else if (data?.paymentMethod === PaymentMethod.PSP) {
                    finalPriceOverriddenDetails = {
                        ...data,
                        finalPriceOverriddenPSP: value,
                        markedUpPercentagePSP:
                            itemLevelMarkedUpPercentageForPsp || partner?.markedUpPercentageForPsp,
                    };
                }

                // display updated values in table
                const updatedConversions = updatePartnerItemFinalPrice(
                    conversions,
                    index,
                    finalPriceOverriddenDetails,
                );

                const result = preparePartnerCurrencyConversions(
                    costPrice,
                    updatedConversions,
                    partner?.markedUpPercentageForCurrency,
                    partner?.markedUpPercentage,
                    partner?.markedUpPercentageForPsp,
                );
                setCalculatedCurrencyConversions(result);

                // record override values for api call
                const overrideRecord = calculatedCurrencyConversions[index];

                const updatedOverrideConversions = getUpdatedOverridedConversions(
                    overrideConversions,
                    overrideRecord,
                );

                setOverrideConversions(updatedOverrideConversions);
                if (tab === 'DCB') {
                    setIsDirtyDCBTab(true);
                } else if (tab === 'PSP') {
                    setIsDirtyPSPTab(true);
                }
            } else {
                setWarningModalBody(GLOBAL_CURRENCY_NOT_SET_ITEM_PARTNER_LEVEL_ERROR);
                setWarningModalTitle(ERROR);
                setShowWarningModal(true);
                setSetItNowButtonEnabled(true);
            }
        },
        [
            calculatedCurrencyConversions,
            costPrice,
            overrideConversions,
            partner?.markedUpPercentageForCurrency,
            partner?.markedUpPercentage,
            partner?.markedUpPercentageForPsp,
            validateGlobalCurrency,
            itemLevelMarkedUpPercentageForCurrency,
            itemLevelMarkedUpPercentageForPsp,
        ],
    );

    const handleWarningOkayClick = () => {
        loadCurrencyConversions();
    };

    const resetCurrencyConversion = useCallback(
        (index) => {
            setShowConfirmationModal(true);
            setResetIndex(index);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [calculatedCurrencyConversions],
    );

    const resetFinalMarkedUpPrice = useCallback(
        (index) => {
            setShowConfirmationModalForFinalMarkedUpPriceReset(true);
            setResetIndex(index);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [calculatedCurrencyConversions],
    );

    const resetMarkedUpPercentageForCurrency = useCallback((index) => {
        setShowConfirmationModalForCurrencyMarkedUpPercentage(true);
        setResetIndex(index);
    }, []);

    const resetMarkedUpPercentageForPoints = useCallback((index) => {
        setShowConfirmationModalForPointsMarkedUpPercentage(true);
        setResetIndex(index);
    }, []);

    const resetMarkedUpPercentageForPsp = useCallback((index) => {
        setShowConfirmationModalForPspMarkedUpPercentage(true);
        setResetIndex(index);
    }, []);

    const handleResetSubmit = () => {
        const overridedRecord = calculatedCurrencyConversions[resetIndex];

        resetOverride({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
                conversion: {
                    sourceCurrency: overridedRecord?.sourceCurrency,
                    targetCurrency: overridedRecord?.targetCurrency,
                    resetLevelItem: true,
                },
            },
        });
        setShowConfirmationModal(false);
    };

    const handleResetSubmitForCurrencyMarkedUpPercentage = () => {
        resetOverrideForCurrencyMarkedUpPercentage({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
            },
        });
        setShowConfirmationModalForCurrencyMarkedUpPercentage(false);
    };

    const handleResetSubmitForPointsMarkedUpPercentage = () => {
        resetOverrideForPointsMarkedUpPercentage({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
            },
        });
        setShowConfirmationModalForPointsMarkedUpPercentage(false);
    };

    const handleResetSubmitForPspMarkedUpPercentage = () => {
        resetOverrideForPspMarkedUpPercentage({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
            },
        });
        setShowConfirmationModalForPspMarkedUpPercentage(false);
    };

    const handleResetSubmitForLocalCurrencyConversion = () => {
        resetLocalCurrencyConversion({
            variables: {
                partnerId: partner?.id,
                itemId: itemID,
            },
        });
        setShowConfirmationModalForLocalCurrencyRateReset(false);
    };

    const handleResetSubmitMarkedUpPriceReset = () => {
        resetMarkedUpPrice({
            variables: {
                partnerId: partner?.id,
                itemId: itemID,
            },
        });
        setShowConfirmationModalForMarkedUpPriceReset(false);
    };

    const handleResetSubmitFinalMarkedUpPrice = () => {
        const overridedRecord = calculatedCurrencyConversions[resetIndex];

        resetOverriddenFinalMarkedUpPrice({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
                sourceCurrency: overridedRecord?.sourceCurrency,
                targetCurrency: overridedRecord?.targetCurrency,
                paymentMethod: resetPaymentMethod?.toString(),
            },
        });

        setShowConfirmationModalForFinalMarkedUpPriceReset(false);
    };

    const handleResetSubmiFinalRetailPriceReset = () => {
        resetOveriddenFinalPricePoints({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
                sourceCurrency: currency,
                targetCurrency: partner?.localCurrency,
                paymentMethod: PaymentMethod.POINTS,
            },
        });
        setShowConfirmationModalForFinalRetailPriceReset(false);
    };

    const handleLocalCurrencyConversionRateChange = (_id: number, value: number) => {
        setIsDirtyPointsTab(true);
        setProductLocalCurrencyConversionRate(value);
    };

    const handleMarkedUpPriceChange = (_id: number, value: number) => {
        setIsDirtyPointsTab(true);
        setLocalCurrencyMarkedUpPrice(value);
    };

    const handleFinalRetailPriceChange = (_id: number, value: number) => {
        setIsDirtyPointsTab(true);
        setOverridenFinalRetailPrice(value);
    };

    const handleMarkedUpPercentageForCurrencyChange = (_id: number, value: number) => {
        if (partner?.markedUpPercentageForCurrency) {
            setIsDirtyDCBTab(true);
            setItemLevelMarkedUpPercentageForCurrency(value);
        } else {
            setWarningModalBody(MARKEDUP_PERCENTAGE_FOR_CURRENCY_NOT_SET_ERROR);
            setWarningModalTitle(ERROR);
            setShowWarningModal(true);
            setSetItNowButtonEnabled(false);
        }
    };

    const handleMarkedUpPercentageForPointsChange = (_id: number, value: number) => {
        if (partner?.markedUpPercentage) {
            setIsDirtyPointsTab(true);
            setItemLevelMarkedUpPercentageForPoints(value);
        } else {
            setWarningModalBody(MARKEDUP_PERCENTAGE_FOR_POINTS_NOT_SET_ERROR);
            setWarningModalTitle(ERROR);
            setShowWarningModal(true);
            setSetItNowButtonEnabled(false);
        }
    };

    const handleMarkedUpPercentageForPspChange = (_id: number, value: number) => {
        if (partner?.markedUpPercentageForPsp) {
            setItemLevelMarkedUpPercentageForPsp(value);
            setIsDirtyPSPTab(true);
        } else {
            setWarningModalBody(MARKEDUP_PERCENTAGE_FOR_PSP_NOT_SET_ERROR);
            setWarningModalTitle(ERROR);
            setShowWarningModal(true);
            setSetItNowButtonEnabled(false);
        }
    };

    const currencyTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function editConversionRate({ row, value }) {
                    return (
                        <div className="flex gap-3">
                            <EditableCell
                                id={`${row?.original?.sourceCurrency}-${row?.original?.targetCurrency}-conversion-rate`}
                                initialValue={value}
                                row={row}
                                updateValue={(index, value) =>
                                    updateConversionRate(index, value, 'DCB')
                                }
                                isOverride={
                                    row?.original?.overrideItem || row?.original?.overridePartner
                                }
                                key={row?.original?.id}
                                isDisabled={
                                    row?.original?.sourceCurrency == row?.original?.targetCurrency
                                }
                                setSelectedGlobalCurrency={setSelectedGlobalCurrency}
                            />
                            {row?.original?.overrideItem && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="currency_conversion_reset"
                                        onClick={() => resetCurrencyConversion(row?.index)}
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
            {
                Header: 'Final Price',
                accessor: 'targetPrice',
            },
            {
                Header: 'Marked-up Price',
                accessor: 'markedUpPriceForCurrency',
            },
            {
                Header: 'Final Marked-up Price',
                accessor: '',
                Cell: function editFinalPrice({ row }) {
                    const currencyConversion = row?.original;

                    const finalPriceOverriddenDetails = {
                        paymentMethod: PaymentMethod.DCB,
                        markedUpPercentageDCB: partner?.markedUpPercentageForCurrency,
                        conversionRate: currencyConversion?.conversionRate,
                        unitPrice: costPrice?.toFixed(2),
                        markedUpPriceDCB: currencyConversion?.markedUpPriceForCurrency,
                    };

                    return (
                        <div key={currencyConversion?.id} className="flex gap-3">
                            <EditableCell
                                id={`${currencyConversion?.sourceCurrency}-${currencyConversion?.targetCurrency}-final-price`}
                                initialValue={
                                    currencyConversion?.finalPriceDCBOverridden
                                        ? currencyConversion?.finalPriceDCBOverridden
                                        : currencyConversion?.markedUpPriceForCurrency
                                }
                                row={row}
                                updateValue={(index, value, data) =>
                                    updateFinalPrice(index, value, data, 'DCB')
                                }
                                isOverride={currencyConversion?.overrideFinalPriceDCB}
                                setSelectedGlobalCurrency={setSelectedGlobalCurrency}
                                data={finalPriceOverriddenDetails}
                            />
                            {currencyConversion?.overrideFinalPriceDCB && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="final_price_reset"
                                        onClick={() => {
                                            resetFinalMarkedUpPrice(row?.index);
                                            setResetPaymentMethod(PaymentMethod.DCB);
                                        }}
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
        [
            updateConversionRate,
            resetCurrencyConversion,
            partner?.markedUpPercentageForCurrency,
            costPrice,
            updateFinalPrice,
            resetFinalMarkedUpPrice,
        ],
    );

    /**
     * Called if the Points tab is dirty and the save button is clicked
     */
    const updatePointsTabData = () => {
        let finalRetailPriceInPoints = retailPriceInPoints;

        if (partner?.markedUpPriceOverridden == false) {
            const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
            const markedUpPercentage =
                itemLevelMarkedUpPercentageForPoints || partner?.markedUpPercentage;

            const markedUpPriceInLocalCurrency = parseFloat(
                ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
            );
            const finalPriceInLocalCurrency = localCurrencyPrice + markedUpPriceInLocalCurrency;

            const itemPoints =
                (localCurrencyMarkedUpPrice || finalPriceInLocalCurrency) *
                (productLocalCurrencyConversionRate || partnerLocalCurrencyConversionRate);

            const value =
                itemPoints > 10
                    ? roundToNearest(itemPoints, 10)?.toFixed(2)
                    : Math.ceil(itemPoints) || 0;
            finalRetailPriceInPoints = value;
        }

        let finalRetailPriceOverriddenData = null;

        if (overridenFinalRetailPrice != null) {
            const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
            const markedUpPercentage = partner?.markedUpPercentage;
            const markedUpPriceInLocalCurrency = parseFloat(
                ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
            );

            const finalPriceInLocalCurrency =
                partner?.markupPrice || localCurrencyPrice + markedUpPriceInLocalCurrency;

            finalRetailPriceOverriddenData = {
                finalPriceOverridden: overridenFinalRetailPrice,
                unitPrice: costPrice?.toFixed(2),
                markedUpPercentage:
                    itemLevelMarkedUpPercentageForPoints || partner?.markedUpPercentage,
                conversionRate: sourceToLocalConversionRate,
                localPointsRate:
                    productLocalCurrencyConversionRate || partnerLocalCurrencyConversionRate,
                markedUpPrice:
                    localCurrencyMarkedUpPrice || partner?.markupPrice || finalPriceInLocalCurrency,
                itemPoints:
                    (localCurrencyMarkedUpPrice || finalPriceInLocalCurrency) *
                    (productLocalCurrencyConversionRate || partnerLocalCurrencyConversionRate),
                roundedItemPoints:
                    calculatedItemPoints > 10
                        ? roundToNearest(calculatedItemPoints, 10)?.toFixed(2)
                        : Math.ceil(calculatedItemPoints) || 0,
            };
        }

        saveProductPartnerPoints({
            variables: {
                saveItemPoints: {
                    partner: {
                        id: partner?.id,
                    },
                    item: {
                        id: partner?.itemId,
                    },
                    pointsValue: finalRetailPriceInPoints,
                    markedUpPercentage: itemLevelMarkedUpPercentageForPoints,
                    markedUpPrice: localCurrencyMarkedUpPrice,
                    localCurrencyConversionRate: productLocalCurrencyConversionRate,
                    finalPriceOverriddenDetails: finalRetailPriceOverriddenData,
                },
            },
        });
    };

    const updatePspTabData = () => {
        savePspMarkedUpPercentage({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
                markedUpPercentageForPsp: itemLevelMarkedUpPercentageForPsp,
            },
        });
    };

    const pointsTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: '',
                Cell: function sourceCurrencyCell() {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === currency)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{currency}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                acccessor: '',
                Cell: function editPointsConversionRateCell() {
                    return (
                        <input
                            type="number"
                            value={sourceToLocalConversionRate}
                            size={5}
                            className="flex mx-auto p-2 border-2 focus:border-purple-400 outline-none rounded-lg"
                            disabled
                        />
                    );
                },
            },
            {
                Header: 'Target/Local Currency',
                accessor: 'localCurrency',
                Cell: function localCurrencyCell({ value }) {
                    const localCurrency = partnerLocalCurrency || value;
                    if (!localCurrency) return <div></div>;

                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === localCurrency)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{localCurrency}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Price in Local Currency',
                accessor: '',
                Cell: function priceInLocalCurrencyCell() {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    return <div className="text-center">{localCurrencyPrice}</div>;
                },
            },
            {
                Header: 'Marked-up Price',
                accessor: '',
                Cell: function markedUpPriceInLocalCurrencyCell({ row }) {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    const markedUpPercentage = partner?.markedUpPercentage;
                    const markedUpPriceInLocalCurrency = parseFloat(
                        ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
                    );
                    const finalPriceInLocalCurrency =
                        localCurrencyPrice + markedUpPriceInLocalCurrency;

                    // For the marked up price value, depending on the data availability,
                    // we use either the edited value in the state,
                    // or the price fetched from the backend,
                    // or the price we calculate manually.
                    return (
                        <div className="flex gap-3">
                            <EditableCell
                                id={`points-marked-up-price`}
                                initialValue={
                                    localCurrencyMarkedUpPrice ||
                                    partner?.markupPrice ||
                                    finalPriceInLocalCurrency
                                }
                                row={row}
                                updateValue={handleMarkedUpPriceChange}
                                key={row?.original?.id}
                                isOverride={row?.original?.markedUpPriceOverridden}
                            />
                            {row?.original?.markedUpPriceOverridden && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="marked_up_price_reset"
                                        onClick={() =>
                                            setShowConfirmationModalForMarkedUpPriceReset(true)
                                        }
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'localCurrencyConversionRate',
                Cell: function editConversionRateCell({ row, value }) {
                    if (localCurrencyConversionRateLoading) {
                        return <div>Loading...</div>;
                    }

                    // For the local currency conversion rate,
                    // we use either the form data stored in the state,
                    // or the value fetched from the item_partner_points table.
                    // If both of these are not present, we fallback to the
                    // partner-level local currency conversion rate.
                    return (
                        <div className="flex gap-3">
                            {row?.original?.overrideItem}
                            <EditableCell
                                id={`points-conversion-rate`}
                                initialValue={
                                    productLocalCurrencyConversionRate ||
                                    value ||
                                    partnerLocalCurrencyConversionRate
                                }
                                row={row}
                                updateValue={handleLocalCurrencyConversionRateChange}
                                isOverride={row.original.localCurrencyConversionRateOverridden}
                                key={row?.original?.id}
                            />
                            {row?.original?.localCurrencyConversionRateOverridden && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="currency_conversion_reset"
                                        onClick={() =>
                                            setShowConfirmationModalForLocalCurrencyRateReset(true)
                                        }
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: ' ',
                accessor: '',
                Cell: function localCurrencyEquationCell({ row }) {
                    const value = row.values.localCurrencyConversionRate;
                    const localCurrency = partnerLocalCurrency || row?.original?.localCurrency;

                    return (
                        <div className="mx-5">
                            {localCurrency} 1 ={' '}
                            {productLocalCurrencyConversionRate ||
                                value ||
                                partnerLocalCurrencyConversionRate}{' '}
                            PTS
                        </div>
                    );
                },
            },
            {
                Header: 'Item Points',
                accessor: 'itemPoints',
                Cell: function itemPointsCell() {
                    const localCurrencyPrice = costPrice * sourceToLocalConversionRate;
                    const markedUpPercentage = partner?.markedUpPercentage;
                    const markedUpPriceInLocalCurrency = parseFloat(
                        ((localCurrencyPrice * markedUpPercentage) / 100).toFixed(2),
                    );
                    const finalPriceInLocalCurrency =
                        partner?.markupPrice || localCurrencyPrice + markedUpPriceInLocalCurrency;

                    const itemPoints =
                        (localCurrencyMarkedUpPrice || finalPriceInLocalCurrency) *
                        (productLocalCurrencyConversionRate || partnerLocalCurrencyConversionRate);
                    setCalculatedItemPoints(itemPoints);
                    return <div className="text-center">{itemPoints.toFixed(2)}</div>;
                },
            },
            {
                Header: 'Retail Price in Points (Rounded)',
                accessor: '',
                Cell: function retailPriceInPointsCell() {
                    const value =
                        calculatedItemPoints > 10
                            ? roundToNearest(calculatedItemPoints, 10)?.toFixed(2)
                            : Math.ceil(calculatedItemPoints) || 0;
                    setRetailPriceInPoints(value);
                    return <div className="text-center">{value}</div>;
                },
            },
            {
                Header: 'Final Retail Price',
                accessor: '',
                Cell: function finalRetailPriceCell({ row }) {
                    const retailPriceCalculated =
                        calculatedItemPoints > 10
                            ? roundToNearest(calculatedItemPoints, 10)?.toFixed(2)
                            : Math.ceil(calculatedItemPoints) || 0;

                    const finalRetailPrice =
                        row?.original?.finalRetailPrice || retailPriceCalculated;

                    return (
                        <div className="flex gap-3">
                            <EditableCell
                                id={`final-retail-price`}
                                initialValue={overridenFinalRetailPrice || finalRetailPrice}
                                row={row}
                                updateValue={handleFinalRetailPriceChange}
                                key={row?.original?.id}
                                isOverride={row?.original?.finalRetailPriceOverridden}
                            />
                            {row?.original?.finalRetailPriceOverridden && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="final_retail_price_reset"
                                        onClick={() =>
                                            setShowConfirmationModalForFinalRetailPriceReset(true)
                                        }
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
        [
            currency,
            sourceToLocalConversionRate,
            partnerLocalCurrency,
            costPrice,
            partner?.markedUpPercentage,
            partner?.markupPrice,
            localCurrencyMarkedUpPrice,
            localCurrencyConversionRateLoading,
            productLocalCurrencyConversionRate,
            partnerLocalCurrencyConversionRate,
            calculatedItemPoints,
            overridenFinalRetailPrice,
        ],
    );

    const pspTableColumns = useMemo(
        () => [
            {
                Header: 'Source Currency',
                accessor: 'sourceCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Target Currency',
                accessor: 'targetCurrency',
                Cell: function formatCellWithCurrency({ value }) {
                    return (
                        <div className="flex">
                            <i
                                className={`${currencyAndCountryCodes
                                    .find((x) => x.currency === value)
                                    ?.country.toLowerCase()} flag px-5 pt-1`}
                            ></i>
                            <div>{value}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'Conversion Rate',
                accessor: 'conversionRate',
                Cell: function editConversionRate({ row, value }) {
                    return (
                        <div className="flex gap-3">
                            <EditableCell
                                id={`${row?.original?.sourceCurrency}-${row?.original?.targetCurrency}-psp-conversion-rate`}
                                initialValue={value}
                                row={row}
                                updateValue={(index, value) =>
                                    updateConversionRate(index, value, 'PSP')
                                }
                                isOverride={
                                    row?.original?.overrideItem || row?.original?.overridePartner
                                }
                                key={row?.original?.id}
                                isDisabled={
                                    row?.original?.sourceCurrency == row?.original?.targetCurrency
                                }
                                setSelectedGlobalCurrency={setSelectedGlobalCurrency}
                            />
                            {row?.original?.overrideItem && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="currency_conversion_reset"
                                        onClick={() => resetCurrencyConversion(row?.index)}
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                id: 'summary',
                accessor: '',
                Cell: function displayConversionRateSummary({ row }) {
                    return row?.original?.conversionRate
                        ? `1 ${row?.original?.sourceCurrency} = ${row?.original?.conversionRate} ${row?.original?.targetCurrency}`
                        : `Currency conversion rate between ${row?.original?.sourceCurrency} and ${row?.original?.targetCurrency} is not set`;
                },
            },
            {
                Header: 'Final Price',
                accessor: 'targetPrice',
            },
            {
                Header: 'Marked-up Price',
                accessor: 'markedUpPriceForPsp',
            },
            {
                Header: 'Final Marked-up Price',
                accessor: '',
                Cell: function editFinalPrice({ row }) {
                    const currencyConversion = row?.original;

                    const finalPriceOverriddenDetails = {
                        paymentMethod: PaymentMethod.PSP,
                        markedUpPercentagePSP: partner?.markedUpPercentageForPsp,
                        conversionRate: currencyConversion?.conversionRate,
                        unitPrice: costPrice?.toFixed(2),
                        markedUpPricePSP: currencyConversion?.markedUpPriceForPsp,
                    };

                    return (
                        <div key={currencyConversion?.id} className="flex gap-3">
                            <EditableCell
                                id={`${currencyConversion?.sourceCurrency}-${currencyConversion?.targetCurrency}-final-price`}
                                initialValue={
                                    currencyConversion?.finalPricePSPOverridden
                                        ? currencyConversion?.finalPricePSPOverridden
                                        : currencyConversion?.markedUpPriceForPsp
                                }
                                row={row}
                                updateValue={(index, value, data) =>
                                    updateFinalPrice(index, value, data, 'PSP')
                                }
                                isOverride={currencyConversion?.overrideFinalPricePSP}
                                setSelectedGlobalCurrency={setSelectedGlobalCurrency}
                                data={finalPriceOverriddenDetails}
                            />
                            {currencyConversion?.overrideFinalPricePSP && (
                                <div className="flex items-center">
                                    <button
                                        value="menu cursor-pointer_psp"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="final_price_reset"
                                        onClick={() => {
                                            resetFinalMarkedUpPrice(row?.index);
                                            setResetPaymentMethod(PaymentMethod.PSP);
                                        }}
                                    >
                                        <BsArrowCounterclockwise className="mx-auto" />
                                    </button>
                                </div>
                            )}
                        </div>
                    );
                },
            },
        ],
        [
            updateConversionRate,
            resetCurrencyConversion,
            partner?.markedUpPercentageForPsp,
            costPrice,
            updateFinalPrice,
            resetFinalMarkedUpPrice,
        ],
    );

    const handlePrepareConversionRates = useCallback(() => {
        if (currencyConversions && costPrice) {
            const results = preparePartnerCurrencyConversions(
                costPrice,
                currencyConversions,
                partner?.markedUpPercentageForCurrency,
                partner?.markedUpPercentage,
                partner?.markedUpPercentageForPsp,
            );
            setCalculatedCurrencyConversions(results);
        }
    }, [costPrice, currencyConversions, partner]);

    const handleModalSave = () => {
        if (conversionRateChange || itemLevelMarkedUpPercentageForCurrency) {
            handleSaveOverrideCurrencyConversions();
        }
        if (isDirtyPointsTab) {
            updatePointsTabData();
        }
        if (itemLevelMarkedUpPercentageForPsp) {
            updatePspTabData();
        }
        handleModalClose();
    };

    const handleModalClose = () => {
        setIsDirtyDCBTab(false);
        setIsDirtyPointsTab(false);
        setIsDirtyPSPTab(false);

        setLocalCurrencyMarkedUpPrice(null);
        setProductLocalCurrencyConversionRate(partner?.localCurrencyConversionRate);
        setPartnerLocalCurrency(null);
        setPartnerLocalCurrencyConversionRate(null);

        setItemLevelMarkedUpPercentageForCurrency(null);
        setItemLevelMarkedUpPercentageForPoints(null);
        setItemLevelMarkedUpPercentageForPsp(null);

        setOpenModal(false);
    };

    const handleSaveOverrideCurrencyConversions = () => {
        saveOverrideConversions({
            variables: {
                partnerId: partner?.pid,
                itemId: itemID,
                conversions: overrideConversions,
                markedUpPercentageForCurrency: itemLevelMarkedUpPercentageForCurrency,
                markedUpPercentageForPSP: itemLevelMarkedUpPercentageForPsp,
            },
        });
    };

    useEffect(() => {
        loadCurrencyConversions();
    }, [loadCurrencyConversions]);

    useEffect(() => {
        if (currencyConversions) {
            handlePrepareConversionRates();
        }
    }, [currencyConversions, handlePrepareConversionRates]);

    useEffect(() => {
        if (partner) {
            setProductLocalCurrencyConversionRate(partner?.localCurrencyConversionRate);
        }
    }, [partner]);

    useEffect(() => {
        if (partner) {
            fetchPartnerLocalCurrencyConversionRate();
        }
    }, [fetchPartnerLocalCurrencyConversionRate, partner]);

    // Find and set the conversion rate between source and local currency
    useEffect(() => {
        if (currencyConversions.length > 0 && partnerLocalCurrency !== null) {
            const sourceToLocalConversion = currencyConversions.find((con) => {
                if (
                    con.sourceCurrency === currency &&
                    con.targetCurrency === partnerLocalCurrency &&
                    con.conversionRate !== null
                ) {
                    return true;
                }

                return false;
            });

            if (sourceToLocalConversion) {
                setSourceToLocalConversionRate(sourceToLocalConversion.conversionRate);
            }
        }
    }, [currency, currencyConversions, partnerLocalCurrency]);

    // PartnerInfo section is common to both of the tabs
    const PartnerInfo = () => (
        <>
            <div className="flex flex-row gap-2 justify-between mb-4">
                <div className="col">
                    <p className="font-poppins font-semibold text-purple-600">
                        Partner Name: {partner?.partnerName}
                    </p>
                </div>
                <div className="col text-right">
                    <p className="font-poppins font-semibold text-purple-600">
                        Cost: {costPrice?.toFixed(2)} {` ` + currency}{' '}
                        <i
                            className={`${currencyAndCountryCodes
                                .find((x) => x.currency === currency)
                                ?.country.toLowerCase()} flag px-5 pt-1`}
                        ></i>
                    </p>
                </div>
            </div>
            <div className="w-full mb-4">
                <p className="font-poppins font-semibold text-purple-600">
                    Partner ID: {partner?.pid}
                </p>
            </div>
        </>
    );

    return (
        <Modal
            open={openModal}
            onClose={handleModalClose}
            center={true}
            styles={{ modal: { borderRadius: 8, maxWidth: '80%' } }}
            focusTrapped={false}
        >
            <div className="flex flex-row p-3 mb-3 justify-center">
                <h3 className="text-xl font-poppins font-semibold flex">
                    View/Edit Conversions & Prices
                </h3>
            </div>
            <div className="relative w-full">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        selfDestruct={true}
                        selfDestructTimer={1000}
                        margin={0}
                        error={toastError}
                        style={'relative w-full z-5 h-7 mb-0 -mt-0'}
                    />
                )}
            </div>
            <div className="pt-4">
                <Tabs>
                    <Tab label="Currencies - DCB" isDirty={isDirtyDCBTab}>
                        <>
                            <PartnerInfo />
                            <div className="flex justify-between flex-row items-center font-poppins text-gray-500 mb-5">
                                <div className="flex gap-3 items-center">
                                    Marked-up Percentage (%)
                                    <EditableCell
                                        id="currencies-dcb-marked-up-percentage"
                                        initialValue={
                                            itemLevelMarkedUpPercentageForCurrency ||
                                            partner?.markedUpPercentageForCurrency
                                        }
                                        row={partner}
                                        updateValue={handleMarkedUpPercentageForCurrencyChange}
                                        key={partner?.id}
                                        isOverride={
                                            partner?.isPartnerCurrencyMarkedUpPercentageOverridden
                                        }
                                    />
                                    {partner?.isPartnerCurrencyMarkedUpPercentageOverridden && (
                                        <button
                                            value="menu cursor-pointer"
                                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                            id="marked_up_percentage_reset"
                                            onClick={() =>
                                                resetMarkedUpPercentageForCurrency(partner?.index)
                                            }
                                        >
                                            <BsArrowCounterclockwise />
                                        </button>
                                    )}
                                </div>
                                <button
                                    value="menu cursor-pointer"
                                    className="bg-purple-500 text-white rounded-lg p-3"
                                    id="available_channels"
                                    onClick={() => {
                                        setIsAvailableChannelsModalVisible(true);
                                    }}
                                >
                                    Available Channels
                                </button>
                            </div>
                            {partnerCurrencyConversionsLoading ? (
                                <div className="w-full">
                                    <div className="m-auto flex flex-col ">
                                        <div className="m-auto p-10">
                                            <Loader />
                                        </div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full border rounded-lg">
                                    {calculatedCurrencyConversions?.length > 0 ? (
                                        <DataTableAlter
                                            columns={currencyTableColumns}
                                            data={calculatedCurrencyConversions}
                                            hidePagination={true}
                                            customizedPageSize={
                                                calculatedCurrencyConversions?.length
                                            }
                                            pageCount={1}
                                        />
                                    ) : (
                                        <>
                                            <div className="m-auto flex flex-col ">
                                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                    {currencyTableColumns?.map((column, i) => {
                                                        return (
                                                            <div
                                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                key={i}
                                                            >
                                                                {column?.Header}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="m-auto p-10">No data found.</div>
                                                <div className="p-10 bg-purple-100"></div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <AvailableChannels
                                openModal={isAvailableChannelsModalVisible}
                                setOpenModal={setIsAvailableChannelsModalVisible}
                                partnerId={partner?.pid}
                                itemId={itemID}
                                paymentMethod={PaymentMethod.DCB}
                                setMessage={setMessage}
                                setShowToast={setShowToast}
                                setToastError={setToastError}
                            />
                        </>
                    </Tab>
                    <Tab label="Points" isDirty={isDirtyPointsTab}>
                        <div>
                            <PartnerInfo />
                            <div className="flex gap-3 flex-row items-center font-poppins text-gray-500 mb-5">
                                Marked-up Percentage (%)
                                <EditableCell
                                    id="points-marked-up-percentage"
                                    initialValue={
                                        itemLevelMarkedUpPercentageForPoints ||
                                        partner?.markedUpPercentage
                                    }
                                    row={partner}
                                    updateValue={handleMarkedUpPercentageForPointsChange}
                                    key={partner?.id}
                                    isOverride={
                                        partner?.isPartnerPointsMarkedUpPercentageOverridden
                                    }
                                />
                                {partner?.isPartnerPointsMarkedUpPercentageOverridden && (
                                    <button
                                        value="menu cursor-pointer"
                                        className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                        id="marked_up_percentage_reset"
                                        onClick={() =>
                                            resetMarkedUpPercentageForPoints(partner?.index)
                                        }
                                    >
                                        <BsArrowCounterclockwise />
                                    </button>
                                )}
                            </div>
                            <div>
                                {partnerLocalCurrency || partner?.localCurrency ? (
                                    <DataTableAlter
                                        columns={pointsTableColumns}
                                        data={[partner]}
                                        hidePagination={true}
                                        pageSizes={1}
                                        currentPage={1}
                                    />
                                ) : (
                                    <div className="m-auto flex flex-col">
                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                            {pointsTableColumns.map((col, index) => (
                                                <div
                                                    className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                    key={index}
                                                >
                                                    {col.Header}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="m-auto p-10 flex text-purple-500">
                                            Partner local currency is not set
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Tab>
                    <Tab label="Currencies - PSP" isDirty={isDirtyPSPTab}>
                        <>
                            <PartnerInfo />
                            <div className="flex justify-between flex-row items-center font-poppins text-gray-500 mb-5">
                                <div className="flex gap-3 items-center">
                                    Marked-up Percentage (%)
                                    <EditableCell
                                        id="currencies-psp-marked-up-percentage"
                                        initialValue={
                                            itemLevelMarkedUpPercentageForPsp ||
                                            partner?.markedUpPercentageForPsp
                                        }
                                        row={partner}
                                        updateValue={handleMarkedUpPercentageForPspChange}
                                        key={partner?.id}
                                        isOverride={
                                            partner?.isPartnerPspMarkedUpPercentageOverridden
                                        }
                                    />
                                    {partner?.isPartnerPspMarkedUpPercentageOverridden && (
                                        <button
                                            value="menu cursor-pointer"
                                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none text-xl"
                                            id="marked_up_percentage_reset"
                                            onClick={() =>
                                                resetMarkedUpPercentageForPsp(partner?.index)
                                            }
                                        >
                                            <BsArrowCounterclockwise />
                                        </button>
                                    )}
                                </div>
                                <button
                                    value="menu cursor-pointer"
                                    className="bg-purple-500 text-white rounded-lg p-3"
                                    id="available_channels"
                                    onClick={() => {
                                        setIsAvailableChannelsModalVisible(true);
                                    }}
                                >
                                    Available Channels
                                </button>
                            </div>
                            {partnerCurrencyConversionsLoading ? (
                                <div className="w-full">
                                    <div className="m-auto flex flex-col ">
                                        <div className="m-auto p-10">
                                            <Loader />
                                        </div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full border rounded-lg">
                                    {calculatedCurrencyConversions?.length > 0 ? (
                                        <DataTableAlter
                                            columns={pspTableColumns}
                                            data={calculatedCurrencyConversions}
                                            hidePagination={true}
                                            customizedPageSize={
                                                calculatedCurrencyConversions?.length
                                            }
                                            pageCount={1}
                                        />
                                    ) : (
                                        <>
                                            <div className="m-auto flex flex-col ">
                                                <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                                    {pspTableColumns?.map((column, i) => {
                                                        return (
                                                            <div
                                                                className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                                key={i}
                                                            >
                                                                {column?.Header}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                <div className="m-auto p-10">No data found.</div>
                                                <div className="p-10 bg-purple-100"></div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <AvailableChannels
                                openModal={isAvailableChannelsModalVisible}
                                setOpenModal={setIsAvailableChannelsModalVisible}
                                partnerId={partner?.pid}
                                itemId={itemID}
                                paymentMethod={PaymentMethod.PSP}
                                setMessage={setMessage}
                                setShowToast={setShowToast}
                                setToastError={setToastError}
                            />
                        </>
                    </Tab>
                </Tabs>

                <div className="flex flex-row justify-end pt-4">
                    <Buttons
                        name={
                            saveOverrideConversionsLoading ||
                            resetOverrideLoading ||
                            resetOverrideForCurrencyMarkedUpPercentageLoading ||
                            resetOverrideForPointsMarkedUpPercentageLoading ||
                            resetOverrideForPspMarkedUpPercentageLoading ||
                            savePspMarkedUpPercentageLoading ? (
                                <NormalLoader />
                            ) : (
                                'Save'
                            )
                        }
                        type="submit"
                        buttonType="secondary-main"
                        id="okay"
                        size="e-small"
                        onclick={handleModalSave}
                        padding="py-3"
                        disabled={
                            !conversionRateChange &&
                            !isDirtyPointsTab &&
                            !isDirtyDCBTab &&
                            itemLevelMarkedUpPercentageForPsp == null
                        }
                    />
                    <Buttons
                        name="Close"
                        type="submit"
                        buttonType="secondary"
                        id="okay"
                        size="e-small"
                        onclick={handleModalClose}
                        padding="py-3"
                    />
                </div>

                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModalForLocalCurrencyRateReset}
                    setShowConfirmationModal={setShowConfirmationModalForLocalCurrencyRateReset}
                    messageTitle={LOCAL_CURRENCY_RESET_TITLE}
                    message={LOCAL_CURRENCY_RESET_MESSAGE}
                    setYesBtnClick={handleResetSubmitForLocalCurrencyConversion}
                    setNoBtnClick={() => setShowConfirmationModalForLocalCurrencyRateReset(false)}
                />

                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModalForMarkedUpPriceReset}
                    setShowConfirmationModal={setShowConfirmationModalForMarkedUpPriceReset}
                    messageTitle={MARKED_UP_PRICE_TITLE}
                    message={MARKED_UP_PRICE_MESSAGE}
                    setYesBtnClick={handleResetSubmitMarkedUpPriceReset}
                    setNoBtnClick={() => setShowConfirmationModalForMarkedUpPriceReset(false)}
                />

                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModalForFinalMarkedUpPriceReset}
                    setShowConfirmationModal={setShowConfirmationModalForFinalMarkedUpPriceReset}
                    messageTitle={FINAL_MARKED_UP_PRICE_TITLE}
                    message={FINAL_MARKED_UP_PRICE_MESSAGE}
                    setYesBtnClick={handleResetSubmitFinalMarkedUpPrice}
                    setNoBtnClick={() => setShowConfirmationModalForFinalMarkedUpPriceReset(false)}
                />

                <ResetCurrencyRateConfirmationModal
                    setShowConfirmationModal={setShowConfirmationModal}
                    showConfirmationModal={showConfirmationModal}
                    handleResetSubmit={handleResetSubmit}
                    overrideItem={true}
                />

                <ResetMarkedUpPercentageForCurrencyConfirmationModal
                    setShowConfirmationModal={setShowConfirmationModalForCurrencyMarkedUpPercentage}
                    showConfirmationModal={showConfirmationModalForCurrencyMarkedUpPercentage}
                    handleResetSubmit={handleResetSubmitForCurrencyMarkedUpPercentage}
                    overrideItem={true}
                />

                <ResetMarkedUpPercentageForPointsConfirmationModal
                    setShowConfirmationModal={setShowConfirmationModalForPointsMarkedUpPercentage}
                    showConfirmationModal={showConfirmationModalForPointsMarkedUpPercentage}
                    handleResetSubmit={handleResetSubmitForPointsMarkedUpPercentage}
                    overrideItem={true}
                />

                <ResetMarkedUpPercentageForPspConfirmationModal
                    setShowConfirmationModal={setShowConfirmationModalForPspMarkedUpPercentage}
                    showConfirmationModal={showConfirmationModalForPspMarkedUpPercentage}
                    handleResetSubmit={handleResetSubmitForPspMarkedUpPercentage}
                    overrideItem={true}
                />

                <WarningMessageModal
                    showWarningModal={showWarningModal}
                    setShowWarningModal={setShowWarningModal}
                    warningModalBody={warningModalBody}
                    warningModalTitle={warningModalTitle}
                    setProcessFinishedClicked={
                        warningModalBody != MARKEDUP_PERCENTAGE_FOR_CURRENCY_NOT_SET_ERROR &&
                        handleWarningOkayClick
                    }
                    isGlobalCurrencyUpdateEnabled={setItNowButtonEnabled}
                    setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                />
                <GlobalCurrencyUpdateModal
                    messageTitle={'Global Currency Conversion'}
                    globalCurrencyUpdateModal={globalCurrencyUpdateModal}
                    setGlobalCurrencyUpdateModal={setGlobalCurrencyUpdateModal}
                    data={selectedGlobalCurrency}
                    callAfterProcessFinished={() => {
                        setShowWarningModal(false);
                        setSetItNowButtonEnabled(true);
                        setGlobalCurrencyUpdateModal(false);
                        handleWarningOkayClick();
                    }}
                />

                <ConfirmationModalWithMessage
                    showConfirmationModal={showConfirmationModalForFinalRetailPriceReset}
                    setShowConfirmationModal={setShowConfirmationModalForFinalRetailPriceReset}
                    messageTitle={FINAL_REATIL_PRICE_TITLE}
                    message={FINAL_REATIL_PRICE_MESSAGE}
                    setYesBtnClick={handleResetSubmiFinalRetailPriceReset}
                    setNoBtnClick={() => setShowConfirmationModalForFinalRetailPriceReset(false)}
                />
            </div>
        </Modal>
    );
}
