import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import {
    ERROR_NETWORK,
    ERROR_SERVER,
    GQL_STORE_API_URL,
    GQL_USER_API_URL,
    GQL_UTIL_API_URL,
} from '../../constants/config/constants';
import { getCookie } from '../../utils/cookiesService';
import { onError } from '@apollo/client/link/error';
import { ClientsEnum } from '../../enums/apoloClient/client-enum';
import { store } from '../..';
import { logout } from '../../redux/rootActions';

const usersLink = new HttpLink({
    uri: GQL_USER_API_URL,
});

const directionalLink = new RetryLink().split(
    (operation) => operation.getContext().clientName === ClientsEnum.UTIL,
    new HttpLink({ uri: GQL_UTIL_API_URL }),
    new HttpLink({ uri: GQL_STORE_API_URL }),
);

const authLink = setContext((_, { headers }) => {
    // get the authentication token from cookie storage if it exists
    const token = getCookie('access_token');

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) {
        if (networkError?.toString() === ERROR_NETWORK) {
            localStorage.setItem('Error', ERROR_SERVER);
        }
        console.log(`[Network error]: ${networkError}`);
        const { response } = operation.getContext();

        if (response.status === 401) {
            store.dispatch(logout());
        }
    }
});

export const userClient = new ApolloClient({
    link: from([
        errorLink,
        authLink.concat(
            ApolloLink.split(
                (operation) =>
                    operation.getContext().clientName === ClientsEnum.STORE ||
                    operation.getContext().clientName === ClientsEnum.UTIL,
                directionalLink, // <= apollo will send to this if clientName is "storeLink"
                usersLink, // <= otherwise will send to this
            ),
        ),
    ]),
    cache: new InMemoryCache(),
});
