import Login from './screens/loginScreens/Login';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import ResetTwoStepAuthScreen from './screens/resetPasswordScreens/ResetTwoStepAuthScreen';
import ResetPasswordScreen from './screens/resetPasswordScreens/ResetPasswordScreen';
import ResetPasswordEmailScreen from './screens/resetPasswordScreens/ResetPasswordEmailScreen';
import AdminProfileScreens from './screens/profileScreens/AdminProfileScreens';
import ProtectedRoute from '../routes/ProtectedRoute';
import ResetNewUserPasswordScreen from './screens/resetPasswordScreens/ResetNewUserPassword';
import 'react-responsive-modal/styles.css';
import EmailSuccessScreen from './screens/resetPasswordScreens/EmailSentScreen';
import DashBoardScreen from './screens/dashboard/DashBoardScreen';
import ConfirmVerificationEmail from './screens/profileScreens/ConfirmVerificationEmail';
import SupplierScreen from './screens/supplierScreens/supplierScreens';
import ProtectedRoutesWithLayout from '../routes/RouteWithLayout';
import ListAllProductScreen from './screens/productScreens/ProductListScreen';
import ProtectedRoutesWithLayoutAndBreadcrumb from '../routes/RouteWithBreadcrumb';
import ChangeEmailModal from './templates/modals/ChangeEmailModal';
import InviteUserModal from './templates/modals/InviteNewUserModal';
import ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar from '../routes/RoutesWithBreadCrumbAndSideBar';
import ProductGeneral from './screens/productScreens/ProductGeneral';
import ProductInventory from './screens/productScreens/ProductInventory';
import EditProductScreen from './screens/productScreens/EditProductDetails';
import EditProductInventory from './screens/productScreens/EditProductInventory';
import ProtectedRoutesWithLayoutAndBreadcrumbAndEditSideBar from '../routes/RoutesWithBreadCrumAndEditSideBar';
import ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar from '../routes/RoutesWithBreadCrumAndViewSideBar';
import ViewProductScreen from './screens/productScreens/ViewProductGeneral';
import ViewProductInventory from './screens/productScreens/ViewProductInventory';
import AddNewCategory from './screens/categoryScreens/AddNewCategory';
import CategoryScreen from './screens/categoryScreens/CategoryListScreens';
import EditCategory from './screens/categoryScreens/EditCategory';
import ViewCategory from './screens/categoryScreens/ViewCateogory';
import OrdersScreen from './screens/orders/OrdersListScreen';
import ListPaymentChannelsScreen from './screens/paymentChannelsScreens/ListPaymentChannelsScreen';
import UsersScreen from './screens/usersScreens/UserListScreen';
import ViewOneUserScreen from './screens/usersScreens/ViewOneUserScreen';
import AddNewScreen from './screens/usersScreens/AddNewScreen';
import AddNewUserAccountDetails from './screens/usersScreens/AddNewUserAccountDetails';
import AddNewUserPersonalDetails from './screens/usersScreens/AddNewUserPersonalDetails';
import AddNewPartnerGeneralView from './screens/partnerScreens/addPartner/AddNewPartnerGeneralView';
import AddNewPartnerAgreementView from './screens/partnerScreens/addPartner/AddNewPartnerAgreementView';
import PartnerListScreen from './screens/partnerScreens/PartnerListScreen';
import ViewPartnerGeneral from './screens/partnerScreens/viewPartner/ViewPartnerGeneral';
import ViewPartnerAgreement from './screens/partnerScreens/viewPartner/ViewPartnerAgreement';
import EditPartnerGeneral from './screens/partnerScreens/editPartner/EditPartnerGeneral';
import ReportsOverView from './screens/reports/ReportsOverView';
import NewPassword from './screens/resetPasswordScreens/NewPassword';
import AddPricingDetails from './screens/productScreens/AddPricingDetails';
import ViewPricingDetails from './screens/productScreens/ViewPricingDetails';
import EditPricingDetails from './screens/productScreens/EditPricingDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import ViewSupplier from './screens/supplierScreens/ViewSupplier';
import EditPartnerAgreement from './screens/partnerScreens/editPartner/EditPartnerAgreementView';
import AddNewSupplier from './screens/supplierScreens/add-supplier/AddNewSupplierGeneral';
import ValidUserLogin from './screens/loginScreens/ValidationLogin';
import OrdersReport from './screens/reports/order/OrdersReport';
import InventoryReport from './screens/reports/inventory/InventoryReport';
import NotFound from './screens/notFoundScreens/NotFound';
import EditSupplierGeneral from './screens/supplierScreens/edit-supplier/EditSupplierGeneral';
import CatalogCategory from './screens/partnerScreens/catalogCategory/CatalogCategory';
import ViewProductVouchers from './screens/supplierScreens/ViewProductVouchers';
import ViewFileProducts from './screens/supplierScreens/ViewFileProducts';
import LoginValidity from './templates/LoginValidity';
import CatalogItem from './screens/partnerScreens/catalogItem/CatalogItem';
import AddPartnerCurrencyPreferencesView from './screens/partnerScreens/addPartner/AddPartnerCurrencyPreferences';
import ViewCurrencyPreferences from './screens/partnerScreens/viewPartner/ViewPartnerCurrencyPreferences';
import GlobalCurrencyConversions from './screens/currencyConversion/GlobalCurrencyConversions';
import { PARTNER_CURRENCY_ADD_PATH } from '../constants/partner';
import FailedTransactionsScreen from './screens/orders/FailedTransactionScreens/FailedTransactionListScreen';
import ProductGroupListScreen from './screens/productGroupScreens/ProductGroupListScreen';
import AddNewGroup from './screens/productGroupScreens/AddProductGroup';
import ViewProductGroup from './screens/productGroupScreens/ViewProductGroup';
import EditProductGroup from './screens/productGroupScreens/EditProductGroup';
import AddPaymentChannelScreen from './screens/paymentChannelsScreens/add/AddPaymentChannelScreen';
import EditPaymentChannelScreen from './screens/paymentChannelsScreens/edit/EditPaymentChannelScreen';
import ViewPaymentChannelScreen from './screens/paymentChannelsScreens/view/ViewPaymentChannelScreen';
import ViewCsvMismatches from './screens/supplierScreens/view-csv-mismatches/ViewCsvMismatches';
import { REVIEW_MISMATCHES } from '../constants/config/constants';

function App() {
    const productName = useSelector((state: RootState) => state.auth.itemName);

    const supplier = useSelector((state: RootState) => state.auth.supplierName);
    const category = useSelector((state: RootState) => state.auth.categoryName);
    const partner = useSelector((state: RootState) => state.auth.partnerName);
    const productGroup = useSelector((state: RootState) => state.auth.productGroupName);
    const paymentChannel = useSelector((state: RootState) => state.auth.paymentChannelName);
    const paymentChannelID = useSelector((state: RootState) => state.auth.paymentChannelId);

    return (
        <div>
            <BrowserRouter>
                <LoginValidity />
                <Switch>
                    <Route
                        path="/reset-Password-Email-Verification"
                        component={ResetPasswordEmailScreen}
                    />
                    <Route path="/validation-partner" component={ValidUserLogin} />
                    <Route path="/email-Success-Screen/:email" component={EmailSuccessScreen} />
                    <Route path="/reset-Password" component={ResetPasswordScreen} />
                    <Route path="/new-Password" component={NewPassword} />
                    <Route path="/reset-Two-Step-Auth-Screen" component={ResetTwoStepAuthScreen} />
                    <Route
                        path="/reset-New-User-Password-Screen"
                        component={ResetNewUserPasswordScreen}
                    />
                    <Route exact path="/" component={Login} />
                    <ProtectedRoute path="/registration" component={ConfirmVerificationEmail} />
                    <ProtectedRoute
                        path="/adminProfile/dashboard/modals"
                        component={ChangeEmailModal}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/users"
                        component={UsersScreen}
                        navProps={'All Users'}
                        crumbs={['Users', 'All Users']}
                    />
                    <ProtectedRoutesWithLayout
                        path="/users/add-user"
                        component={AddNewScreen}
                        navProps={'Add new users'}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/users/view"
                        component={ViewOneUserScreen}
                        navProps={'View users'}
                        crumbs={['Users', 'All Users', 'View User']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/users/account-details-add"
                        component={AddNewUserAccountDetails}
                        navProps={'Add New User'}
                        sidebarElements={[
                            { name: 'Account Details', path: '/users/account-details-add' },
                            { name: 'Personal Details', path: '/users/personal-details-add' },
                        ]}
                        crumbs={['Users', 'Account details']}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/users/personal-details-add"
                        component={AddNewUserPersonalDetails}
                        navProps={'Add New User'}
                        sidebarElements={[
                            { name: 'Account Details', path: '/users/account-details-add' },
                            { name: 'Personal Details', path: '/users/personal-details-add' },
                        ]}
                        crumbs={['Users', 'Personal Details']}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/general-details-add"
                        component={AddNewPartnerGeneralView}
                        navProps={'Add New Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-add' },
                            {
                                name: 'Currency Preferences',
                                path: PARTNER_CURRENCY_ADD_PATH,
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-add' },
                        ]}
                        crumbs={['Partners', 'General']}
                        add={true}
                        edit={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/agreement-details-add"
                        component={AddNewPartnerAgreementView}
                        navProps={'Add New Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-add' },
                            {
                                name: 'Currency Preferences',
                                path: PARTNER_CURRENCY_ADD_PATH,
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-add' },
                        ]}
                        crumbs={['Partners', 'Agreements', `${partner ? partner : null}`]}
                        add={true}
                        edit={false}
                        view={false}
                    />
                    {/* <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/account-details-add"
                        component={AddNewPartnerAccountManagementView}
                        navProps={'Add New Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-add' },
                            {
                                name: 'Currency Preferences',
                                path: PARTNER_CURRENCY_ADD_PATH,
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-add' },
                            { name: 'Account Management', path: '/partner/account-details-add' },
                        ]}
                        crumbs={['Partners', 'Account Management', `${partner ? partner : null}`]}
                        edit={false}
                        add={true}
                        view={false}
                    /> */}
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path={PARTNER_CURRENCY_ADD_PATH}
                        component={AddPartnerCurrencyPreferencesView}
                        navProps={'Add New Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-add' },
                            {
                                name: 'Currency Preferences',
                                path: PARTNER_CURRENCY_ADD_PATH,
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-add' },
                        ]}
                        crumbs={['Partners', 'Currency Preferences', `${partner ? partner : null}`]}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    {/* <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/account-details-view"
                        component={ViewPartnerAccountManagement}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Account Management', path: '/partner/account-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Account Management',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        add={false}
                        edit={false}
                        view={false}
                    /> */}
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/agreement-details-view"
                        component={ViewPartnerAgreement}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Agreements',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        add={false}
                        edit={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/general-details-view"
                        component={ViewPartnerGeneral}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'General',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/view/catalog/setup"
                        component={() => <CatalogCategory viewOnly={true} />}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Catalog Setup',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/view/item/setup"
                        component={() => <CatalogItem viewOnly={true} />}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Catalog Setup',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/view/currency-preferences"
                        component={() => <ViewCurrencyPreferences />}
                        navProps={'View Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-view' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/view/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-view' },
                            { name: 'Catalog Setup', path: '/partner/view/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/view/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Currency Preferences',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/general-details-edit"
                        component={EditPartnerGeneral}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'General',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/agreement-details-edit"
                        component={EditPartnerAgreement}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Agreements',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    {/* <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/account-details-edit"
                        component={EditPartnerAccountManagement}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Account Management', path: '/partner/account-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Account Management',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    /> */}
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/edit/catalog/setup"
                        component={() => <CatalogCategory viewOnly={false} />}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Catalog Setup',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/edit/item/setup"
                        component={() => <CatalogItem viewOnly={false} />}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Catalog Setup',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/partner/edit/currency-preferences"
                        component={() => <AddPartnerCurrencyPreferencesView />}
                        navProps={'Edit Partner'}
                        sidebarElements={[
                            { name: 'General', path: '/partner/general-details-edit' },
                            {
                                name: 'Currency Preferences',
                                path: '/partner/edit/currency-preferences',
                            },
                            { name: 'Agreement', path: '/partner/agreement-details-edit' },
                            { name: 'Catalog Setup', path: '/partner/edit/catalog/setup' },
                            { name: 'Item Setup', path: '/partner/edit/item/setup' },
                        ]}
                        crumbs={[
                            'Partners',
                            'Currency Preferences',
                            `${partner ? partner : localStorage.getItem('partnerName') ?? null}`,
                        ]}
                        edit={false}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayout
                        path="/dashboard"
                        component={DashBoardScreen}
                        navProps={'Dashboard'}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/suppliers"
                        component={SupplierScreen}
                        navProps={'All Suppliers'}
                        crumbs={['Suppliers', 'All Suppliers']}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        exact
                        path="/suppliers/general-add"
                        component={AddNewSupplier}
                        sidebarElements={[{ name: 'General', path: '/suppliers/general-add' }]}
                        navProps={'Add Suppliers'}
                        crumbs={['Suppliers', 'Add Suppliers']}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    {/* <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        exact
                        path="/suppliers/points-add"
                        component={AddNewSupplierPoints}
                        sidebarElements={[
                            { name: 'General', path: '/suppliers/general-add' },
                            { name: 'Partner conversion points', path: '/suppliers/points-add' },
                        ]}
                        navProps={'Add Suppliers'}
                        crumbs={['Suppliers', 'Add Suppliers', supplier ? supplier : '']}
                        edit={false}
                        add={true}
                        view={false}
                    /> */}

                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/suppliers-view"
                        component={ViewSupplier}
                        navProps={'View Supplier'}
                        crumbs={['Suppliers', 'View Supplier', `${supplier ? supplier : null}`]}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/suppliers-view/:supplierName/product/:id/vouchers/"
                        component={ViewProductVouchers}
                        navProps={'View Supplier'}
                        crumbs={[
                            'Suppliers',
                            'View Supplier',
                            `${supplier ? supplier : null}`,
                            'Vouchers',
                        ]}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/suppliers-view/:supplierName/:supplierId/file/:fileId"
                        component={ViewFileProducts}
                        navProps={'View Supplier'}
                        crumbs={[
                            'Suppliers',
                            'View Supplier',
                            `${supplier ? supplier : null}`,
                            'Products',
                        ]}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        exact
                        path="/suppliers-edit"
                        component={EditSupplierGeneral}
                        navProps={'Edit Supplier'}
                        crumbs={[
                            'Suppliers',
                            'Edit Supplier',
                            `${supplier ? supplier : localStorage.getItem('supplierName') || null}`,
                        ]}
                        sidebarElements={[{ name: 'General', path: '/suppliers-edit' }]}
                        edit={true}
                        add={false}
                    />

                    {/* <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        exact
                        path="/suppliers-edit-points"
                        component={EditSupplierPoints}
                        sidebarElements={[
                            { name: 'General', path: '/suppliers-edit' },
                            { name: 'Partner conversion points', path: '/suppliers-edit-points' },
                        ]}
                        edit={true}
                        add={false}
                        navProps={'Edit Supplier'}
                        crumbs={[
                            'Suppliers',
                            'Edit Supplier',
                            supplier ? supplier : localStorage.getItem('supplierName') || '',
                        ]}
                        view={false}
                    /> */}

                    <ProtectedRoutesWithLayout
                        path="/suppliers/view-csv-mismatches"
                        component={ViewCsvMismatches}
                        navProps={REVIEW_MISMATCHES}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/reports/overview"
                        component={ReportsOverView}
                        navProps={'Overview'}
                        crumbs={['Reports', 'Overview']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/reports/orders"
                        component={OrdersReport}
                        navProps={'Orders report'}
                        crumbs={['Reports', 'Orders']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/reports/inventory"
                        component={InventoryReport}
                        navProps={'Inventory report'}
                        crumbs={['Reports', 'Inventory']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/products/categories"
                        component={CategoryScreen}
                        navProps={'All Categories'}
                        crumbs={['Products', 'Categories']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/products/groups"
                        component={ProductGroupListScreen}
                        navProps={'All Groups'}
                        crumbs={['Products', 'Groups']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/orders"
                        component={OrdersScreen}
                        navProps={'Orders'}
                        crumbs={['Orders', 'All Orders']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/orders/failedTransactions"
                        component={FailedTransactionsScreen}
                        navProps={'Failed Transactions'}
                        crumbs={['Orders', 'Failed Transactions']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        exact
                        path="/payment-channels"
                        component={ListPaymentChannelsScreen}
                        navProps={'Payment Channels'}
                        crumbs={['Payment Channels', 'All Payment Channels']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/payment-channels/add"
                        exact
                        component={AddPaymentChannelScreen}
                        sidebarElements={[{ name: 'General', path: '/payment-channels/add' }]}
                        navProps={'Add Payment Channel'}
                        crumbs={['Payment Channels', 'Add Payment Channel']}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/payment-channels/edit/:id"
                        exact
                        component={EditPaymentChannelScreen}
                        sidebarElements={[
                            { name: 'General', path: `/payment-channels/edit/${paymentChannelID}` },
                        ]}
                        navProps={'Edit Payment Channel'}
                        crumbs={[
                            'Payment Channels',
                            'Edit Payment Channel',
                            `${paymentChannel || null}`,
                        ]}
                        edit={true}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/payment-channels/view/:id"
                        exact
                        component={ViewPaymentChannelScreen}
                        sidebarElements={[
                            { name: 'General', path: `/payment-channels/view/${paymentChannelID}` },
                        ]}
                        navProps={'View Payment Channel'}
                        crumbs={[
                            'Payment Channels',
                            'View Payment Channel',
                            `${paymentChannel || null}`,
                        ]}
                        edit={true}
                        add={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products"
                        exact
                        component={ListAllProductScreen}
                        navProps={'All Products'}
                        crumbs={['Products', 'All Products']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/partners"
                        exact
                        component={PartnerListScreen}
                        navProps={'All Partners'}
                        crumbs={['Partners', 'All Partners']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/categories/add"
                        exact
                        component={AddNewCategory}
                        navProps={'Add New Category'}
                        crumbs={['Products', 'Categories', 'Add New Categories']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/groups/add"
                        exact
                        component={AddNewGroup}
                        navProps={'Add New Group'}
                        crumbs={['Products', 'Groups', 'Add New Group']}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/groups/view/:id"
                        component={ViewProductGroup}
                        navProps={'View Product Group'}
                        crumbs={[
                            'Products',
                            'Groups',
                            'View Group',
                            `${productGroup ? productGroup : null}`,
                        ]}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/groups/edit/:id"
                        component={EditProductGroup}
                        navProps={'Edit Product Group'}
                        crumbs={[
                            'Products',
                            'Groups',
                            'Edit Group',
                            `${productGroup ? productGroup : null}`,
                        ]}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/categories/edit"
                        component={EditCategory}
                        navProps={'Edit Category'}
                        crumbs={[
                            'Products',
                            'Categories',
                            'Edit Category',
                            `${category ? category : null}`,
                        ]}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/products/categories/view"
                        component={ViewCategory}
                        navProps={'View Category'}
                        crumbs={[
                            'Products',
                            'Categories',
                            'View Category',
                            `${category ? category : null}`,
                        ]}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/products/general-add"
                        component={ProductGeneral}
                        navProps={'Add New Products'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'General', supplier ? supplier : '']}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/products/inventory-add"
                        component={ProductInventory}
                        navProps={'Add New Products'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'Inventory']}
                        edit={false}
                        view={false}
                        add={true}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumbAndSideBar
                        path="/products/pricing-add"
                        component={AddPricingDetails}
                        navProps={'Add New Products'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={[
                            'Products',
                            'Pricing and Conversions',
                            `${supplier ? supplier : null}`,
                            `${productName ? productName : null}`,
                        ]}
                        edit={false}
                        add={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar
                        path="/products/pricing-view/:id"
                        component={ViewPricingDetails}
                        navProps={'View Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={[
                            'Products',
                            'Pricing and Conversions',
                            `${productName ? productName : null}`,
                        ]}
                        edit={false}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndEditSideBar
                        path="/products/general-edit/:id"
                        component={EditProductScreen}
                        navProps={'Edit Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'General', `${productName ? productName : null}`]}
                        edit={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndEditSideBar
                        path="/products/pricing-edit/:id"
                        component={EditPricingDetails}
                        navProps={'Edit Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={[
                            'Products',
                            'Pricing and Conversions',
                            `${productName ? productName : null}`,
                        ]}
                        edit={true}
                        view={false}
                    />

                    <ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar
                        path="/products/general-view/:id"
                        component={ViewProductScreen}
                        navProps={'View Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'General', `${productName ? productName : null}`]}
                        edit={false}
                        view={true}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndViewSideBar
                        path="/products/inventory-view"
                        component={ViewProductInventory}
                        navProps={'View Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'Inventory', `${productName ? productName : null}`]}
                        edit={false}
                        view={true}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumbAndEditSideBar
                        path="/products/inventory-edit"
                        component={EditProductInventory}
                        navProps={'Edit Product'}
                        sidebarElements={['General', 'Inventory', 'Attribute']}
                        crumbs={['Products', 'Inventory']}
                        edit={true}
                        view={false}
                    />
                    <ProtectedRoutesWithLayoutAndBreadcrumb
                        path="/currencyConversion"
                        component={GlobalCurrencyConversions}
                        navProps={'Global Currency Conversions'}
                        crumbs={['Currency Conversions', 'Global Currency Conversions']}
                    />
                    <ProtectedRoutesWithLayout
                        exact
                        path="/adminProfile"
                        component={AdminProfileScreens}
                        navProps={'Admin Profile'}
                    />
                    <ProtectedRoutesWithLayout
                        path="/inviteUserModal"
                        component={InviteUserModal}
                        navProps={'Admin Profile'}
                    />
                    <Route path="*" component={NotFound} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
