/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../../../styles/dropdown.css';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import Loader from '../../../utils/loader';
import * as BsIcons from 'react-icons/bs';
import { pagination } from '../../../constants/config/constants';
import { Buttons } from '../../ui/atoms/Button';
import DataTable from '../../ui/organisms/DataTable';
import SearchBar from '../../ui/molecules/SearchBar';
import {
    ADD_CATEGORIES,
    BULK_ACTION,
    FETCH_CATEGORIES,
    FETCH_CATEGORIES_BY_ID,
} from '../../../queries/CategoryQueries';
import { useHistory } from 'react-router';
import {
    INSTANT_EDIT_OPTION_EDIT,
    INSTANT_EDIT_OPTION_VIEW,
    INSTANT_EDIT_OPTION_TRASH,
    INSTANT_EDIT_OPTION_RESTORE,
    RESTORE_CONFIRMATION_TITLE,
    INSTANT_EDIT_OPTION_DUPLICATE,
    INSTANT_EDIT_OPTION_UNPUBLISH,
    INSTANT_EDIT_OPTION_PUBLISH,
    PUBLISH_CONFIRMATION_TITLE,
} from '../../../constants/product';
import TableTabView from '../productScreens/TableTabView';
import ConfirmationModalWithMessage from '../../templates/modals/ConfirmationModalWithMessage';
import {
    BULK_ACTION_RESTORED_SUCCESS_ALL_CATEGORIES,
    BULK_ACTION_RESTORE_ALL_CATEGORIES,
    BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES,
    BULK_ACTION_SEND_TO_TRASH_CATEGORIES,
    BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES,
    BULK_ACTION_TRASHED_SUCCESS_ALL_CATEGORIES,
    PUBLISH_CONFIRMATION_MESSAGE_CATEGORIES,
    RESTORE_CONFIRMATION_MESSAGE_CATEGORIES,
    SUCCESS_MESSAGE_DUPLICATE_CATEGORY,
    SUCCESS_MESSAGE_ACTIVATED_CATEGORY,
    SUCCESS_MESSAGE_RESTORE_CATEGORY,
    SUCCESS_MESSAGE_TRASHED_CATEGORY,
    SUCCESS_MESSAGE_INACTIVATED_CATEGORY,
    ACTIVE_CONFIRMATION_TITLE,
} from '../../../constants/category';
import Toast from '../../ui/atoms/Toast';
import {
    NOT_REGISTERED,
    NOT_REGISTERED_WARNING_BODY,
    NOT_REGISTERED_WARNING_TITLE,
    REGISTERED,
} from '../../../constants/profile';
import WarningMessageModal from '../../templates/modals/WarningMessageModal';
import { ItemStatus } from '../../../enums/item';

export default function CategoryScreen() {
    const [completeQuery, setCompleteQuery] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [instantEditMenuItems, setInstantEditMenuItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [confirmationMessageTitle, setConfirmationMessageTitle] = useState('');
    const [clickedCategory, setClickedCategory] = useState(null);
    const [cellClickedCategory, setCellClickedCategory] = useState(null);
    const [categoriesData, setCategoriesData] = useState([]);
    const [bulkActionsMenuItems, setBulkActionsMenuItems] = useState([]);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const history = useHistory();
    const [complete, setComplete] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [categoryDataInitial, setCategoryDataInitial] = useState([]);
    const [categoryDataUnMutated, setCategoryDataMutated] = useState([]);
    const [message, setMessage] = useState('');
    const [confirmationModalYesClicked, setConfirmationModalYesClicked] = useState(false);
    const [confirmationModalNoClicked, setConfirmationModalNoClicked] = useState(false);
    const [pageIndex, setPageIndex] = useState(pagination?.pageIndex);
    const [pageSize, setPageSize] = useState(pagination?.pageSize);
    const [pageCount, setPageCount] = useState(pagination?.pageCount);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [restoreSuccess, setRestoreSuccess] = useState(false);
    const [trashSuccess, setTrashedSuccess] = useState(false);
    const [bulkAction, setBulkAction] = useState('');
    const [error, setError] = useState(false);
    const [IsDuplicate, setIsDuplicate] = useState(false);
    const [isTrash, setIsTrash] = useState(false);
    const [isRestore, setIsRestore] = useState(false);
    const [isStatusChange, setIsStatusChange] = useState(false);
    const [isRestoreBulk, setIsRestoreBulk] = useState(false);
    const [duplicatedCategory, setDuplicatedCategory] = useState(null);
    const [duplicateMutationCompleted, setDuplicateMutationCompleted] = useState(false);
    const [applyBulkActionClicked, setApplyBulkActionClicked] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalBody, setWarningModalBody] = useState('');
    const [warningModalTitle, setWarningModalTitle] = useState('');
    const [warningModalOkayClicked, setWarningModalOkayClicked] = useState(false);
    const isRegistered = localStorage.getItem('isRegistered');
    const [categoryStatus, setCategoryStatus] = useState({
        all: 0,
        published: 0,
        unpublished: 0,
        trashed: 0,
    });
    const [isInitialLoad, setIsInitialLoad] = useState(false);

    const categoryNames = categoryDataUnMutated?.map((category) => category?.categoryName);
    const categoryIds = categoryDataUnMutated?.map((category) => category?.categoryId);
    const categoryDataArray = categoryNames?.concat(categoryIds);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Category ID',
                accessor: 'categoryId',
            },
            {
                Header: 'Category Name',
                accessor: 'categoryName',
                Cell: function decorateCell({ value }) {
                    return (
                        <span className="cursor-pointer underline text-blue-600">
                            {value.slice(0, 50)}
                        </span>
                    );
                },
            },
            {
                Header: 'Description',
                accessor: 'categoryDescription',
                Cell: function showDescription({ value }) {
                    return (
                        <span
                            className={`inline-flex rounded-full h-6 px-3 justify-center items-center`}
                        >
                            {value?.slice(0, 50)}
                        </span>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'isActive',
                Cell: function status({ row }) {
                    const status = row?.original?.isActive ? 'Active' : 'Inactive';
                    const featured = row?.original?.featured;
                    return (
                        <>
                            <span
                                className={`inline-flex rounded-full h-6 px-3 justify-center items-center text-sm ${
                                    status === 'Active'
                                        ? 'bg-green-100 text-green-600'
                                        : status === 'Inactive'
                                        ? 'bg-red-100 text-red-600'
                                        : ''
                                }`}
                            >
                                {status}
                            </span>
                            {featured && (
                                <span className="inline-flex rounded-full h-6 px-3 ml-3 justify-center items-center text-sm bg-yellow-100 text-yellow-600">
                                    Featured
                                </span>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Group Count',
                accessor: 'groupCount',
            },
            {
                id: 'actionColumn',
                accessor: '',
                disableSortBy: true,
                width: 5,
                Cell: (
                    <div className="cursor__pointer">
                        <button
                            value="menu cursor-pointer"
                            className="text-gray-500 rounded cursor-pointer border border-transparent focus:outline-none lg:ml-2"
                        >
                            <BsIcons.BsThreeDots className="mx-auto" />
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    const getCatageoryDataSet = (row) => {
        const dataOfCurrentCategory = row?.values?.categoryId;
        // ?.filter(
        //     (cat) =>
        //         (cat?.category?.categoryId === row?.values?.categoryId &&
        //             cat?.item?.itemStatus === ItemStatus.PUBLISHED) ||
        //         cat?.item?.itemStatus === ItemStatus.UNPUBLISHED,
        // )
        // ?.map((data) => ({
        //     id: data?.productGroup?.id,
        // }));
        // const data = dataOfCurrentCategory?.map((groups) => groups?.productGroup);
        // console.log(dataOfCurrentCategory);
        return new Set(dataOfCurrentCategory);
    };

    const [saveCategory, { error: queryError }] = useMutation(ADD_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        refetchQueries: [FETCH_CATEGORIES],
        fetchPolicy: 'network-only',
        onCompleted: () => {
            setComplete(true);
        },
        onError: () => {
            setError(true);
            setMessage(queryError?.message || 'Oops something went wrong');
            setShowToast(true);
        },
    });

    const [duplicateCategory] = useMutation(ADD_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: (data) => {
            setDuplicatedCategory(data?.saveCategory);
            setDuplicateMutationCompleted(true);
        },
    });

    const [updateCategoryList] = useMutation(BULK_ACTION, {
        context: { clientName: ClientsEnum.STORE },
        onCompleted: () => {
            setApplyBulkActionClicked(false);
        },
        onError: () => {
            setMessage(queryError?.message || 'Oops something went wrong');
            setShowToast(true);
        },
    });

    const { data: categoryData, loading } = useQuery(FETCH_CATEGORIES, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: IsDuplicate ? 'cache-only' : 'network-only',
        nextFetchPolicy: IsDuplicate ? 'cache-only' : 'network-only',
        onCompleted: () => {
            setCategoriesData(categoryData?.categories);
            setCategoryDataMutated(categoryData?.categories);
            setCategoryDataInitial(categoryData?.categories);
            setCompleteQuery(true);
        },
    });

    const [getSupplierById, { data: categoryQueryData }] = useLazyQuery(FETCH_CATEGORIES_BY_ID, {
        context: { clientName: ClientsEnum.STORE },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
        onCompleted: () => {
            setComplete(true);
        },
    });

    const category = categoryQueryData?.category;

    const handleTrashCategory = (status: boolean) => {
        setIsTrash(true);
        setIsDuplicate(false);
        saveCategory({
            variables: {
                category: {
                    id: category?.id,
                    categoryId: category?.categoryId,
                    parentCategory: category?.parentCategory,
                    categoryName: category?.categoryName,
                    categoryDescription: category?.categoryDescription || 'jk',
                    categoryImage: category?.categoryImage,
                    categoryCoverImage: category?.categoryCoverImage,
                    categoryIcon: category?.categoryIcon,
                    isActive: false,
                    isDeleted: status,
                    productGroupCatalogCategory: category?.productGroupCatalogCategory?.map(
                        (productGroupCatalogCategoryItem) => ({
                            catalogId: productGroupCatalogCategoryItem?.catalog?.catalogId,
                            groupId: productGroupCatalogCategoryItem?.productGroup.id,
                            categoryId: category?.id,
                        }),
                    ),
                },
            },
        });
        setConfirmationModalYesClicked(false);

        if (status === false) {
            setShowToast(true);
            setMessage(SUCCESS_MESSAGE_RESTORE_CATEGORY);
        } else {
            setShowToast(true);
            setMessage(SUCCESS_MESSAGE_TRASHED_CATEGORY);
        }
        setSelectedMenuItem('');
    };

    const handleCategoryActiveStatus = (status: boolean) => {
        setIsStatusChange(true);
        setIsDuplicate(false);
        saveCategory({
            variables: {
                category: {
                    id: category?.id,
                    categoryId: category?.categoryId,
                    parentCategory: category?.parentCategory,
                    categoryName: category?.categoryName,
                    categoryDescription: category?.categoryDescription || 'jk',
                    categoryImage: category?.categoryImage,
                    categoryCoverImage: category?.categoryCoverImage,
                    categoryIcon: category?.categoryIcon,
                    isActive: status,
                    isDeleted: category?.isDeleted,
                    productGroupCatalogCategory: category?.productGroupCatalogCategory?.map(
                        (productGroupCatalogCategoryItem) => ({
                            catalogId: productGroupCatalogCategoryItem?.catalog?.catalogId,
                            groupId: productGroupCatalogCategoryItem?.productGroup.id,
                            categoryId: category?.id,
                        }),
                    ),
                },
            },
        });

        setConfirmationModalYesClicked(false);

        if (status === true) {
            setShowToast(true);
            setMessage(SUCCESS_MESSAGE_ACTIVATED_CATEGORY);
        } else {
            setShowToast(true);
            setMessage(SUCCESS_MESSAGE_INACTIVATED_CATEGORY);
        }
        setSelectedMenuItem('');
    };

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDuplicateCategory = (category: any) => {
        if (isRegistered === REGISTERED) {
            duplicateCategory({
                variables: {
                    category: {
                        parentCategory: category?.parentCategory,
                        categoryName: `COPY-${category?.categoryName}`,
                        categoryDescription: category?.categoryDescription,
                        categoryImage: category?.categoryImage,
                        categoryCoverImage: category?.categoryCoverImage,
                        categoryIcon: category?.categoryIcon,
                        isActive: category?.isActive,
                        isDeleted: category?.isDeleted,
                        items: category?.items?.map((product) => ({
                            id: product?.id,
                        })),
                    },
                },
            });
            setDuplicatedCategory(undefined);
            setSelectedMenuItem('');
            setDuplicateMutationCompleted(false);
        } else {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        }
    };

    useEffect(() => {
        if (duplicateMutationCompleted && duplicatedCategory !== undefined) {
            const index = categoriesData.findIndex(
                (i) => i.categoryId === clickedCategory?.categoryId,
            );
            duplicatedCategory.categoryDescription = clickedCategory?.categoryDescription;
            duplicatedCategory.items = clickedCategory?.items;

            const newList = categoriesData.slice(0);

            newList.splice(index + 1, 0, duplicatedCategory);
            setCategoriesData(newList);
            if (IsDuplicate && complete) {
                setMessage(SUCCESS_MESSAGE_DUPLICATE_CATEGORY + clickedCategory?.categoryId);
            }
            setShowToast(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryDataInitial, duplicateMutationCompleted, complete, IsDuplicate]);

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === REGISTERED) {
            window.location.reload();
        }
    }, [isRegistered, warningModalOkayClicked]);

    useEffect(() => {
        if (warningModalOkayClicked && isRegistered === NOT_REGISTERED) {
            history.push('/adminProfile');
        }
    }, [history, isRegistered, warningModalOkayClicked]);

    useEffect(() => {
        if (clickedCategory?.categoryId) {
            getSupplierById({ variables: { categoryId: clickedCategory?.id } });
        }
    }, [clickedCategory?.categoryId, getSupplierById]);

    useEffect(() => {
        if (trashSuccess) {
            setMessage(BULK_ACTION_TRASHED_SUCCESS_ALL_CATEGORIES);
            setShowToast(true);
        } else if (restoreSuccess) {
            setMessage(BULK_ACTION_RESTORED_SUCCESS_ALL_CATEGORIES);
            setShowToast(true);
        }
    }, [restoreSuccess, trashSuccess]);

    useEffect(() => {
        if (showToast === true) {
            setTimeout(() => {
                setShowToast(false);
            }, 900);
        } else if (isTrash || isRestoreBulk || isStatusChange) {
            window.location.reload();
        }
    }, [showToast, isTrash, isRestore, isRestoreBulk, isStatusChange]);

    useEffect(() => {
        if (isRegistered === NOT_REGISTERED) {
            setWarningModalBody(NOT_REGISTERED_WARNING_BODY);
            setWarningModalTitle(NOT_REGISTERED_WARNING_TITLE);
            setShowWarningModal(true);
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_VIEW &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            localStorage.setItem('view-category', clickedCategory?.id);
            history.push('/products/categories/view');
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_EDIT &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            localStorage.setItem('edit-category', clickedCategory?.id);
            history.push('/products/categories/edit');
            setIsDuplicate(false);
            setSelectedMenuItem('');
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_TRASH &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            if (complete) {
                handleTrashCategory(true);
                setIsTrash(true);
                setIsDuplicate(false);
            }
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_UNPUBLISH &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            if (complete) {
                handleCategoryActiveStatus(false);
                setIsDuplicate(false);
            }
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_PUBLISH &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            if (clickedCategory.groupCount > 0) {
                if (complete) {
                    setConfirmationMessage(PUBLISH_CONFIRMATION_MESSAGE_CATEGORIES);
                    setConfirmationMessageTitle(ACTIVE_CONFIRMATION_TITLE);
                    setShowConfirmationModal(true);
                }
            } else {
                setWarningModalTitle('Cannot activate category');
                setWarningModalBody(
                    'This category contains 0 groups, therefore it cannot be activated.',
                );
                setShowWarningModal(true);
            }
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            if (complete) {
                setIsRestore(true);
                setConfirmationMessage(RESTORE_CONFIRMATION_MESSAGE_CATEGORIES);
                setConfirmationMessageTitle(RESTORE_CONFIRMATION_TITLE);
                setShowConfirmationModal(true);
            }
        } else if (
            selectedMenuItem === INSTANT_EDIT_OPTION_DUPLICATE &&
            clickedCategory &&
            isRegistered === REGISTERED
        ) {
            setIsDuplicate(true);
            setDuplicatedCategory(clickedCategory);
            handleDuplicateCategory(clickedCategory);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenuItem, clickedCategory]);

    useEffect(() => {
        if (confirmationModalNoClicked) {
            setShowConfirmationModal(false);
            setSelectedMenuItem('');
            setConfirmationModalNoClicked(false);
        }
    }, [confirmationModalNoClicked]);

    useEffect(() => {
        if (confirmationModalYesClicked) {
            setShowConfirmationModal(false);
            if (selectedMenuItem === INSTANT_EDIT_OPTION_RESTORE) {
                handleTrashCategory(false);
            } else if (selectedMenuItem === INSTANT_EDIT_OPTION_PUBLISH) {
                handleCategoryActiveStatus(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationModalYesClicked]);

    useEffect(() => {
        let categoryStatusArray = [];

        switch (selectedTab) {
            case 1:
                categoryStatusArray = categoryDataInitial.filter((category) => {
                    return category?.isDeleted === false;
                });
                break;
            case 2:
                categoryStatusArray = categoryDataInitial.filter((category) => {
                    return category?.isActive === true && category?.isDeleted === false;
                });
                break;
            case 3:
                categoryStatusArray = categoryDataInitial.filter((category) => {
                    return category?.isActive === false && category?.isDeleted === false;
                });
                break;
            case 5:
                categoryStatusArray = categoryDataInitial.filter((category) => {
                    return category?.isDeleted === true;
                });
                break;
        }

        if (searchTerm !== '' && searchTerm?.length > 2) {
            const searchResult = categoryStatusArray.filter((data) => {
                return (
                    data?.categoryName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
                    data?.categoryId?.toLowerCase().includes(searchTerm?.toLowerCase())
                );
            });

            categoryStatusArray = searchResult;
        }

        if (!searchTerm?.length) {
            setCategoriesData(categoryStatusArray);
        }

        const noOfPages = Math.ceil(categoryStatusArray?.length / pageSize);
        const pageIndexFilteredData = categoryStatusArray.slice(
            pageIndex * pageSize,
            pageIndex * pageSize + pageSize,
        );
        setPageCount(noOfPages);
        setCategoriesData(categoryStatusArray);
        setFilteredData(categoryStatusArray);
    }, [categoryDataInitial, pageIndex, pageSize, selectedTab, searchTerm]);

    useEffect(() => {
        const instantMenuList = [];

        switch (selectedTab) {
            case 1:
                instantMenuList.push(
                    {
                        text: 'Edit',
                        value: INSTANT_EDIT_OPTION_EDIT,
                    },
                    {
                        text: 'View',
                        value: INSTANT_EDIT_OPTION_VIEW,
                    },
                    {
                        text: 'Active',
                        value: INSTANT_EDIT_OPTION_PUBLISH,
                    },
                    {
                        text: 'Inactive',
                        value: INSTANT_EDIT_OPTION_UNPUBLISH,
                    },
                    {
                        text: 'Trash',
                        value: INSTANT_EDIT_OPTION_TRASH,
                    },
                );
                break;
            case 2:
                instantMenuList.push(
                    {
                        text: 'Edit',
                        value: INSTANT_EDIT_OPTION_EDIT,
                    },
                    {
                        text: 'View',
                        value: INSTANT_EDIT_OPTION_VIEW,
                    },
                    {
                        text: 'Inactive',
                        value: INSTANT_EDIT_OPTION_UNPUBLISH,
                    },
                    {
                        text: 'Trash',
                        value: INSTANT_EDIT_OPTION_TRASH,
                    },
                );
                break;
            case 3:
                instantMenuList.push(
                    {
                        text: 'View',
                        value: INSTANT_EDIT_OPTION_VIEW,
                    },
                    {
                        text: 'Edit',
                        value: INSTANT_EDIT_OPTION_EDIT,
                    },
                    {
                        text: 'Active',
                        value: INSTANT_EDIT_OPTION_PUBLISH,
                    },
                    {
                        text: 'Trash',
                        value: INSTANT_EDIT_OPTION_TRASH,
                    },
                );
                break;
            case 5:
                instantMenuList.push(
                    {
                        text: 'View',
                        value: INSTANT_EDIT_OPTION_VIEW,
                    },
                    {
                        text: 'Restore',
                        value: INSTANT_EDIT_OPTION_RESTORE,
                    },
                );
                break;
        }

        setInstantEditMenuItems(instantMenuList);
    }, [selectedTab]);

    const handleApplyBulkActionBtnClick = () => {
        setApplyBulkActionClicked(true);
    };

    const handleBulkAction = (idList: any, bulkAction: string) => {
        if (isRegistered === REGISTERED) {
            let categoryListInput = {};

            switch (bulkAction) {
                case BULK_ACTION_SEND_TO_TRASH_CATEGORIES:
                    categoryListInput = { isDeleted: true, isActive: false };
                    break;
                case BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES:
                    categoryListInput = { isDeleted: false, isActive: false };
                    break;
                case BULK_ACTION_RESTORE_ALL_CATEGORIES:
                    categoryListInput = { isDeleted: false, isActive: false };
                    break;
                case BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES:
                    categoryListInput = { isDeleted: false, isActive: true };
                    break;
            }
            updateCategoryList({
                variables: {
                    categoryIds: idList,
                    categoryListInput: categoryListInput,
                },
            });
        }
    };

    useEffect(() => {
        const bulkActionList = [];
        switch (selectedTab) {
            case 1:
                bulkActionList.push(
                    {
                        text: 'Move to Active',
                        value: BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES,
                    },
                    {
                        text: 'Move to Inactive',
                        value: BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES,
                    },
                    {
                        text: 'Move to Trash',
                        value: BULK_ACTION_SEND_TO_TRASH_CATEGORIES,
                    },
                );
                break;
            case 2:
                bulkActionList.push(
                    {
                        text: 'Move to Inactive',
                        value: BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES,
                    },
                    {
                        text: 'Move to Trash',
                        value: BULK_ACTION_SEND_TO_TRASH_CATEGORIES,
                    },
                );
                break;
            case 3:
                bulkActionList.push(
                    {
                        text: 'Move to Active',
                        value: BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES,
                    },
                    {
                        text: 'Move to Trash',
                        value: BULK_ACTION_SEND_TO_TRASH_CATEGORIES,
                    },
                );
                break;
            case 5:
                bulkActionList.push({
                    text: 'Restore all',
                    value: BULK_ACTION_RESTORE_ALL_CATEGORIES,
                });
                break;
        }
        setBulkActionsMenuItems(bulkActionList);
    }, [selectedTab]);

    useEffect(() => {
        const categoryList = selectedCategories?.slice(0);
        const catIDs = categoriesData?.filter((cat) =>
            categoryList?.some((cats) => cats === cat?.id),
        );
        const idList = catIDs?.map((category) => {
            return category.categoryId;
        });
        if (
            bulkActionsMenuItems.some(
                (action) => action.value === bulkAction && applyBulkActionClicked,
            )
        ) {
            if (
                bulkAction === BULK_ACTION_SEND_TO_TRASH_CATEGORIES &&
                selectedCategories.length > 1 &&
                applyBulkActionClicked
            ) {
                setIsTrash(true);
                handleBulkAction(idList, bulkAction);
                setTrashedSuccess(true);
                setRestoreSuccess(false);
            } else if (
                bulkAction === BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES &&
                selectedCategories.length > 1 &&
                applyBulkActionClicked
            ) {
                setIsTrash(false);
                setIsRestoreBulk(true);
                handleBulkAction(idList, bulkAction);
                setRestoreSuccess(true);
                setTrashedSuccess(false);
            } else if (
                bulkAction === BULK_ACTION_RESTORE_ALL_CATEGORIES &&
                selectedCategories.length > 1 &&
                applyBulkActionClicked
            ) {
                setIsTrash(false);
                setIsRestoreBulk(true);
                handleBulkAction(idList, bulkAction);
                setRestoreSuccess(true);
                setTrashedSuccess(false);
            } else if (
                bulkAction === BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES &&
                selectedCategories.length > 1 &&
                applyBulkActionClicked
            ) {
                setIsTrash(false);
                setIsRestoreBulk(true);
                handleBulkAction(idList, bulkAction);
                setRestoreSuccess(true);
                setTrashedSuccess(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bulkAction, selectedCategories, applyBulkActionClicked]);

    useEffect(() => {
        if (selectedCategories?.length > 1) {
            setShowBulkActions(true);
        } else {
            setShowBulkActions(false);
        }
    }, [selectedCategories]);

    useEffect(() => {
        const categoryCounts = {
            all: 0,
            published: 0,
            unpublished: 0,
            trashed: 0,
        };

        categoryDataInitial?.map((category) => {
            if (category?.isDeleted === false) {
                categoryCounts.all++;
            }
            if (category?.isDeleted === false && category?.isActive === true) {
                categoryCounts.published++;
            }
            if (category?.isDeleted === false && category?.isActive === false) {
                categoryCounts.unpublished++;
            }
            if (category?.isDeleted === true) {
                categoryCounts.trashed++;
            }
        });
        setCategoryStatus(categoryCounts);
    }, [completeQuery, categoryDataInitial]);

    useEffect(() => {
        if (cellClickedCategory?.id) {
            localStorage.setItem('view-category', cellClickedCategory?.id);
            history.push('/products/categories/view');
        }
    }, [cellClickedCategory?.id, history]);

    if (loading) return <Loader />;

    return (
        <div className="border-2 border-gray-300 rounded-lg mr-7 mb-10">
            <WarningMessageModal
                showWarningModal={showWarningModal}
                setShowWarningModal={setShowWarningModal}
                warningModalBody={warningModalBody}
                warningModalTitle={warningModalTitle}
                setProcessFinishedClicked={setWarningModalOkayClicked}
            />
            <div className="w-full ml-4 pt-4">
                {showToast && (
                    <Toast
                        setShowToast={setShowToast}
                        message={message}
                        width="w-10/12"
                        margin="ml-1"
                        error={error}
                    />
                )}
                <div className={`${showToast ? 'mt-14' : null}`}>
                    <TableTabView
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        categoryCounts={categoryStatus}
                    />
                    <ConfirmationModalWithMessage
                        showConfirmationModal={showConfirmationModal}
                        setShowConfirmationModal={setShowConfirmationModal}
                        message={confirmationMessage}
                        messageTitle={confirmationMessageTitle}
                        setYesBtnClick={setConfirmationModalYesClicked}
                        setNoBtnClick={setConfirmationModalNoClicked}
                    />
                </div>
            </div>
            <div className={`flex p-4`}>
                <div className="flex w-full">
                    <div
                        className="relative flex-grow-0 w-1/2 md:w-1/2 lg:w-1/3"
                        id="category_search"
                    >
                        <SearchBar
                            id="category-list-search-field"
                            options={categoryDataArray}
                            handleChange={(e) => handleChange(e)}
                            placeHolder={'Search category by name or ID'}
                        />
                    </div>
                    {showBulkActions && (
                        <div
                            className="flex flex-row relative space-x-2 px-2 ml-10"
                            id="category_bulkAction"
                        >
                            <select
                                className="pl-2 pr-8 z-0 rounded-md outline-none border font-poppins bg-white border-gray-400 hover:border-purple-500"
                                id="stockStatus"
                                placeholder="actions"
                                onChange={(e) => {
                                    setBulkAction(e.target.value);
                                }}
                                onBlur={(e) => setBulkAction(e.target.value)}
                            >
                                <option value="select" key="select" className="py-1 rounded-md">
                                    Select Action
                                </option>
                                {bulkActionsMenuItems.map((option: any, index) => {
                                    return (
                                        <option value={option.value} key={index} className="py-2">
                                            {option.text}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="bg-purple-500 text-white rounded-md px-8 font-poppins  text-center font-bold"
                                onClick={() => handleApplyBulkActionBtnClick()}
                            >
                                Apply
                            </button>
                        </div>
                    )}
                </div>

                <Buttons
                    name="Add New Category"
                    type="submit"
                    buttonType="primary"
                    id="Add_category_button"
                    size="e-small"
                    padding="py-2"
                    onclick={() => {
                        if (isRegistered === REGISTERED) {
                            history.push('/products/categories/add');
                        } else {
                            alert(isRegistered);
                        }
                    }}
                />
            </div>

            <div className="w-full">
                {categoriesData?.length ? (
                    <DataTable
                        columns={columns}
                        data={categoriesData}
                        setSelectedItems={setSelectedCategories}
                        // setPageIndex={setPageIndex} //future need
                        setDefaultPageSize={setPageSize}
                        menuItems={instantEditMenuItems}
                        setExposeMenuClickedItem={setClickedCategory}
                        setExposeCellClickedItem={setCellClickedCategory}
                        setSelectedMenuItem={setSelectedMenuItem}
                        checkbox={true}
                        checkboxHeader={true}
                        selectedTab={selectedTab}
                        isInitialLoad={isInitialLoad}
                        setIsInitialLoad={setIsInitialLoad}
                    />
                ) : (
                    <div className="m-auto flex flex-col ">
                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                            {columns?.map((column, i) => {
                                return (
                                    <div
                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                        key={i}
                                    >
                                        {column?.Header}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="m-auto p-10">
                            Your search {`"${searchTerm}"`} did not match any results
                        </div>
                        <div className="p-10 bg-purple-100"></div>
                    </div>
                )}
            </div>
        </div>
    );
}
