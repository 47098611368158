export const FAILURE_MESSAGE = 'Oops something went wrong';

export const SUCCESS_MESSAGE_SAVED_CATEGORY = 'Category added successfully';

export const SUCCESS_MESSAGE_UPDATE_CATEGORY = 'Category updated successfully';

export const SUCCESS_MESSAGE_RESTORE_CATEGORY = 'Successfully restored selected category';

export const SUCCESS_MESSAGE_TRASHED_CATEGORY = 'Successfully trashed selected category';

export const SUCCESS_MESSAGE_INACTIVATED_CATEGORY = 'Successfully inactivated selected category';

export const SUCCESS_MESSAGE_ACTIVATED_CATEGORY = 'Successfully activated selected category';

export const RESTORE_CONFIRMATION_MESSAGE_CATEGORIES =
    'Are you sure you want to restore the category from trash?';

export const PUBLISH_CONFIRMATION_MESSAGE_CATEGORIES =
    'Are you sure you want to active the category from inactive?';

export const BULK_ACTION_SEND_TO_TRASH_CATEGORIES = 'SENDTOTRASH';

export const BULK_ACTION_SEND_TO_INACTIVE_CATEGORIES = 'SENDTOINACTIVE';

export const BULK_ACTION_SEND_TO_ACTIVE_CATEGORIES = 'SENDTOACTIVE';

export const BULK_ACTION_RESTORE_ALL_CATEGORIES = 'RESTOREALL';

export const BULK_ACTION_TRASHED_SUCCESS_ALL_CATEGORIES =
    'Successfully trashed selected categories';

export const BULK_ACTION_RESTORED_SUCCESS_ALL_CATEGORIES =
    'Successfully restored selected categories';

export const ACTIVE_CONFIRMATION_TITLE = 'Active';

export const SUCCESS_MESSAGE_DUPLICATE_CATEGORY = 'Successfully duplicated category ';

export const ERROR_MESSAGE_CATALOG = 'Please add catalogs to the selected items';

export const ERROR_EMPTY_FIELDS = 'Please fill all the fields';

export const ERROR_ADD_CATEGORY = 'Error occurred while creating the category';
export const ERROR_EDIT_CATEGORY = 'Error occurred while updating the category';
