import moment from 'moment';

export const BULK_ACTION_SEND_TO_TRASH = 'SENDTOTRASH';

export const UP_AND_HOLD = 'UPANDHOLD';

export const BULK_ACTION_RESTORE_ALL = 'RESTOREALL';

export const BULK_ACTION_MOVE_TO_UNPUBLISHED = 'MOVETOUNPUBLISH';

export const BULK_ACTION_MOVE_TO_PUBLISHED = 'MOVETOPUBLISH';

export const INSTANT_EDIT_OPTION_VIEW = 'VIEW';

export const INSTANT_EDIT_OPTION_EDIT = 'EDIT';

export const PRODUCT_ADD_EXIT_CONFIRM_MESSAGE = 'Are you sure you want to discard the changes?';

export const PRODUCT_ADD_EXIT_CONFIRM_TITLE = 'Are you sure';

export const INSTANT_EDIT_OPTION_DUPLICATE = 'DUPLICATE';

export const INSTANT_EDIT_OPTION_UNPUBLISH = 'UNPUBLISH';

export const INSTANT_EDIT_OPTION_PUBLISH = 'PUBLISH';

export const INSTANT_EDIT_OPTION_TRASH = 'TRASH';

export const INSTANT_EDIT_OPTION_RESTORE = 'RESTORE';

export const SUCCESS_MESSAGE_BULK_ACTIONS_TRASHED =
    'Successfully selected products move to trashed';

export const SUCCESS_MESSAGE_BULK_ACTIONS_UNPUBLISHED =
    'Successfully selected products move to Unpublished';

export const SUCCESS_MESSAGE_BULK_ACTIONS_PUBLISHED =
    'Successfully selected products move to Published';

export const RESTORE_CONFIRMATION_MESSAGE =
    'Are you sure you want to restore the product from trash? This product will be moved to unpublished and then you can move to publish';

export const RESTORE_CONFIRMATION_TITLE = 'Restore';

export const PUBLISH_CONFIRMATION_TITLE = 'Publish';

export const SUCCESS_MESSAGE_BULK_ACTIONS_RESTORE_ALL = 'Successfully restored selected products';

export const SUCCESS_MESSAGE_DUPLICATE_PRODUCT = 'Successfully duplicated product ';

export const SUCCESS_MESSAGE_UNPUBLISHED_PRODUCT = 'Successfully unpublished product ';

export const SUCCESS_MESSAGE_PUBLISHED_PRODUCT_INSTANT_EDIT = 'Successfully published product ';

export const SUCCESS_MESSAGE_UPDATED_PRODUCT = 'Successfully updated product';

export const FAILURE_MESSAGE = 'Something went wrong';

export const SUCCESS_MESSAGE_PUBLISHED_PRODUCT = 'Item published successfully';

export const SUCCESS_MESSAGE_RESTORE_PRODUCT = 'Successfully restored the product';

export const SUCCESS_MESSAGE_TRASHED_PRODUCT_PREFIX = 'Successfully ';

export const SUCCESS_MESSAGE_TRASHED_PRODUCT_POSTFIX = ' moved to trashed';

export const BULK_ACTION_VALIDATION_FAILED_PUBLISHED =
    "You can perform this action only to 'Unpublished' products. \nPlease select multiple 'Unpublished' products to proceed";

export const BULK_ACTION_VALIDATION_FAILED_UNPUBLISHED =
    "You can perform this action only to 'Published' products. \nPlease select multiple 'Published' products to proceed.";

export const BULK_ACTION_VALIDATION_FAILED_TITLE = 'Sorry!';

export const DUPLICATE_PRODUCT_PREFIX = 'Copy - ';

export const DUPLICATE_PRODUCT_REORDER_TOOLTIP_TEXT =
    'The list of products is unsorted due to the product duplication. Please refresh to \nsort it.';

export const INSTANT_EDIT_PUBLISH_VALIDATION_FAILED =
    'Please fill out the mandatory information before publish this product.';

const currentDate = new Date();
export const DATE = moment(currentDate).format('YYYY-MM-DD');

export const UN_SAVED_CHANGES = 'Unsaved Changes';

export const UN_SAVED_CHANGES_TEXT =
    'There are unsaved changes to your product, do you wish to save this product as an unpublished or discard the changes?';

export const SET_ITEM_POINTS_SUCCESS = 'Successfully saved item points';

export const VALIDATION_ERROR_MARKUP_PRICE_NOT_SET =
    'Please edit and save partner points for all partners';

export const VALIDATION_ERROR_ITEM_POINTS_EMPTY = 'Item points empty for one or many partners';

export const CATALOG_NO_OPTION_MESSAGE = 'All Catalogs Selected';

export const IN_STOCK = 'IN-STOCK';

export const OUT_OF_STOCK = 'OUT-OF-STOCK';

export const UPDATE_ITEM_ERROR = 'Update Item failed with an error';

export const UPDATE_ITEM_SUCCESS = 'Successfully updated the Item';

export const LOCAL_CURRENCY_RESET_TITLE = 'Reset local currency rate';

export const LOCAL_CURRENCY_RESET_MESSAGE =
    'Are you sure you want to reset the local currency rate?';

export const MARKED_UP_PRICE_TITLE = 'Reset marked up price';

export const MARKED_UP_PRICE_MESSAGE = 'Are you sure you want to reset the marked up price?';

export const FINAL_REATIL_PRICE_TITLE = 'Reset final retail price';

export const FINAL_REATIL_PRICE_MESSAGE = 'Are you sure you want to reset the final retail price?';
