import {
    UPLOAD_AGREEMENT,
    UPLOAD_AGREEMENT_SUCCESS,
    LOCAL_SAVE_PARTNER_DATA,
    PRODUCT_PRICE,
    LOCAL_REMOVE_PARTNER_DATA,
    SAVE_ITEM_NAME_SUCCESS,
    SAVE_ITEM_NAME,
    SET_CONVERSION_RATE,
    SET_UNIT_PRICE,
    SET_ITEM_PARTNER_POINTS,
    FETCH_CREATED_USER_DETAILS_SUCCESS,
    FETCH_CREATED_USER_DETAILS_FAILED,
    SAVE_CATEGORY_NAME,
    SAVE_CATEGORY_NAME_SUCCESS,
    SAVE_PARTNER_NAME,
    SAVE_PARTNER_NAME_SUCCESS,
    SAVE_SUPPLIER_NAME,
    SAVE_SUPPLIER_NAME_SUCCESS,
    LOCAL_SAVE_SUPPLIER_DATA,
    LOCAL_REMOVE_SUPPLIER_DATA,
    USERS_BY_ROLE,
    LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA,
    SET_ADMIN_PROFILE_LOGIN_CLICKED,
    SET_SELECTED_ITEMS_DATA_TABLE,
    RESET_PRODUCT_PRICE,
    SAVE_PRODUCT_GROUP_NAME,
    SAVE_PRODUCT_GROUP_NAME_SUCCESS,
    SAVE_PAYMENT_CHANNEL_NAME,
    SAVE_PAYMENT_CHANNEL_NAME_SUCCESS,
    SAVE_PAYMENT_CHANNEL_ID,
    SAVE_PAYMENT_CHANNEL_ID_SUCCESS,
} from './../../enums/redux/redux-enum';
import axios from 'axios';
import { AUTH_API_URL } from '../../constants/config/constants';
import { getErrorMessage } from '../../utils/axiosErrorHandler';
import { getCookie, removeCookie, setCookie } from '../../utils/cookiesService';
import jwt from 'jwt-decode';
import {
    SET_AUTH_TOKEN,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGIN_VERIFICATION,
    LOGIN_VERIFICATION_SUCCESS,
    LOGIN_VERIFICATION_FAILED,
    REFRESH_LOGIN,
    REFRESH_LOGIN_SUCCESS,
    REFRESH_LOGIN_FAILED,
    ADMIN_CLI_LOGIN,
    ADMIN_CLI_LOGIN_FAILED,
    CREATE_USER,
    USERS_BY_ROLE_STORE_ADMIN_SUCCESS,
    USERS_BY_ROLE_PARTNER_SUCCESS,
    USERS_BY_ROLE_FAILED,
    SENT_NEW_USER_INVITE,
    SENT_NEW_USER_INVITE_SUCCESS,
    SENT_NEW_USER_INVITE_FAILURE,
    FETCH_USER_DETAILS,
    LOGOUT_FROM_SESSION,
    LOGOUT_FROM_SESSION_SUCCESS,
    LOGOUT_FROM_ALL_SESSION,
    LOGOUT_FROM_ALL_SESSION_SUCCESS,
    UPLOAD_PROFILE_PIC,
    UPLOAD_PROFILE_PIC_SUCCESS,
    NEW_USER_PASSWORD,
    NEW_USER_PASSWORD_SUCCESS,
    NEW_USER_PASSWORD_FAIL,
    LOGOUT,
    ROLE_TYPES_SUCCESS,
    GET_ALL_SESSION,
    GET_ALL_SESSION_SUCCESS,
    CLEAR_ERRORS,
    GET_ALL_USERS,
    ASSIGN_CLIENT_ROLE,
    ASSIGN_CLIENT_ROLE_SUCCESS,
    CREATE_USER_FAILED,
    CREATE_USER_SUCCESS,
    FETCH_CREATED_USER_DETAILS,
} from '../../enums/redux/redux-enum';
import { ClientRoles } from '../../enums/keycloak';

export const setAuthToken = (authToken: any | null) => (dispatch: any) => {
    dispatch({ type: SET_AUTH_TOKEN, payload: authToken });
};

export const handleToken = (response: any) => {
    const token = {
        accessToken: response,
    };
    setAuthToken(token);
};

export const login = (email: string, password: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_AUTH || '');
    params.append('username', email.trim());
    params.append('password', password);
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    try {
        dispatch({ type: LOGIN });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);

        handleToken(response.data.access_token);

        const currentUser: any = jwt(response.data.access_token);

        localStorage.setItem('current_user_email', currentUser?.email);
        localStorage.setItem('refresh_token', response.data.refresh_token);

        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
    } catch (error: any) {
        dispatch({ type: LOGIN_FAILED, payload: getErrorMessage(error) });
    }
};

export const verifyPassword = (email: string, password: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_AUTH || '');
    params.append('username', email.trim());
    params.append('password', password);
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    try {
        dispatch({ type: LOGIN_VERIFICATION });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        if (response) {
            dispatch({ type: LOGIN_VERIFICATION_SUCCESS });
        }
    } catch (error: any) {
        dispatch({ type: LOGIN_VERIFICATION_FAILED, payload: getErrorMessage(error) });
    }
};

export const refreshAuthToken = (refreshToken: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_REFRESH || '');
    params.append('refresh_token', refreshToken);

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    localStorage.removeItem('access_token_time');
    try {
        dispatch({ type: REFRESH_LOGIN });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        dispatch({ type: REFRESH_LOGIN_SUCCESS, payload: response.data });

        if (response.data) {
            // localStorage.setItem('Refreshed', 'REFRESHED');
            localStorage.setItem('refresh_token', response.data.refresh_token);
            const date = Date.now() + response.data.expires_in * 1000;

            const options = { path: '/', expires: date };

            localStorage.setItem('access_token_time', date?.valueOf().toString());
            setCookie('access_token', response.data.access_token, options);
        }
    } catch (error: any) {
        localStorage.setItem('refresh-failed', 'REFRESH_FAILED');
        dispatch({ type: REFRESH_LOGIN_FAILED, payload: getErrorMessage(error) });
    }
};

export const adminClientLogin = () => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    try {
        dispatch({ type: ADMIN_CLI_LOGIN });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        localStorage.setItem('admin-cli-token', response.data.access_token);
    } catch (error: any) {
        dispatch({ type: ADMIN_CLI_LOGIN_FAILED, payload: getErrorMessage(error) });
    }
};

export const createUser = (createdTimestamp: number, email: string) => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');
    const randomUID = `UID${Math.floor(Math.random() * 1e16)}`;
    const bodyObject = {
        createdTimestamp: createdTimestamp,
        username: randomUID,
        enabled: true,
        totp: false,
        emailVerified: false,
        email: email,
        disableableCredentialTypes: [],
        requiredActions: [],
        notBefore: 0,
        access: {
            manageGroupMembership: true,
            view: true,
            mapRoles: true,
            impersonate: true,
            manage: true,
        },
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${adminClientToken}`,
        },
    };
    const body = JSON.stringify(bodyObject);

    try {
        dispatch({ type: CREATE_USER });
        await axios.post(`${process.env.REACT_APP_USERS}`, body, config);
        dispatch({ type: CREATE_USER_SUCCESS });
    } catch (error: any) {
        dispatch({ type: CREATE_USER_FAILED, payload: getErrorMessage(error) });
    }
};

export const sendNewUserInvite = () => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    const newUserId = localStorage.getItem('addedUserID');

    try {
        dispatch({ type: SENT_NEW_USER_INVITE });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        localStorage.setItem('admin-cli-token', response.data.access_token);

        const adminUserConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer  ${response.data.access_token}`,
            },
        };

        await axios.put(
            `${process.env.REACT_APP_ROLES_CREATE_USERS}/${newUserId}/execute-actions-email`,
            ['UPDATE_PASSWORD'],
            adminUserConfig,
        );
        dispatch({ type: SENT_NEW_USER_INVITE_SUCCESS });
    } catch (error: any) {
        dispatch({ type: SENT_NEW_USER_INVITE_FAILURE, payload: getErrorMessage(error) });
    }
};

export const resetPasswordAction = (email: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    try {
        dispatch({ type: ADMIN_CLI_LOGIN });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        localStorage.setItem('admin-cli-token', response.data.access_token);

        const adminUserConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer  ${response.data.access_token}`,
            },
        };

        const userDetails = await axios.get(
            `${process.env.REACT_APP_USERS}?email=${email}`,
            adminUserConfig,
        );

        dispatch({ type: FETCH_USER_DETAILS, payload: response.data });

        dispatch({ type: SENT_NEW_USER_INVITE });
        await axios.put(
            `${process.env.REACT_APP_USERS}/${userDetails?.data[0]?.id}/execute-actions-email`,
            ['UPDATE_PASSWORD'],
            adminUserConfig,
        );
        dispatch({ type: SENT_NEW_USER_INVITE_SUCCESS });
    } catch (error: any) {
        dispatch({ type: SENT_NEW_USER_INVITE_FAILURE, payload: getErrorMessage(error) });
    }
};

export const newUserInvite = (email: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    try {
        dispatch({ type: ADMIN_CLI_LOGIN });
        const response = await axios.post(`${AUTH_API_URL}`, params, config);
        localStorage.setItem('admin-cli-token', response.data.access_token);

        const adminUserConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer  ${response.data.access_token}`,
            },
        };

        const userDetails = await axios.get(
            `${process.env.REACT_APP_USERS}?email=${email}`,
            adminUserConfig,
        );

        dispatch({ type: FETCH_USER_DETAILS, payload: response.data });

        dispatch({ type: SENT_NEW_USER_INVITE });
        await axios.put(
            `${process.env.REACT_APP_USERS}/${userDetails?.data[0]?.id}/execute-actions-email`,
            ['UPDATE_PROFILE'],
            adminUserConfig,
        );
        dispatch({ type: SENT_NEW_USER_INVITE_SUCCESS });
    } catch (error: any) {
        dispatch({ type: SENT_NEW_USER_INVITE_FAILURE, payload: getErrorMessage(error) });
    }
};

export const logoutFromSessions = (sessionID) => async (dispatch: any) => {
    const token = getCookie('access_token');
    const config = {
        headers: { Authorization: `Bearer  ${token}` },
    };
    dispatch({ type: LOGOUT_FROM_SESSION });
    await axios.delete(`${process.env.REACT_APP_REALM}/sessions/${sessionID}`, config);
    dispatch({ type: LOGOUT_FROM_SESSION_SUCCESS });
};

export const logoutFromAllSessions = (userId) => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');
    const adminUserConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${adminClientToken}`,
        },
    };
    dispatch({ type: LOGOUT_FROM_ALL_SESSION });
    await axios.post(`${process.env.REACT_APP_USERS}/${userId}/logout`, null, adminUserConfig);
    dispatch({ type: LOGOUT_FROM_ALL_SESSION_SUCCESS });
};

export const uploadProfilePic = (imageUrl) => async (dispatch: any) => {
    dispatch({ type: UPLOAD_PROFILE_PIC });
    if (imageUrl) {
        dispatch({ type: UPLOAD_PROFILE_PIC_SUCCESS, payload: imageUrl });
    }
};
export const saveProductName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_ITEM_NAME });
    if (response) {
        dispatch({ type: SAVE_ITEM_NAME_SUCCESS, payload: response });
    }
};

export const saveCategoryName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_CATEGORY_NAME });
    if (response) {
        dispatch({ type: SAVE_CATEGORY_NAME_SUCCESS, payload: response });
    }
};

export const saveProductGroupName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_PRODUCT_GROUP_NAME });
    if (response) {
        dispatch({ type: SAVE_PRODUCT_GROUP_NAME_SUCCESS, payload: response });
    }
};

export const savePaymentChannelName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_PAYMENT_CHANNEL_NAME });
    if (response) {
        dispatch({ type: SAVE_PAYMENT_CHANNEL_NAME_SUCCESS, payload: response });
    }
};

export const savePaymentChannelId = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_PAYMENT_CHANNEL_ID });
    if (response) {
        dispatch({ type: SAVE_PAYMENT_CHANNEL_ID_SUCCESS, payload: response });
    }
};

export const savePartnerName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_PARTNER_NAME });
    if (response) {
        dispatch({ type: SAVE_PARTNER_NAME_SUCCESS, payload: response });
    }
};

export const saveSupplierName = (response) => (dispatch: any) => {
    dispatch({ type: SAVE_SUPPLIER_NAME });
    if (response) {
        dispatch({ type: SAVE_SUPPLIER_NAME_SUCCESS, payload: response });
    }
};

export const updateNewUserPassword = (password: string, userId: any) => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');

    const adminClientConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${adminClientToken}`,
        },
    };

    const body = { type: 'password', value: password, temporary: false };

    try {
        dispatch({ type: NEW_USER_PASSWORD });

        await axios.put(
            `${process.env.REACT_APP_USERS}/${userId}/reset-password`,
            body,
            adminClientConfig,
        );
        dispatch({ type: NEW_USER_PASSWORD_SUCCESS });
    } catch (error: any) {
        dispatch({ type: NEW_USER_PASSWORD_FAIL, payload: getErrorMessage(error) });
    }
};

export const logout = () => async (dispatch: any) => {
    const refresh_token = localStorage.getItem('refresh_token');

    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_CLIENT_ID || '');
    params.append('refresh_token', refresh_token);

    await axios.post(`${process.env.REACT_APP_LOGOUT}`, params);

    localStorage.removeItem('current_user_email');
    localStorage.removeItem('profilePicData');
    localStorage.removeItem('addedUserID');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('current_user_username');
    localStorage.removeItem('edit-item');
    localStorage.removeItem('view-item');
    localStorage.removeItem('refresh-failed');
    localStorage.removeItem('edit-category');
    localStorage.removeItem('view-category');
    localStorage.removeItem('added_product');
    localStorage.removeItem('isRegistered');
    localStorage.removeItem('view-user');
    localStorage.removeItem('user_type');
    localStorage.removeItem('NO_REFRESH');
    localStorage.removeItem('partnerId');
    localStorage.removeItem('partnerUUID');
    localStorage.removeItem('catalogId');
    localStorage.removeItem('Error');
    localStorage.removeItem('Refreshed');
    localStorage.removeItem('NO_REFRESH');
    localStorage.removeItem('access_token_time');
    localStorage.removeItem('iId');
    localStorage.removeItem('cPrice');
    localStorage.removeItem('currency');

    removeCookie('access_token');
    dispatch({ type: LOGOUT });
};

export const getRoles = () => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');

    const config = {
        headers: { Authorization: `Bearer  ${adminClientToken}` },
    };

    const clientsList = await axios.get(`${process.env.REACT_APP_REALM}/clients`, config);
    const swarmioUser = clientsList?.data?.find((client) => {
        return client.clientId === process.env.REACT_APP_CLIENT_ID;
    });
    const response = await axios.get(
        `${process.env.REACT_APP_REALM}/clients/${swarmioUser?.id}/roles`,
        config,
    );
    dispatch({ type: ROLE_TYPES_SUCCESS, payload: response.data });
};

export const getUsersWithRole = (role: any) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const adminConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    try {
        dispatch({ type: USERS_BY_ROLE });
        const adminClientAuthResponse = await axios.post(`${AUTH_API_URL}`, params, adminConfig);
        localStorage.setItem('admin-cli-token', adminClientAuthResponse.data.access_token);

        const config = {
            headers: { Authorization: `Bearer  ${adminClientAuthResponse.data.access_token}` },
        };

        const clientsList = await axios.get(`${process.env.REACT_APP_REALM}/clients`, config);
        const swarmioUser = clientsList?.data?.find((client) => {
            return client.clientId === process.env.REACT_APP_CLIENT_ID;
        });

        const usersListByRoles = await axios.get(
            `${process.env.REACT_APP_REALM}/clients/${swarmioUser?.id}/roles/${role}/users`,
            config,
        );

        if (role === ClientRoles.STORE_ADMIN && swarmioUser?.id && role) {
            dispatch({
                type: USERS_BY_ROLE_STORE_ADMIN_SUCCESS,
                payload: usersListByRoles?.data,
            });
        }
        if (role === ClientRoles.PARTNER && swarmioUser?.id && role) {
            dispatch({
                type: USERS_BY_ROLE_PARTNER_SUCCESS,
                payload: usersListByRoles?.data,
            });
        }
    } catch (error: any) {
        dispatch({ type: USERS_BY_ROLE_FAILED, payload: getErrorMessage(error) });
    }
};

export const getActiveSessions = (email) => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');

    const config = {
        headers: { Authorization: `Bearer  ${adminClientToken}` },
    };
    const userDetails = await axios.get(`${process.env.REACT_APP_USERS}?email=${email}`, config);
    dispatch({ type: FETCH_USER_DETAILS, payload: userDetails?.data });

    dispatch({ type: GET_ALL_SESSION });
    const loggedInSessions = await axios.get(
        `${process.env.REACT_APP_USERS}/${userDetails?.data[0]?.id}/sessions`,
        config,
    );
    dispatch({ type: GET_ALL_SESSION_SUCCESS, payload: loggedInSessions?.data });
};

export const getUserDetails = (email: string) => async (dispatch: any) => {
    const adminClientToken = localStorage.getItem('admin-cli-token');
    const adminUserConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${adminClientToken}`,
        },
    };
    const response = await axios.get(
        `${process.env.REACT_APP_USERS}?email=${email}`,
        adminUserConfig,
    );
    if (response) {
        localStorage.setItem('current_user_username', response?.data[0]?.username);
    }
    dispatch({ type: FETCH_USER_DETAILS, payload: response.data });
};

export const getAllUserDetails = () => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const adminConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };

    try {
        dispatch({ type: ADMIN_CLI_LOGIN });
        const adminClientAuthResponse = await axios.post(`${AUTH_API_URL}`, params, adminConfig);
        localStorage.setItem('admin-cli-token', adminClientAuthResponse.data.access_token);
        const config = {
            headers: { Authorization: `Bearer  ${adminClientAuthResponse.data.access_token}` },
        };
        const response = await axios.get(`${process.env.REACT_APP_USERS}`, config);
        dispatch({ type: GET_ALL_USERS, payload: response.data });
    } catch (error: any) {
        console.log(error);
    }
};
export const getUserDetailsUsingAdminClient = (email: string) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const adminConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const adminClientAuthResponse = await axios.post(`${AUTH_API_URL}`, params, adminConfig);
    const adminUserConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${adminClientAuthResponse.data.access_token}`,
        },
    };

    try {
        dispatch({ type: FETCH_CREATED_USER_DETAILS });
        const response = await axios.get(
            `${process.env.REACT_APP_USERS}?email=${email}`,
            adminUserConfig,
        );
        dispatch({ type: FETCH_CREATED_USER_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_CREATED_USER_DETAILS_FAILED, payload: getErrorMessage(error) });
    }
};

export const clearAuthErrors = () => (dispatch: any) => {
    dispatch({ type: CLEAR_ERRORS });
};

export const assignClientRoleToUser = (userId: any, role: any) => async (dispatch: any) => {
    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ADMIN_CLIENT_ID || '');
    params.append('grant_type', process.env.REACT_APP_GRANT_TYPE_ADMIN || '');
    params.append('client_secret', process.env.REACT_APP_ADMIN_CLIENT_SECRET || '');

    const adminConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const adminClientAuthResponse = await axios.post(`${AUTH_API_URL}`, params, adminConfig);
    const adminUserConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer  ${adminClientAuthResponse.data.access_token}`,
        },
    };

    dispatch({ type: ASSIGN_CLIENT_ROLE });

    await axios.post(
        `${process.env.REACT_APP_USERS}/` +
            userId +
            `/role-mappings/clients/${process.env.REACT_APP_SWARMIO_CLIENT_ID}`,
        role,
        adminUserConfig,
    );

    dispatch({ type: ASSIGN_CLIENT_ROLE_SUCCESS });
};

export const uploadAgreementToS3 = (fileUrl) => async (dispatch: any) => {
    dispatch({ type: UPLOAD_AGREEMENT });
    if (fileUrl) {
        dispatch({ type: UPLOAD_AGREEMENT_SUCCESS, payload: fileUrl });
    }
};

export const setPartner = (partner: any) => (dispatch: any) => {
    if (partner && partner !== null) {
        dispatch({ type: LOCAL_SAVE_PARTNER_DATA, payload: partner });
    }
};

export const localSaveCreatedSupplier = (supplier: any) => (dispatch: any) => {
    if (supplier && supplier !== null) {
        dispatch({ type: LOCAL_SAVE_SUPPLIER_DATA, payload: supplier });
    }
};

export const localRemoveCreatedSupplier = () => (dispatch: any) => {
    dispatch({ type: LOCAL_REMOVE_SUPPLIER_DATA });
};

export const localRemoveCreatedPartner = () => (dispatch: any) => {
    dispatch({ type: LOCAL_REMOVE_PARTNER_DATA });
};

export const localRemoveKeycloakCreatedPartner = () => (dispatch: any) => {
    dispatch({ type: LOCAL_REMOVE_KEYCLOAK_PARTNER_DATA });
};

export const localProductPrice = (price: any) => (dispatch: any) => {
    if (price) {
        dispatch({ type: PRODUCT_PRICE, payload: price });
    }
};

export const resetLocalProductPrice = () => (dispatch: any) => {
    dispatch({ type: RESET_PRODUCT_PRICE });
};

export const setConversionRate = (rate: any) => (dispatch: any) => {
    if (rate) {
        dispatch({ type: SET_CONVERSION_RATE, payload: rate });
    }
};

export const setUnitPrice = (unitPrice: any) => (dispatch: any) => {
    if (unitPrice) {
        dispatch({ type: SET_UNIT_PRICE, payload: unitPrice });
    }
};

export const setItemPoints = (itemPoints: any) => (dispatch: any) => {
    if (itemPoints) {
        dispatch({ type: SET_ITEM_PARTNER_POINTS, payload: itemPoints });
    }
};

export const setAdminProfileLogout = () => (dispatch: any) => {
    dispatch({ type: SET_ADMIN_PROFILE_LOGIN_CLICKED, payload: true });
};

export const setSelectedItemsDataTable = (selectedFlatRows: any) => (dispatch: any) => {
    if (selectedFlatRows) {
        dispatch({ type: SET_SELECTED_ITEMS_DATA_TABLE, payload: selectedFlatRows });
    }
};
