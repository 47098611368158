/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
    adminClientLogin,
    clearAuthErrors,
    getUsersWithRole,
    login,
    logout,
} from '../../../redux/rootActions';
import { getGeoLocation } from '../../../redux/actions/geoLocationActions';
import { RootState } from '../../../redux/rootReducer';

import { useHistory } from 'react-router-dom';
import { getCookie, setCookie } from '../../../utils/cookiesService';
import { Buttons } from '../../ui/atoms/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ACTIVITY_LOG, UPDATE_USER_LOGIN_DATA } from '../../../queries/AdminProfileQueries';
import AdminLoginValidation from '../../../helpers/adminLoginValidation.helpers';
import jwtDecode from 'jwt-decode';
import { deviceType, getUA, useDeviceData } from 'react-device-detect';
import { SESSION_STATUS_ACTIVE } from '../../../constants/userLoginSession';
import { REGISTERED, NOT_REGISTERED } from '../../../constants/profile';
import NormalLoader from '../../../utils/normalLoader';
import SpinLoader from '../../../utils/spinLoader';
import { ClientRoles } from '../../../enums/keycloak';
import jwt from 'jwt-decode';
import { SORT_ASC } from '../../../constants/common';

const validationSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email('Email must be a valid email')
        .required('Email address is required'),
    password: Yup.string().required('Password cannot be blank'),
});

export default function Login() {
    const dispatch = useDispatch();

    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [hasLoggedIn, setHasLoggedIn] = useState(false);
    const [rememberMeChecked, setRememberMeChecked] = useState(false);
    const [rememberMeCheckedClicked, setRememberMeCheckedClicked] = useState(false);
    const [isValidUser, setIsValidUser] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [rememberedEmail, setRememberedEmail] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [activityLogProcessLoading, setActivityLogProcessLoading] = useState(false);
    const [loggedInUserProcessing, setLoggedInUserProcessing] = useState(false);

    const loginResponse = useSelector((state: RootState) => state.auth.loginResponse);
    const loading = useSelector((state: RootState) => state.auth.isLoading);
    const loginErrorMessage = useSelector((state: RootState) => state.auth.loginErrorMessage);

    const geoLocationData = useSelector((state: RootState) => state.geoLocation.geoLocation);
    const deviceData = useDeviceData(navigator.userAgent);

    const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' };
    const token = getCookie('access_token');

    const [updateUserLoginData] = useMutation(UPDATE_USER_LOGIN_DATA);

    const processUserLoginSession = () => {
        if (geoLocationData && deviceData) {
            const currentUser: any = loginResponse?.access_token
                ? jwtDecode(loginResponse?.access_token)
                : null;
            updateUserLoginData({
                variables: {
                    userName: currentUser?.preferred_username,
                    userLogin: {
                        sessionId: currentUser?.sid,
                        device: getUA,
                        deviceType: deviceType,
                        location:
                            geoLocationData?.state === null
                                ? geoLocationData?.country_name
                                : geoLocationData?.state + ', ' + geoLocationData?.country_name,
                        status: SESSION_STATUS_ACTIVE,
                        ipAddress: geoLocationData?.IPv4,
                    },
                },
            });
        }
    };

    useEffect(() => {
        localStorage.setItem('NO_REFRESH', 'NO_REFRESH');
        if (token) {
            const decoded_token: any = jwtDecode(token);
            if (decoded_token?.exp && decoded_token.exp * 1000 < new Date().getTime()) {
                dispatch(logout());
                history.push('/');
            } else {
                setLoggedInUserProcessing(true);
                const currentUser: any = token ? jwtDecode(token) : null;

                getActivityLog({
                    variables: {
                        userName: currentUser?.preferred_username,
                        offset: 0,
                        limit: 100,
                        order: SORT_ASC,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    let currentUserTokenDetails;
    const loginToken = getCookie('access_token');

    if (loginToken) {
        currentUserTokenDetails = jwt(loginToken);
    }
    const clientID = process.env.REACT_APP_CLIENT_ID;
    const currentUserRoles = currentUserTokenDetails?.resource_access[clientID || 'swarmio-user'];

    useEffect(() => {
        if (token && clicked && currentUserRoles) {
            const roles = currentUserRoles?.roles;

            if (roles?.includes(ClientRoles.STORE_ADMIN)) {
                setIsValidUser(true);
            } else {
                dispatch(logout());
                history?.push('/validation-partner');
            }
        }
    }, [clicked, currentUserRoles, dispatch, history, token]);

    const [getActivityLog, { data: activityLogData }] = useLazyQuery(ACTIVITY_LOG, {
        onCompleted: () => {
            const isFirstLogin = AdminLoginValidation.validateFirstLogin(
                activityLogData?.userActivityLogs?.logs,
            );

            if (isFirstLogin) {
                localStorage.setItem('is_first_login', 'true');
                setTimeout(() => {
                    setActivityLogProcessLoading(false);
                    setLoggedInUserProcessing(false);
                    localStorage?.setItem('isRegistered', NOT_REGISTERED);
                    dispatch(adminClientLogin());
                    return history.push('/adminProfile');
                }, 2000);
            } else {
                processUserLoginSession();
                setTimeout(() => {
                    setActivityLogProcessLoading(false);
                    setLoggedInUserProcessing(false);
                    localStorage?.setItem('isRegistered', REGISTERED);
                    dispatch(adminClientLogin());
                    return history.push('/dashboard');
                }, 2000);
            }
        },
    });

    const passwordShow = () => {
        setShowPassword(!showPassword);
    };

    const checkRememberMe = () => {
        setRememberMeCheckedClicked(true);
        setRememberMeChecked(!rememberMeChecked);
    };

    useEffect(() => {
        if (loginResponse) {
            const date = new Date();
            date.setTime(date.getTime() + loginResponse.expires_in * 1000).valueOf();

            const options = { path: '/', expires: date };
            localStorage.setItem('access_token_time', date?.valueOf().toString());
            setCookie('access_token', loginResponse.access_token, options);
        }
    }, [loginResponse]);

    useEffect(() => {
        if (loginErrorMessage) {
            setTimeout(() => dispatch(clearAuthErrors()), 8000);
        }
    }, [dispatch, loginErrorMessage]);

    useEffect(() => {
        const rememberedEmails = localStorage.getItem('remember_email');
        if (rememberedEmails) setRememberedEmail(rememberedEmails);
    }, []);

    useEffect(() => {
        dispatch(getGeoLocation());
    }, [dispatch]);

    const rememberedEmails = localStorage.getItem('remember_email');

    useEffect(() => {
        rememberMeChecked === true && email ? localStorage.setItem('remember_email', email) : null;
    }, [email, rememberMeChecked]);

    useEffect(() => {
        rememberMeChecked === false && rememberMeCheckedClicked
            ? localStorage.removeItem('remember_email')
            : null;
    }, [rememberMeChecked, rememberMeCheckedClicked]);

    useEffect(() => {
        if (rememberedEmails?.length) {
            setRememberMeChecked(true);
        }
    }, [dispatch, rememberedEmails]);

    useEffect(() => {
        if (loginResponse && hasLoggedIn) {
            setActivityLogProcessLoading(true);
            const currentUser: any = loginResponse?.access_token
                ? jwtDecode(loginResponse?.access_token)
                : null;
            if (token) {
                const decoded_token: any = jwtDecode(token);

                if (decoded_token?.exp && decoded_token.exp * 1000 < new Date().getTime()) {
                    dispatch(logout());
                    history.push('/');
                } else {
                    getActivityLog({
                        variables: {
                            userName: currentUser?.preferred_username,
                            offset: 0,
                            limit: 100,
                            order: SORT_ASC,
                        },
                    });
                }
            }
        }
    }, [loginResponse, hasLoggedIn, history, getActivityLog, token, dispatch]);

    if (loggedInUserProcessing)
        return (
            <div className="container h-screen w-screen m-auto text-center flex justify-center items-center">
                <SpinLoader />
            </div>
        );

    return (
        <div className="container h-screen w-screen">
            <div
                className="flex bg-cover bg-center   h-full w-full  md:h-screen md:w-screen  bg-no-repeat"
                style={{ backgroundImage: `url(/images/background.svg)` }}
            >
                <div className="container  max-w-2xl md:mt-20 lg:mt-16 xl:mt-7 2xl:mt-auto md:rounded-md bg-white flex sm:m-auto  flex-wrap flex-col">
                    <div className="mb-7 md:mb-8 max-w-2xl">
                        <img className="w-28 mx-auto  mt-14" src="/images/favicon.png" alt=""></img>

                        <div className=" flex flex-col flex-wrap justify-center -mt-4">
                            <div className="m-auto font-extrabold text-xl font-poppins justify-center mb-4">
                                LOG IN
                            </div>

                            <div className="m-auto text-black  font-poppins font-semibold">
                                {"Welcome Let's get started"}
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                email: rememberedEmail || '',
                                password: '',
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={async ({ email, password }) => {
                                setEmail(email);
                                setPassword(password);
                                setClicked(true);
                                dispatch(login(email, password));
                                setHasLoggedIn(true);
                                if (isValidUser) {
                                    const rememberedEmail = localStorage.getItem('remember_email');
                                    if (rememberedEmail) setRememberedEmail(rememberedEmail);
                                }
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleSubmit }) => (
                                <div className="flex flex-col flex-wrap mt-5 mx-4 max-w-xl md:mt-10 md:m-auto lg:max-w-lg ">
                                    <div className="flex flex-wrap flex-col lg:mb-5">
                                        <div className="text-gray-500 w-44 text-center font-poppins  h-max bg-white z-30  -m-3 ml-4 border-opacity-20">
                                            Enter email address
                                        </div>
                                        <input
                                            className={`p-3 pt-3 md:p:3 w-full rounded-xl outline-none border-2 font-poppins bg-white border-gray-400 hover:border-purple-500 ${
                                                errors?.email ? 'border-red-300' : 'border-gray-400'
                                            }`}
                                            placeholder="Admin@swarmio.com"
                                            type="email"
                                            id="email"
                                            onChange={handleChange('email')}
                                            value={values.email}
                                            style={inputStyle}
                                        />
                                        {errors.email && touched.email ? (
                                            <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                {errors.email}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="flex flex-col mt-2 mb-5">
                                        <div className="text-gray-500  w-44 font-poppins  text-center  h-max bg-white z-30 ml-4">
                                            Enter your password
                                        </div>

                                        <div className="relative">
                                            <input
                                                className={`p-3 pt-3 md:p:3  w-full  rounded-xl outline-none -mt-3 font-poppins bg-gray-1f00  border-2 border-gray-400  hover:border-purple-500
                                                    ${
                                                        errors.password
                                                            ? 'border-red-300'
                                                            : 'border-gray-400'
                                                    }`}
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Admin123"
                                                onChange={handleChange('password')}
                                                value={values.password}
                                                style={inputStyle}
                                            />
                                            {errors.password && touched.password ? (
                                                <div className="text-red-500 text-xs mt-1 md:text-sm">
                                                    {errors.password}
                                                </div>
                                            ) : null}

                                            <div className="absolute inset-y-0 right-0 pr-3 flex text-base item-center">
                                                <img
                                                    src="/images/icons/eye 1.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'block' : 'hidden'
                                                    }`}
                                                    onClick={passwordShow}
                                                    alt="show-password"
                                                    id="eye"
                                                    role="presentation"
                                                    draggable="false"
                                                />

                                                <img
                                                    src="/images/icons/eye-hide.png"
                                                    className={`text-gray-700 cursor-pointer w-5 h-5 ${
                                                        showPassword ? 'hidden' : 'block'
                                                    }`}
                                                    alt="hide-password"
                                                    id="eye"
                                                    role="presentation"
                                                    onClick={passwordShow}
                                                    draggable="false"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex mb-11 ml-1 -mt-2">
                                        <input
                                            type="checkbox"
                                            checked={rememberMeChecked}
                                            className="w-4  h-6 font-poppins appearance-non  checked:bg-purple-400"
                                            onClick={checkRememberMe}
                                        ></input>
                                        <p className="text-gray-500 font-poppins ml-3 mt-1">
                                            Remember me
                                        </p>
                                    </div>

                                    <div
                                        className={` border-l-8 p-2  border-red-600 bg-red-100 text-center ${
                                            loginErrorMessage ? 'block' : 'hidden'
                                        }`}
                                        role="alert"
                                    >
                                        <p className="font-semibold" id="invalid_username_password">
                                            Invalid username or password{' '}
                                            <a
                                                className="text-red-600 ml-2 underline"
                                                href="/reset-Password-Email-Verification"
                                                id="forget_password_link"
                                            >
                                                Forgot password?
                                            </a>
                                        </p>
                                    </div>

                                    <div className="pt-5 flex align-middle justify-center xl:mb-10">
                                        <Buttons
                                            name={
                                                loading || activityLogProcessLoading ? (
                                                    <NormalLoader />
                                                ) : (
                                                    'Login'
                                                )
                                            }
                                            type="submit"
                                            buttonType="primary"
                                            id="login"
                                            size="large-full"
                                            padding="py-4"
                                            onclick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}
