import Modal from 'react-responsive-modal';
import { useMemo, useState } from 'react';
import Loader from '../../../utils/loader';
import DataTableAlter from '../../ui/organisms/DataTableAlter';
import { AVAILABLE_PAYMENT_CHANNELS_PAGE } from '../../../constants/productGroups';
import ReactSwitch from 'react-switch';
import { useMutation, useQuery } from '@apollo/client';
import {
    FETCH_PARTNER_ITEM_PAYMENT_CHANNELS,
    UPDATE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS,
} from '../../../queries/PaymentChannelQueries';
import { ClientsEnum } from '../../../enums/apoloClient/client-enum';
import { Buttons } from '../../ui/atoms/Button';
import { PaymentMethod } from '../../../enums/payment';

interface Props {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    partnerId?: any;
    itemId?: any;
    paymentMethod?: PaymentMethod;
    setMessage: (value: any) => void;
    setShowToast: (value: boolean) => void;
    setToastError: (value: boolean) => void;
}

export default function AvailableChannels({
    openModal,
    setOpenModal,
    partnerId,
    itemId,
    paymentMethod,
    setMessage,
    setShowToast,
    setToastError,
}: Props) {
    const [availableChannels, setAvailableChannels] = useState([]);
    const [disabledChannels, setDisabledChannels] = useState([]);
    const [isDirty, setIsDirty] = useState(false);

    const { data: data, loading: paymentChannelsLoading } = useQuery(
        FETCH_PARTNER_ITEM_PAYMENT_CHANNELS,
        {
            variables: { partnerId: partnerId, itemId: itemId, paymentType: paymentMethod },
            context: { clientName: ClientsEnum.STORE },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            onCompleted: () => {
                setDisabledChannels(
                    data?.partnerItemPaymentChannels?.filter(
                        (channel) => channel?.isEnabled == false,
                    ),
                );

                const sortedChannels = [...data?.partnerItemPaymentChannels];

                const enabledChannels = [];
                const disabledChannels = [];

                sortedChannels?.forEach((channel) => {
                    channel.globallyEnabled
                        ? enabledChannels.push(channel)
                        : disabledChannels.push(channel);
                });

                enabledChannels.sort((a, b) => {
                    if (a.isEnabled && !b.isEnabled) {
                        return -1;
                    } else if (!a.isEnabled && b.isEnabled) {
                        return 1;
                    }
                    return 0;
                });
                setAvailableChannels([...enabledChannels, ...disabledChannels]);
            },
        },
    );

    const [updateDisabledPaymentChannels] = useMutation(
        UPDATE_PARTNER_ITEM_DISABLED_PAYMENT_CHANNELS,
        {
            context: { clientName: ClientsEnum.STORE },
            refetchQueries: [FETCH_PARTNER_ITEM_PAYMENT_CHANNELS],
            onCompleted: () => {
                setMessage(`Payment channel(s) updated successfully`);
                setShowToast(true);
                setToastError(false);
                handleModalClose();
            },
            onError() {
                setMessage(`Payment channel(s) update unsuccessfull`);
                setShowToast(true);
                setToastError(true);
                handleModalClose();
            },
        },
    );

    const handleModalClose = () => {
        setIsDirty(false);
        setOpenModal(false);
    };

    const handleSubmitDisabledPaymentChannels = () => {
        const modifiedDisabledPaymentChannels = disabledChannels.map(
            ({ __typename, ...rest }) => rest,
        );

        updateDisabledPaymentChannels({
            variables: {
                updateDisabledPaymentChannelInput: {
                    partnerId: partnerId,
                    itemId: itemId,
                    paymentType: paymentMethod,
                    disabledPaymentChannels: modifiedDisabledPaymentChannels,
                },
            },
        });
    };

    const handleToggle = (prevValue, row) => {
        setIsDirty(true);
        setDisabledChannels((prevChannels) => {
            const updatedChannels = [...prevChannels];

            if (!prevValue) {
                const existingChannelIndex = updatedChannels.findIndex(
                    (channel) => channel.id === row.original.id,
                );

                if (existingChannelIndex !== -1) {
                    updatedChannels.splice(existingChannelIndex, 1);
                }
            } else {
                const existingChannelIndex = updatedChannels.findIndex(
                    (channel) => channel.id === row.original.id,
                );

                if (existingChannelIndex === -1) {
                    updatedChannels.push(row.original);
                }
            }
            return updatedChannels;
        });
    };

    const availablePaymentChannelsColumns = useMemo(
        () => [
            {
                Header: 'ID',
                id: 'channelId',
                accessor: 'channelId',
            },
            {
                Header: 'Name',
                id: 'paymentName',
                accessor: 'paymentName',
            },
            {
                Header: 'Status',
                id: 'isEnabled',
                accessor: 'isEnabled',
                Cell: function EnableDisablePaymentChannel({ row, value }) {
                    const [toggleEnabled, setToggleEnabled] = useState(value);

                    return (
                        <div className="">
                            <ReactSwitch
                                id={row?.original?.paymentName + '_toggle_switch'}
                                checked={toggleEnabled}
                                onChange={() => {
                                    handleToggle(toggleEnabled, row);
                                    setToggleEnabled((prev) => !prev);
                                }}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor={'#8b5cf6'}
                                height={24}
                                width={46}
                                disabled={!row?.original?.globallyEnabled}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );

    return (
        <Modal
            open={openModal}
            onClose={() => {
                handleModalClose();
            }}
            center={true}
            styles={{
                modal: { borderRadius: 8, minWidth: '30%', maxWidth: '80%', maxHeight: '80%' },
            }}
            focusTrapped={false}
        >
            <div className="flex flex-row pt-3 mb-5 justify-center">
                <h3 className="text-xl font-poppins font-semibold flex">Available Channels</h3>
            </div>
            <div className="mt-4">
                <>
                    {paymentChannelsLoading ? (
                        <div className="w-full">
                            <div className="m-auto flex flex-col ">
                                <div className="m-auto p-10">
                                    <Loader />
                                </div>
                                <div className="p-10 bg-purple-100"></div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full border rounded-lg">
                            {availableChannels?.length > 0 ? (
                                <DataTableAlter
                                    columns={availablePaymentChannelsColumns}
                                    data={availableChannels}
                                    hidePagination={true}
                                    customizedPageSize={availableChannels?.length}
                                    availablePaymentChannelsPage={AVAILABLE_PAYMENT_CHANNELS_PAGE}
                                />
                            ) : (
                                <>
                                    <div className="m-auto flex flex-col ">
                                        <div className="py-4 px-5 bg-purple-100 justify-between flex">
                                            {availablePaymentChannelsColumns?.map((column, i) => {
                                                return (
                                                    <div
                                                        className="text-gray-600 font-poppins font-semibold flex ml-12"
                                                        key={i}
                                                    >
                                                        {column?.Header}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="m-auto p-10">No data found.</div>
                                        <div className="p-10 bg-purple-100"></div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                    <div className="flex flex-row justify-end pt-4 gap-3">
                        <Buttons
                            name="Close"
                            type="submit"
                            buttonType="secondary"
                            id="close_btn"
                            size="e-small"
                            onclick={handleModalClose}
                            padding="py-3"
                        />
                        <Buttons
                            name={'Save'}
                            type="submit"
                            buttonType="primary"
                            id="save_btn"
                            size="e-small"
                            onclick={handleSubmitDisabledPaymentChannels}
                            padding="py-3"
                            disabled={!isDirty}
                        />
                    </div>
                </>
            </div>
        </Modal>
    );
}
